import React from 'react'
import { Route, Router } from 'react-router-dom'

import OverallPerformance from './OverallPerformance'
import ConfusionMatrix from './ConfusionMatrix'
import TrendCharts from './TrendCharts'
import Unitwise from 'screens/PerformanceMonitor/Unitwise'

export default function PerformancePage(props) {
	// const [modalIsOpen, setIsOpen] = useState(false)
	// const [date, setDate] = useState({ start: '', end: '' })

	// const [packageFilterList, setPackageFilterList] = useState(['LG- UI GFN', 'LG- UI GFN', 'LG- UI GFN'])
	// const [platformFilterList, setPlatformFilerList] = useState(['KLA'])
	// const [surfaceFilterList, setSurfaceFilterList] = useState(['Top: Leads , Heat Sink', 'Bottom: Leads'])

	// let dateFilterLabel = date.start && date.end && (
	//   <div className="label">
	//     {formatDate(date.start) + ' - ' + formatDate(date.end)}
	//     <Icon name="close" onClick={e => setDate({ ...date, start: '', end: '' })} />
	//   </div>
	// )
	// let packagesFilterLabel = packageFilterList.map((value, index) => {
	//   return (
	//     <div className="label" key={index}>
	//       {value}
	//       <Icon name="close" />
	//     </div>
	//   )
	// })
	// let platformsFilterLabel = platformFilterList.map((value, index) => {
	//   return (
	//     <div className="label" key={index}>
	//       {value}
	//       <Icon name="close" />
	//     </div>
	//   )
	// })
	// let surfacesFilterLabel = surfaceFilterList.map((value, index) => {
	//   return (
	//     <div className="label" key={index}>
	//       {value}
	//       <Icon name="close" />
	//     </div>
	//   )
	// })

	// function openModal() {
	//   setIsOpen(true)
	// }

	// function closeModal() {
	//   setIsOpen(false)
	// }
	return (
		<div className="page performance">
			{/* <PackageList modalIsOpen={modalIsOpen} closeModal={closeModal}></PackageList> */}
			{/* <Grid columns="equal" className="filters">
        <Grid.Row>
          <span className="filter-label">Select the Filters</span>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DateDropDown date={date} setDate={setDate}></DateDropDown>
          </Grid.Column>
          <Grid.Column>
            <PackageDropDown showAllPackages={openModal}></PackageDropDown>
          </Grid.Column>
          <Grid.Column>
            <PlatformDropDown></PlatformDropDown>
          </Grid.Column>
          <Grid.Column>
            <SurfaceDropDown></SurfaceDropDown>
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
			{/* {date.start && date.end && (
        <Grid columns="equal" className="select-filter">
          <div className="select-filter-title">
            <div className="selected-title">
              Selected Filters <Button className="show-filter-btn">Show All Filters</Button>
            </div>
          </div>
          <div className="hr"></div>
          <div className="selected-filter-results">
            <div className="selected-result">
              <div className="selected-filter-title">Date:</div>
              <div className="selected-filter-results">{dateFilterLabel}</div>
            </div>
            <div className="vr"></div>
            <div className="selected-result">
              <div className="selected-filter-title">Packages:</div>
              <div className="selected-filter-results">{packagesFilterLabel}</div>
            </div>
            <div className="vr"></div>
            <div className="selected-result">
              <div className="selected-filter-title">Inspection Platforms</div>
              <div className="selected-filter-results">{platformsFilterLabel}</div>
            </div>
            <div className="vr"></div>
            <div className="selected-result">
              <div className="selected-filter-title">Surfaces</div>
              <div className="selected-filter-results">{surfacesFilterLabel}</div>
            </div>
          </div>
        </Grid>
      )} */}
			<Router history={props.history}>
				<Route path={`/performance/overall`} component={OverallPerformance}></Route>
				<Route path={`/performance/unitwise-analysis`} component={Unitwise}></Route>
				<Route path={`/performance/confusion-matrix`} component={ConfusionMatrix}></Route>
				<Route exact path={`/performance/trend-charts`} component={TrendCharts}></Route>
			</Router>
		</div>
	)
}
