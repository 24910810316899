import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'

import { startGpu, stopGpu } from 'actions'

import logo from 'assests/images/logo.png'
import logo2x from 'assests/images/logo@2x.png'
import profile from 'assests/images/profile.png'
import profile2x from 'assests/images/profile@2x.png'

/**
 * component to display logo, title, power button and profile
 */

const getButtoncolor = (gpu, django) => {
	let color = null
	if (gpu === 'running') {
		color = 'green'
	} else if (gpu === 'stopped') {
		color = 'red'
	} else {
		color = 'yellow'
	}
	return color
}
const changeGpuState = (gpuState, dispatch) => {
	if (gpuState === 'stopped') {
		dispatch(startGpu())
	} else if (gpuState === 'running') {
		dispatch(stopGpu())
	}
}
const Header = () => {
	const { gpuState, djangoState, title } = useSelector(state => ({
		gpuState: state.common.gpu,
		djangoState: state.common.django,
		title: state.common.title,
	}))
	const buttonColor = getButtoncolor(gpuState, djangoState)
	const dispatch = useDispatch()
	return (
		<React.Fragment>
			<div className="Rectangle">
				<div>
					<img src={logo} srcSet={logo2x} className="logo" alt="" />
				</div>
				<div className="header">
					<div className="diamond"></div>
					<div className="title">{title}</div>
				</div>
				<div className="start">
					<Button
						icon
						onClick={e => changeGpuState(gpuState, dispatch)}
						data-tip={gpuState}
						className="power"
					>
						<Icon name="power" color={buttonColor}></Icon>{' '}
					</Button>
					<img src={profile} srcSet={profile2x} className="profile" alt="" />
				</div>
			</div>
			<ReactTooltip place="bottom" type="dark" effect="solid">
				<p>{'GPU : ' + gpuState}</p>
				<p>{'Django : ' + djangoState}</p>
			</ReactTooltip>
		</React.Fragment>
	)
}

export default Header
