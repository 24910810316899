import React, { useState, useEffect } from 'react'
import { Grid, Image, Table, Dropdown, Button, Icon, Form, Radio, Segment, Popup } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip'

import { BACKEND_URL } from 'constants/constants'
// import { DateDropDown, PackageDropDown, PlatformDropDown, SurfaceDropDown } from 'components/DropDown'

import marked from '../../assests/images/marked.png'
import unmarked from '../../assests/images/unmarked.png'
import feedback from '../../assests/images/feedback.png'
// import send from '../../assests/images/send.png'
// import analyse from '../../assests/images/analyse.png'
// import tag from '../../assests/images/tag.png'
// import more from '../../assests/images/more.png'
import glass from '../../assests/images/glass.svg'
import cancel from '../../assests/images/cancel.svg'

import { useDropDownFilter } from 'hooks/useDropDownFilter'

const options = {
	1: 'Original feedback',
	2: 'Adjusted feedback',
	3: 'Adjustments',
}

const options1 = {
	1: 'All',
	2: 'Bookmarked',
	3: 'Not Bookmarked',
}
// export const SelectFilters = props => {
// 	return (
// 		<Grid columns="equal" className="filters">
// 			<Grid.Row className="filter-row">
// 				<span className="filter-label">Select the Filters</span>
// 			</Grid.Row>
// 			<Grid.Row className="filter-row1">
// 				<DateDropDown></DateDropDown>
// 				<PackageDropDown></PackageDropDown>
// 				<PlatformDropDown></PlatformDropDown>
// 				<SurfaceDropDown></SurfaceDropDown>
// 			</Grid.Row>
// 		</Grid>
// 	)
// }

export const SelectedCards = props => {
	const showTakeFeedback = props.showTakeFeedback || false
	return (
		<React.Fragment>
			{props.checkedCount > 0 && (
				<React.Fragment>
					{showTakeFeedback && (
						<div className="select-all">
							<label className="b-contain">
								<span>Take feedback from image names</span>
								<input type="checkbox" />
								<div className="b-input"></div>
							</label>
						</div>
					)}
					<div className="selected-cells">
						<div className="heading">{props.allSelected ? props.total : props.checkedCount} Selected</div>
						<div className="action" onClick={() => props.navigate()}>
							<Image src={feedback} alt="feedback" />
							<span>Feedback</span>
						</div>
						{/* <div className="action">
            <Image src={analyse} alt="analyse" className="analyze" />
            <span>Analyse</span>
          </div> */}
						<div className="action-last" onClick={() => props.bookmark()}>
							<Image src={unmarked} alt="unmarked" />
							<span>Bookmark</span>
						</div>
						<div className="action">{/* <Image src={tag} alt="tag" />
							<span>Add a Tag</span> */}</div>
						{/* <div className="action-last">
            <Image src={send} alt="send" />
            <span>Send for Review</span>
          </div> */}
						<div className="close">
							<Icon name="close" size="large" onClick={() => props.reset()}></Icon>
						</div>
					</div>
				</React.Fragment>
			)}
			<div className="select-all">
				<label className="b-contain">
					<span>Select All</span>
					<input
						type="checkbox"
						onChange={e => props.selectAll(!props.allSelected)}
						checked={props.allSelected}
					/>
					<div className="b-input"></div>
				</label>
				{props.checkedCount > 0 && (
					<div className="feedback-grp">
						<span>Save Feedback</span>
						<div></div>
						<span>Cancel Feedback</span>
					</div>
				)}
			</div>
		</React.Fragment>
	)
}
export const GridView = props => {
	let actual = props.data.actual_defect && props.data.actual_defect.organization_defect_code ? true : false
	const data = props.data
	return (
		<div className="card">
			<div className="item-check">
				<Segment compact>
					<label className="b-contain">
						<input
							type="checkbox"
							onChange={e => props.onCheck(props.data.id, !props.data.viewChecked)}
							checked={props.data.viewChecked}
						/>
						<div className="b-input"></div>
					</label>
				</Segment>
			</div>
			<div className="item-image">
				<Image
					className="card-img"
					src={data.org_file.cloud_file_url ? data.org_file.cloud_file_url : BACKEND_URL + data.org_file.file}
					onClick={e => props.openZoom(props.index)}
				></Image>
			</div>
			<div className="item-number">{`${props.index + 1}.`}</div>
			<div className="item-title">{`${data.org_file.name.substring(35, 0)}...`}</div>
			<div className="item-result">
				<div className="item-result-name">AI Result:</div>
				<div className="item-result-title">
					{`${data.predicted_defect.organization_defect_code}( ${data.predicted_defect.name} )`}
					<div className={actual ? (data.is_correct ? 'button right' : 'button wrong') : ''}></div>
				</div>
			</div>
			<div className="item-result1">
				<div className="item-result-name">Feedback:</div>
				<div className="item-result-title">
					{actual ? `${data.actual_defect.organization_defect_code}( ${data.actual_defect.name} )` : ''}
				</div>
			</div>
			<div className="item-box">
				<Popup
					content="Feedback"
					position="left center"
					trigger={
						<div className="box1">
							<img
								src={feedback}
								alt="feedback"
								data-for="feedback"
								data-tip
								onClick={() => {
									props.navigate(data.org_file.id)
								}}
							></img>
						</div>
					}
				/>
				{/* <ReactTooltip id="feedback" place="left" type="light" effect="solid" className="card-tip">
					Feedback
				</ReactTooltip> */}
				<Popup
					content="Bookmark"
					position="left center"
					trigger={
						<div className="box2" data-for="bookmark" data-tip>
							<img
								src={data.is_bookmark ? marked : unmarked}
								alt="bookmark"
								onClick={e => props.bookmark(props.index, data.org_file.id, !data.is_bookmark)}
							></img>
						</div>
					}
				/>
				{/* <ReactTooltip id="bookmark" place="left" type="light" effect="solid" className="card-tip">
					Bookmark
				</ReactTooltip> */}
				{/* <div className="box3" data-tip data-for="more">
					<img src={more} alt="more" />
				</div>
				<ReactTooltip
					id="more"
					place="left"
					type="light"
					effect="solid"
					className="card-tip-more"
					clickable={true}
				>
					<div>
						<img src={analyse} alt="analyse" />
						Analyse
					</div>
					<div>
						<img src={tag} alt="analyse" />
						Add a tag
					</div>
					<div>
						<img src={send} alt="analyse" />
						Send for Review
					</div>
				</ReactTooltip> */}
				{/* <ReactTooltip
          id="more"
          place="left"
          type="light"
          effect="solid"
          className="card-tip-extra"
          data-iscapture="true"
        >
          
        </ReactTooltip> */}
			</div>
		</div>
	)
}
export const TableView = props => {
	return (
		<Grid>
			<Table striped className="misclass-table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>S.No.</Table.HeaderCell>
						<Table.HeaderCell>Unit</Table.HeaderCell>
						<Table.HeaderCell>Unit Name</Table.HeaderCell>
						<Table.HeaderCell>Unit's feedback</Table.HeaderCell>
						<Table.HeaderCell>Model's Results</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{props.data.map((item, i) => {
						return (
							<Table.Row className="misclass-row" key={i}>
								<Table.Cell>{i}</Table.Cell>
								<Table.Cell>
									<Image src={BACKEND_URL + item.product.resized}></Image>
								</Table.Cell>
								<Table.Cell>{item.product.name}</Table.Cell>
								<Table.Cell>{item.actual_defect.organization_specific_code}</Table.Cell>
								<Table.Cell>{item.predicted_defect.organization_specific_code}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		</Grid>
	)
}

export const Filters = props => {
	const [val, setVal] = useState(props.display)
	// const [filter, setFilter] = useState(props.filters)
	// const [defectFilter, setDefectFilter] = useState({
	//   predicted: [],
	//   feedback: [],
	// })
	// const [searchValue, setSearchValue] = useState({
	//   predicted: '',
	//   feedback: '',
	// })

	// useEffect(() => {
	//   setDefectFilter(props.defectFilter)
	// }, [props.defectFilter])
	// useEffect(() => {
	//   setFilter(props.filters)
	// }, [props.filters])
	const handleRadioChange = (e, { value }) => {
		e.persist()
		e.preventDefault()
		e.stopPropagation()
		setVal(value)
	}

	// const onInputClick = useCallback(e => {
	//   e.stopPropagation()
	// }, [])

	// const checkDefectFilter = (id, key) => {
	//   const temp = defectFilter[key]
	//   temp[id].checked = !temp[id].checked
	//   setDefectFilter({
	//     ...defectFilter,
	//     [key]: temp,
	//   })
	// }
	// /**
	//  * update search value
	//  * @param {event object} e : passed by javascript
	//  */
	// const handleChange = (e, key) => {
	//   e.persist()
	//   const val = e.target.value
	//   if (val !== '') {
	//     const re = new RegExp(_.escapeRegExp(parseInt(val)), 'i')
	//     if (key === 1) {
	//       setSearchValue({
	//         ...searchValue,
	//         predicted: val,
	//       })
	//       const filteredData = props.filters.defects.predicted.filter(({ val }) => re.test(val))
	//       setFilter({
	//         ...filter,
	//         defects: {
	//           ...filter.defects,
	//           predicted: filteredData,
	//         },
	//       })
	//     } else if (key === 2) {
	//       setSearchValue({
	//         ...searchValue,
	//         feedback: val,
	//       })
	//       const filteredData = props.filters.defects.feedback.filter(({ val }) => re.test(val))
	//       setFilter({
	//         ...filter,
	//         defects: {
	//           ...filter.defects,
	//           feedback: filteredData,
	//         },
	//       })
	//     }
	//   } else {
	//     if (key === 1) {
	//       setSearchValue({
	//         ...searchValue,
	//         predicted: '',
	//       })
	//     } else if (key === 2) {
	//       setSearchValue({
	//         ...searchValue,
	//         feedback: '',
	//       })
	//     }
	//     setFilter(props.filters)
	//   }
	// }

	// /**
	//  * search defects on input change
	//  * @param {event object} e : passed by javascript
	//  */
	// const onInputChange = e => {
	//   e.persist()
	//   // this.props.onSearch(e.target.value)
	// }

	return (
		<Grid.Row>
			<Grid.Column>
				<Dropdown
					multiple
					item
					icon={false}
					trigger={
						<Button
							content={options[props.display]}
							className="drop-btn"
							icon="caret down"
							labelPosition="right"
						></Button>
					}
				>
					<Dropdown.Menu className="displayDrop">
						<div className="displayMenu">
							<div className="dropTitle">Display</div>
							<div className="dropRadioBtn">
								<Form>
									<Form.Field>
										<Radio
											label="Original feedback"
											name="radioGroup"
											value={1}
											checked={val === 1}
											onChange={handleRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Adjusted feedback"
											name="radioGroup"
											value={2}
											checked={val === 2}
											onChange={handleRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Adjustments"
											name="radioGroup"
											value={3}
											checked={val === 3}
											onChange={handleRadioChange}
										/>
									</Form.Field>
								</Form>
							</div>
							<div>
								<Button className="applyBtn ss-big" onClick={e => props.setDisplay(val)}>
									Apply
								</Button>
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Column>
		</Grid.Row>
	)
}

// export const SelectedFilters = props => {
// 	return (
// 		<React.Fragment>
// 			<Grid.Row columns={1}>
// 				<Grid.Column className="column-box2">
// 					<div className="select-filter-title">
// 						<div className="selected-title">Selected Filters</div>
// 					</div>
// 					<div className="selected-filter-results-list">
// 						{/* <div className="selected-result">
//               <div className="selected-filter-title">Unit Name:</div>
//               <div className="selected-filter-results">
//                 {props.name && (
//                   <div className="label">
//                     {props.name.slice(0, 10) + '...'}
//                     <Icon name="close" onClick={e => props.resetNameFilter()} />
//                   </div>
//                 )}
//               </div>
//             </div> */}
// 						{/* <div className="vr"></div>
//             <div className="selected-result">
//               <div className="selected-filter-title">Predicted</div>
//               <div className="selected-filter-results">
//                 {props.defectFilter.predicted.map((val, i) => {
//                   return val.checked ? (
//                     <div className="label" key={i}>
//                       {val.val}
//                       <Icon name="close" onClick={e => props.removePredictedFilter(i, 'predicted')} />
//                     </div>
//                   ) : (
//                     ''
//                   )
//                 })}
//               </div>
//             </div>
//             <div className="vr"></div>
//             <div className="selected-result">
//               <div className="selected-filter-title">Feedback</div>
//               <div className="selected-filter-results">
//                 {props.defectFilter.feedback.map((val, i) => {
//                   return val.checked ? (
//                     <div className="label" key={i}>
//                       {val.val}
//                       <Icon name="close" onClick={e => props.removePredictedFilter(i, 'feedback')} />
//                     </div>
//                   ) : (
//                     ''
//                   )
//                 })}
//               </div>
//             </div>

//             <div className="vr"></div> */}
// 						<div className="selected-result">
// 							<div className="selected-filter-title">Display</div>
// 							<div className="selected-filter-results">
// 								<div className="label">
// 									{options[props.display]}
// 									<Icon name="close" />
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</Grid.Column>
// 			</Grid.Row>
// 		</React.Fragment>
// 	)
// }

export const GridGive = props => {
	const data = props.data
	let predictedCode = ''
	let predictedName = ''
	let actualCode = ''
	let actualName = ''
	let extraName = ''
	let extraCode = ''
	let actual = false
	if (data.predicted_defect) {
		predictedCode = data.predicted_defect.organization_defect_code
		predictedName = `( ${data.predicted_defect.name.substring(0, 3)}..)`
	}
	if (data.actual_defect && data.actual_defect.organization_defect_code && data.actual_defect.name) {
		actual = true
		actualCode = data.actual_defect.organization_defect_code
		actualName = `( ${data.actual_defect.name.substring(0, 3)}..)`
	}
	if (data.extra_defect) {
		extraName = `( ${data.extra_defect.name.substring(0, 3)}..)`
		extraCode = data.extra_defect.organization_defect_code
	}
	const [right, setRight] = useState(0)
	const [clickedWrong, setClickedWrong] = useState(false)

	useEffect(() => {
		if (!data.is_correct) {
			setRight(data.predicted_defect.name === data.actual_defect.name ? 1 : -1)
		} else {
			setRight(data.is_correct ? 1 : -1)
		}
	}, [data.is_correct, data.predicted_defect.name, data.actual_defect.name])

	useEffect(() => {
		setClickedWrong(false)
	}, [props.display])

	const handleSelectDefect = defect => {
		setClickedWrong(false)
		let isCorrect = defect.name === data.predicted_defect.name
		props.predictRight(data.org_file.id, defect, isCorrect)
		setRight(isCorrect ? 1 : -1)
	}
	return (
		<Grid className="card">
			<Grid.Row className="card-row">
				<Grid.Column width={1} className="check">
					<label className="b-contain">
						<input
							type="checkbox"
							onChange={e => props.onCheck(props.data.id, !props.data.giveChecked)}
							checked={props.data.giveChecked}
						/>
						<div className="b-input"></div>
					</label>
				</Grid.Column>
				<Grid.Column className="card-row-column">
					<Image
						className="clickable"
						src={
							data.org_file.cloud_file_url
								? data.org_file.cloud_file_url
								: BACKEND_URL + data.org_file.file
						}
						onClick={e => props.openZoom(props.index)}
					></Image>
				</Grid.Column>
				<Grid.Column width={9} className="card-row-column2">
					<Grid.Column className="index">{props.index + 1 + '.'}</Grid.Column>
					{data.org_file.name}
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="results">
				<div className="card-row2">
					<Grid.Column className="title">AI Result: </Grid.Column>
					<Grid.Column className="code">
						<span data-tip data-for={'ai' + props.index}>
							{predictedCode.length > 3
								? `${predictedCode.slice(0, 4)}.. ${predictedName}`
								: `${predictedCode} ${predictedName}`}
						</span>
						<span data-tip data-for={'ex' + props.index}>
							{extraCode.length > 3
								? `${extraCode.slice(0, 4)}.. ${extraName}`
								: `${extraCode} ${extraName}`}
						</span>
					</Grid.Column>
					<div className="btn-grp">
						<Button
							icon
							labelPosition="left"
							basic={!(right === 1 && actual)}
							className="right"
							onClick={e => {
								setRight(1)
								setClickedWrong(false)
								props.predictRight(data.org_file.id, data.predicted_defect, true)
							}}
						>
							<Icon name="check" />
						</Button>
						<Button
							icon
							className="wrong"
							labelPosition="left"
							basic={!(right === -1 && actual)}
							onClick={e => {
								setRight(-1)
								setClickedWrong(true)
								// props.recordWrongFeedback(data.org_file.id)
							}}
						>
							<Icon name="close" />
						</Button>
					</div>
				</div>
				<div className="card-row3">
					<Grid.Column className="title">Feedback:</Grid.Column>
					<Grid.Column className="drpdwn code">
						{clickedWrong ? (
							<SelectDefect defects={props.defects} selectFeedback={handleSelectDefect} />
						) : actual ? (
							<span data-tip data-for={'feedback' + props.index}>
								{actualCode.length > 3
									? `${actualCode.slice(0, 4)}.. ${actualName}`
									: `${actualCode} ${actualName}`}
							</span>
						) : (
							''
						)}
						<ReactTooltip
							id={'ai' + props.index}
							place="top"
							type="light"
							effect="solid"
							className="extraClass1"
						>
							<span>{data.predicted_defect.name}</span>
						</ReactTooltip>
						<ReactTooltip
							id={'feedback' + props.index}
							place="top"
							type="light"
							effect="solid"
							className="extraClass1"
						>
							<span>{data.actual_defect.name}</span>
						</ReactTooltip>
						{extraCode && extraName ? (
							<ReactTooltip
								id={'ex' + props.index}
								place="top"
								type="light"
								effect="solid"
								className="extraClass1"
							>
								<span>{data.extra_defect.name}</span>
							</ReactTooltip>
						) : (
							''
						)}
					</Grid.Column>
				</div>
			</Grid.Row>
		</Grid>
	)
}

export const SelectDefect = ({ defects, selectFeedback }) => {
	const { data, searchTerm, change, checkedDefect, toggleDefect } = useDropDownFilter(defects)

	const handleButtonClick = () => {
		selectFeedback(checkedDefect)
	}

	return (
		<Dropdown
			multiple
			text="Defect Class"
			onClick={e => {
				e.stopPropagation()
				e.persist()
			}}
			onChange={e => {
				e.stopPropagation()
				e.persist()
			}}
			onLabelClick={e => {
				e.stopPropagation()
				e.persist()
			}}
		>
			<Dropdown.Menu>
				<div className="heading">
					<div>Select the Class</div>
					<div className="icon-grp">
						<img src={glass} alt=""></img>
						<img src={cancel} alt=""></img>
					</div>
				</div>
				<div className="search">
					<input
						placeholder="Seacrh Here"
						onClick={e => {
							e.stopPropagation()
						}}
						val={searchTerm}
						onChange={e => {
							change(e)
						}}
					/>
				</div>
				<div className="options">
					{data.map((item, index) => {
						return (
							<Radio
								key={index}
								label={item.name}
								checked={item.id === checkedDefect.id}
								onClick={e => {
									e.stopPropagation()
								}}
								onChange={() => toggleDefect(item)}
							></Radio>
						)
					})}
					{/* <ul>
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <input
                    type="radio"
                    id={index}
                    name="radio-group"
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    checked={item.checked}
                    onChange={e => {
                      e.persist()
                      e.preventDefault()
                      e.stopPropagation()
                      toggleDefect(item.id)
                    }}
                  />
                  <label for={index}>{item.name}</label>
                </li>
              )
            })}
          </ul> */}
				</div>
				<button onClick={e => handleButtonClick()}>Apply</button>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export const SelectFilters = props => {
	const [bValue, setBValue] = useState(0)

	useEffect(() => {
		setBValue(props.bookmarkDisplay)
	}, [props.bookmarkDisplay])

	const handleBookmarkRadioChange = (e, { value }) => {
		e.persist()
		e.preventDefault()
		e.stopPropagation()
		setBValue(value)
	}

	return (
		<Grid columns="equal" className="filters">
			<Grid.Row className="filter-row">
				<span className="filter-label">Select the Filters</span>
			</Grid.Row>
			<Grid.Row className="filter-row1">
				<Dropdown
					multiple
					item
					trigger={<Button content={options1[bValue]} className="filter-btn" labelPosition="right"></Button>}
					className="platforms"
					onClose={() => setBValue(props.bookmarkDisplay)}
				>
					<Dropdown.Menu className="dropdown-menu">
						<div className="sort-options">
							<div className="sort-label">
								<span>Display</span>
								<span className="close-icon">
									<Icon name="close" className="clickable"></Icon>
								</span>
							</div>
							<Form>
								<Form.Field>
									<Radio
										label="All"
										name="radioGroup"
										value={1}
										checked={bValue === 1}
										onChange={handleBookmarkRadioChange}
									/>
								</Form.Field>
								<Form.Field>
									<Radio
										label="Bookmarked"
										name="radioGroup"
										value={2}
										checked={bValue === 2}
										onChange={handleBookmarkRadioChange}
									/>
								</Form.Field>
								<Form.Field>
									<Radio
										label="Not Bookmarked"
										name="radioGroup"
										value={3}
										checked={bValue === 3}
										onChange={handleBookmarkRadioChange}
									/>
								</Form.Field>
							</Form>
						</div>
						<div className="s-check">
							<Button className="applyBtn ss-big" onClick={e => props.setBookmarkDisplay(bValue)}>
								Apply
							</Button>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Row>
		</Grid>
	)
}

export const SelectedFilters = props => {
	let actual = props.defectDict[props.actual] ? props.defectDict[props.actual].organization_defect_code : ''
	let predicted = props.defectDict[props.predicted] ? props.defectDict[props.predicted].organization_defect_code : ''
	return (
		<React.Fragment>
			<Grid.Row columns={1} className="selected-filters">
				<Grid.Column className="column-box2">
					<div className="select-filter-title">
						<div className="selected-title">Selected Filters</div>
					</div>
					<div className="selected-filter-results-list">
						<div className="selected-result">
							<div className="selected-filter-title">Display</div>
							<div className="selected-filter-results">
								<div className="label">
									{options1[props.bookmarkDisplay]}
									<Icon name="close" />
								</div>
							</div>
						</div>
						{(actual || predicted) && (
							<div className="selected-result">
								<div className="selected-filter-title">Defects</div>
								<div className="selected-filter-results">
									<div className="label">
										{actual ? `AI Result : ${actual}` : ''}{' '}
										{predicted ? `Feedback : ${predicted}` : ''}
										<Icon name="close" onClick={e => props.clearIdFilter()} />
									</div>
								</div>
							</div>
						)}
					</div>
				</Grid.Column>
			</Grid.Row>
		</React.Fragment>
	)
}
