import React, { useState } from 'react'
import { Modal, Form, Button, Input } from 'semantic-ui-react'

export const AddModelForm = props => {
	const [name, setName] = useState('')
	const [host, setHost] = useState('')
	const [path, setPath] = useState('')
	const [code, setCode] = useState('')
	const [changeOutput, setChangeOutput] = useState(false)
	const [status, setStatus] = useState('inactive')

	const handleClick = () => {
		const modelForm = new FormData()
		modelForm.append('name', name)
		modelForm.append('model_server_host', host)
		modelForm.append('model_path', path)
		modelForm.append('organization_code', code)
		modelForm.append('change_model_output_from_defect_code_to_id', changeOutput)
		modelForm.append('status', status)
		props.handleSubmit(modelForm)
	}
	return (
		<Modal open={props.isOpen}>
			<Modal.Content>
				<Form>
					<Form.Field required>
						<label>Name</label>
						<Input placeholder="Name" onChange={e => setName(e.target.value)} />
					</Form.Field>
					<Form.Field required>
						<label>Host</label>
						<Input placeholder="Host" onChange={e => setHost(e.target.value)} />
					</Form.Field>
					<Form.Field required>
						<label>Path</label>
						<Input placeholder="Path" onChange={e => setPath(e.target.value)} />
					</Form.Field>
					<Form.Field required>
						<label>Organization code</label>
						<Input placeholder="Organization code" onChange={e => setCode(e.target.value)} />
					</Form.Field>
					<Form.Group required>
						<label>Change model Output</label>
						<Form.Radio
							label="Yes"
							value="true"
							checked={changeOutput}
							onChange={(e, { value }) => setChangeOutput(value)}
						/>
						<Form.Radio
							label="No"
							value="false"
							checked={!changeOutput}
							onChange={(e, { value }) => setChangeOutput(value)}
						/>
					</Form.Group>
					<Form.Group required>
						<label>Status</label>
						<Form.Radio
							label="Yes"
							value="active"
							checked={status === 'active'}
							onChange={(e, { value }) => setStatus(value)}
						/>
						<Form.Radio
							label="No"
							value="inactive"
							checked={status === 'inactive'}
							onChange={(e, { value }) => setStatus(value)}
						/>
					</Form.Group>
					<Button type="submit" onClick={e => handleClick()}>
						Submit
					</Button>
				</Form>
			</Modal.Content>
		</Modal>
	)
}
