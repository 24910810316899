import { useState } from 'react'

export function useZoom(inferences, loadMore = null, offset = 0) {
	const [zoomData, setZoomData] = useState({ index: -1, data: {}, open: false })

	const toggleZoomImage = (index, show = true) => {
		if (show) {
			if (index >= 0 && index <= inferences.length - 1) {
				let temp = inferences[index]
				setZoomData({
					index: index,
					data: temp,
					open: true,
				})
			}
			if (index === inferences.length - 1) {
				if (loadMore) {
					loadMore(offset + 20)
				}
			}
		} else {
			setZoomData({
				index: -1,
				data: {},
				open: false,
			})
		}
	}

	return { zoomData, setZoomData, toggleZoomImage }
}
