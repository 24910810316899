import { call, put, takeEvery, race, take, fork, delay, select } from 'redux-saga/effects'
import {
	SERVER_STATUS_REQ,
	START_AWS_POLLING,
	STOP_AWS_POLLING,
	START_DJANGO_POLLING,
	STOP_DJANGO_POLLING,
	START_GPU,
	STOP_GPU,
} from '../constants/action-types'
import Api from '../Api'
import {
	startAwsPolling,
	startDjangoPolling,
	stopAwsPolling,
	stopDjangoPolling,
	setGpuStatus,
	setError,
	setDjangoStatus,
	saveModels,
	getDefects,
	getModelDefects,
} from '../actions'

function* pollDjangoServer() {
	while (true) {
		try {
			const { model } = yield select(state => ({ model: state.common.model }))
			const status = yield Api.getDjangoServerStatus()
			yield put(setDjangoStatus('running'))
			if (status) {
				try {
					const response = yield Api.models()
					if (response) {
						yield put(saveModels(response.data.response))
						yield put(getDefects())
						if (model) yield put(getModelDefects(model))
					}
					yield put(stopDjangoPolling())
				} catch (e) {
					yield put(setError())
				}
			}
		} catch (e) {
			yield put(setDjangoStatus('stopped'))
			// yield put({ type: DJANGO_STATUS_UPDATE, django: 'stopped' })
			// yield put(stopDjangoPolling())
			yield delay(4000)
		}
	}
}
export function* pollDjangoServerWatcher() {
	yield take(START_DJANGO_POLLING)
	yield race([call(pollDjangoServer), take(STOP_DJANGO_POLLING)])
}

function* pollAwsServer() {
	while (true) {
		try {
			const state = yield call(() => {
				return Api.awsStatus()
			})
			// const state = 'running'
			if (state === 'running') {
				yield put(startDjangoPolling())
				yield put(setGpuStatus(state))
				yield put(stopAwsPolling())
			} else if (state === 'stopped') {
				yield put(setGpuStatus(state))
				yield put(stopAwsPolling())
			}
			if (state === 'stopped' || state === 'running') {
				yield put(stopAwsPolling())
			}
			yield put(setGpuStatus(state))
			yield delay(3000)
		} catch (e) {
			console.log('TCL: function*pollAwsServer -> e', e)
			yield put(setError())
		}
	}
}

export function* pollAwsServerWatcher() {
	yield take(START_AWS_POLLING)
	yield race([call(pollAwsServer), take(STOP_AWS_POLLING)])
}

function* getServerStatus(action) {
	const state = yield call(() => {
		return Api.awsStatus()
	})
	// const state = 'running'
	yield put(startDjangoPolling())
	if (state === 'running') {
	} else if (state === 'pending') {
		yield put(startAwsPolling())
	} else if (state === 'stopping') {
		yield put(startAwsPolling())
		yield put(setDjangoStatus(state))
	} else if (state === 'stopped') {
		yield put(setDjangoStatus(state))
	}
	yield put(setGpuStatus(state))
}

export function* serverStatusWatcher() {
	yield takeEvery(SERVER_STATUS_REQ, getServerStatus)
}

function* stopAwsServer() {
	yield call(() => {
		return Api.stopAws()
	})
	yield put(startAwsPolling())
	yield put(setGpuStatus('stopping'))
	yield put(setDjangoStatus('stopped'))
}

export function* stopAwsServerWatcher() {
	yield takeEvery(STOP_GPU, stopAwsServer)
}

function* startAwsServer() {
	yield call(() => {
		return Api.startAws()
	})
	yield put(startAwsPolling())
	yield put(setGpuStatus('pending'))
	yield put(setDjangoStatus('pending'))
}

export function* startAwsServerWatcher() {
	yield takeEvery(START_GPU, startAwsServer)
}

export default function* serverSaga() {
	yield fork(serverStatusWatcher)
	yield fork(pollDjangoServerWatcher)
	yield fork(pollAwsServerWatcher)
	yield fork(stopAwsServerWatcher)
	yield fork(startAwsServerWatcher)
}
