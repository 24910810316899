import { SAVE_CONFUSION_MATRIX } from 'constants/action-types'
import { RESET } from 'constants/action-types'
import { parseMatrix } from 'helpers'

const initialState = {
	loading: true,
	data: [],
	recall: [],
	precision: [],
	predicted: [],
	pTotal: [],
	aTotal: [],
	actual: [],
	labelCount: 0,
}

export default function matrix(state = initialState, action) {
	switch (action.type) {
		case SAVE_CONFUSION_MATRIX:
			return {
				...state,
				...parseMatrix(action.data.matrix),
				loading: false,
				labelCount: action.data.valid_label_count,
			}
		case RESET:
			return {
				...initialState,
			}
		default:
			return state
	}
}
