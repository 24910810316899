import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import Api from 'Api'
import { GridView, SelectedCards, SelectFilters, SelectedFilters } from '../Components'
import {
	setTitle,
	checkInference,
	uncheckInference,
	unselectAllInfereces,
	selectAllInferences,
	addInferenceBookmark,
	removeInferenceBookmark,
	resetPredictions,
	setError,
	resetCheckedPredictions,
	bookmarkCheckedInferences,
	setShowChecked,
	giveSingleFeedback,
	removeInference,
	saveParams,
} from 'actions'
import PredictionImageZoom from 'components/ImageZoom/PredictionImageZoom'
import { useZoom } from 'hooks/useZoom'
import { useFetch } from 'hooks/useFetch'
import { checkAnyBookmarked } from 'helpers'

const ViewFeedback = () => {
	const {
		model,
		session,
		actualDefectId,
		predictedDefectId,
		bookmarked,
		stateLoaded,
		defectDict,
		total,
		inferences,
		loading,
		accuracy,
		checkedCount,
		inferencesLoaded,
	} = useSelector(
		state => ({
			model: state.common.model,
			session: state.common.session,
			actualDefectId: state.common.actualDefectId,
			predictedDefectId: state.common.predictedDefectId,
			bookmarked: state.common.bookmarked,
			stateLoaded: state.common.stateLoaded,
			defectDict: state.common.defectDict,
			total: state.predictions.total,
			inferences: state.predictions.inferences,
			loading: state.predictions.loading,
			accuracy: state.overview.accuracy,
			checkedCount: state.predictions.viewChecked,
			inferencesLoaded: state.predictions.inferencesLoaded,
		}),
		shallowEqual
	)

	const history = useHistory()
	const dispatch = useDispatch()

	const [filteredData, setFilteredData] = useState([])
	const [allSelected, setAllSelected] = useState(false)
	const [bookmarkDisplay, setBookmarkDisplay] = useState(1)

	const { offset, loadMore, setOffset } = useFetch(
		stateLoaded && !inferencesLoaded,
		session,
		actualDefectId,
		predictedDefectId,
		bookmarked
	)
	const { zoomData, setZoomData, toggleZoomImage } = useZoom(inferences, loadMore, offset)

	const updateBookmarkDisplay = option => {
		dispatch(resetPredictions())
		setBookmarkDisplay(option)
		setOffset(0)
		let q = `?model=${model}&session=${session}`
		if (actualDefectId) q += `&actualDefectId=${actualDefectId}`
		if (predictedDefectId) q += `&predictedDefectId=${predictedDefectId}`
		if (option === 2) q += `&bookmarked=${true}`
		if (option === 3) q += `&bookmarked=${false}`
		dispatch(saveParams({ bookmarked: option }))
		history.replace(`${history.location.pathname}${q}`)
	}

	useEffect(() => {
		setFilteredData(inferences)
	}, [inferences])

	useEffect(() => {
		dispatch(setTitle('Feedback'))
	}, [dispatch])

	const predictRight = async (id, code) => {
		const formData = new FormData()
		formData.append('prediction_id', id)
		formData.append('adjusted_defect_id', code)
		await Api.reviewPrediction(formData).then(response => {})
	}

	const bookmark = (index, id, value) => {
		const formData = new FormData()
		formData.append('orgFileId', id)
		formData.append('sessionId', session)
		formData.append('isBookmark', value)
		value ? dispatch(addInferenceBookmark(id)) : dispatch(removeInferenceBookmark(id))
		if ((bookmarkDisplay === 2 && !value) || (bookmarkDisplay === 3 && value)) dispatch(removeInference(id))
		if (zoomData.open && zoomData.data.org_file.id === id) {
			setZoomData(obj => {
				return {
					...obj,
					data: {
						...obj.data,
						is_bookmark: value,
					},
				}
			})
		}
		Api.updateInference(formData)
			.then(_ => {
				if (_.data.success) {
				}
			})
			.catch(e => {
				dispatch(setError())
			})
	}

	const bookmarkChecked = () => {
		let { hasAny, hasAll } = checkAnyBookmarked(inferences, 'viewChecked')
		let isBookmark = hasAll ? !hasAll : hasAny ? true : !hasAll
		dispatch(bookmarkCheckedInferences('viewChecked', isBookmark))
		const patchData = {
			filters: {
				session_id: parseInt(session),
			},
		}
		if (bookmarkDisplay !== 1) patchData['filters']['is_bookmark'] = bookmarkDisplay === 2 ? true : false
		if (predictedDefectId) patchData['filters']['predicted_defect_id'] = predictedDefectId
		if (actualDefectId) patchData['org_file__defect_id'] = actualDefectId
		patchData['updates'] = {
			is_bookmark: isBookmark,
		}
		inferences.forEach((item, index) => {
			if (item.viewChecked) {
				if ((bookmarkDisplay === 2 && !isBookmark) || (bookmarkDisplay === 3 && isBookmark)) {
					dispatch(removeInference(item.org_file.id, 'viewChecked'))
				}
			}
		})
		if (allSelected) {
			setAllSelected(false)
		}
		Api.bookmarkAll(patchData).catch(e => dispatch(setError()))
	}

	const handleCardCheck = (id, value) => {
		value ? dispatch(checkInference(id, 'viewChecked')) : dispatch(uncheckInference(id, 'viewChecked'))
	}

	const handleSelectAll = () => {
		allSelected ? dispatch(unselectAllInfereces('viewChecked')) : dispatch(selectAllInferences('viewChecked'))
		setAllSelected(val => !val)
	}

	const navigate = (id = null) => {
		id ? dispatch(giveSingleFeedback(id)) : dispatch(setShowChecked('viewChecked'))
		history.push(`/feedback/give?model=${model}&session=${session}`)
	}

	const resetChecked = () => {
		setAllSelected(false)
		dispatch(resetCheckedPredictions('viewChecked'))
	}

	const clearIdFilter = () => {
		dispatch(resetPredictions())
		dispatch(saveParams({ actualDefectId: '', predictedDefectId: '' }))
		let q = `?&model=${model}&session=${session}`
		if (bookmarkDisplay === 2) q += `&bookmarked=${true}`
		if (bookmarkDisplay === 3) q += `&bookmarked=${false}`
		history.replace(`${history.location.pathname}${q}`)
	}

	return (
		<React.Fragment>
			{zoomData.open && (
				<PredictionImageZoom
					index={zoomData.index}
					data={zoomData.data}
					toggleZoomImage={toggleZoomImage}
					total={total}
					open={zoomData.open}
					predictRight={predictRight}
					bookmark={bookmark}
					loading={loading}
				></PredictionImageZoom>
			)}
			<div className="page feedback">
				<SelectFilters bookmarkDisplay={bookmarkDisplay} setBookmarkDisplay={updateBookmarkDisplay} />
				<SelectedFilters
					bookmarkDisplay={bookmarkDisplay}
					clearIdFilter={clearIdFilter}
					defectDict={defectDict}
					actual={actualDefectId}
					predicted={predictedDefectId}
				></SelectedFilters>
				{accuracy ? (
					<div className="accuracy-card">
						<div className="title">Accuracy</div>
						<div className="score">{`${parseFloat(accuracy * 100).toFixed(2)}%`}</div>
					</div>
				) : (
					<></>
				)}
				<SelectedCards
					total={total}
					selectAll={handleSelectAll}
					navigate={navigate}
					allSelected={allSelected}
					checkedCount={checkedCount}
					reset={resetChecked}
					bookmark={bookmarkChecked}
				></SelectedCards>
				<Grid columns={4} className="misclass-analysis">
					{filteredData.length > 0 && (
						<InfiniteScroll
							dataLength={inferences.length}
							next={() => loadMore(offset + 20)}
							hasMore={true}
							scrollThreshold={0.9}
							style={{ overflow: 'visible', width: '100%' }}
						>
							<div className="container" id="card-grid">
								{filteredData.map((item, i) => {
									return (
										<GridView
											data={item}
											predictRight={predictRight}
											key={i}
											index={i}
											openZoom={toggleZoomImage}
											bookmark={bookmark}
											onCheck={handleCardCheck}
											navigate={navigate}
										></GridView>
									)
								})}
							</div>
						</InfiniteScroll>
					)}
					{loading && inferences.length <= total && (
						<Grid.Row>
							<Loader>Loading</Loader>
						</Grid.Row>
					)}
				</Grid>
			</div>
		</React.Fragment>
	)
}

export default ViewFeedback
