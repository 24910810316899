import React, { Component } from 'react'
import { PieChart, Pie, Cell, Legend } from 'recharts'

/**
 * component to display pie chart
 */
export default class DemoPieChart extends Component {
	render() {
		const renderLegend = props => {
			const { payload } = props
			return (
				<ul>
					{payload.map((entry, index) => (
						<React.Fragment key={index}>
							<span>{entry.value}</span>
							<li key={`item-${index}`} style={{ color: entry.color }}>
								<span style={{ marginLeft: 0, color: 'black' }}>{entry.payload.payload.value}</span>
							</li>
						</React.Fragment>
					))}
				</ul>
			)
		}
		const data = this.props.data
		return (
			<React.Fragment>
				<PieChart
					width={this.props.width}
					height={this.props.height}
					onMouseEnter={this.onPieEnter}
					margin={this.props.margin}
				>
					{/* <Legend
            iconType="circle"
            align="left"
            verticalAlign="top"
            layout="vertical"
          /> */}
					<Legend
						content={renderLegend}
						verticalAlign="middle"
						// layout="vertical"
						// // margin={{ top: -50, left: 0, right: 0, bottom: 0 }}
					/>
					<Pie data={data} labelLine={false} outerRadius={80} innerRadius={50} fill="#8884d8" dataKey="value">
						{data.map((entry, index) => (
							<Cell key={index} fill={this.props.colors[index % this.props.colors.length]} />
						))}
					</Pie>
				</PieChart>
			</React.Fragment>
		)
	}
}
