import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Button, Dropdown } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import ReactResizeDetector from 'react-resize-detector'

import { VHeader, Matrix } from './Components'
import { setTitle, getConfusionMatrix, resetPredictions, saveParams } from 'actions'
import { downloadConfusionReport, modelName } from 'helpers'

const ConfusionMatrix = () => {
	const {
		model,
		session,
		defects,
		stateLoaded,
		defectDict,
		models,
		loading,
		data,
		recall,
		precision,
		pTotal,
		aTotal,
		actual,
		predicted,
		labelCount,
	} = useSelector(
		state => ({
			model: state.common.model,
			session: state.common.session,
			defects: state.common.defects,
			stateLoaded: state.common.stateLoaded,
			defectDict: state.common.defectDict,
			models: state.common.models,
			loading: state.matrix.loading,
			data: state.matrix.data,
			recall: state.matrix.recall,
			precision: state.matrix.precision,
			pTotal: state.matrix.pTotal,
			aTotal: state.matrix.aTotal,
			actual: state.matrix.actual,
			predicted: state.matrix.predicted,
			labelCount: state.matrix.labelCount,
		}),
		shallowEqual
	)
	const history = useHistory()
	const dispatch = useDispatch()

	const [adjusted, setAdjusted] = useState(false)

	useEffect(() => {
		if (stateLoaded && session) {
			dispatch(getConfusionMatrix(session))
		}
	}, [dispatch, session, stateLoaded])

	useEffect(() => {
		dispatch(setTitle('Performance Monitoring of the Platform'))
	}, [dispatch])

	const options = [
		{ key: 1, text: 'Actual', value: false },
		{ key: 2, text: 'Adjusted', value: true },
	]

	const navigate = (actual = '', predicted = '') => {
		dispatch(resetPredictions())
		dispatch(saveParams({ bookmarked: '' }))
		let q = `?model=${model}&session=${session}`
		if (predicted) q += `&actualDefectId=${predicted}`
		if (actual) q += `&predictedDefectId=${actual}`
		history.push(`/feedback/view${q}`)
	}

	return (
		<div className="confusion-bg">
			<div className="confusion">
				<Grid columns="equal" padded className="confusion-grid">
					<Grid.Row className="confusion-title">
						<Grid.Column width={8}>
							Confusion Matrix & Class wise precision, recall in {modelName(models, model)}
						</Grid.Column>
						{/* <Grid.Column>Display:</Grid.Column> */}
						<Grid.Column className="show-rows">
							<span>Display</span>
							<Dropdown
								options={options}
								selection
								value={adjusted}
								onChange={(e, { value }) => {
									setAdjusted(value)
								}}
							></Dropdown>
						</Grid.Column>
						<Grid.Column className="download">
							<Button
								className="box-button ss-big"
								onClick={e => downloadConfusionReport(adjusted, model, session)}
							>
								Download CSV
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<div className="confusion-grid">
					<React.Fragment>
						<VHeader defects={defects}></VHeader>
						<ReactResizeDetector handleHeight handleWidth>
							{({ width, height }) => (
								<Matrix
									width={width}
									height={height}
									// rows={rows}
									// maxRows={maxRows}
									data={data}
									loading={loading}
									defects={defects}
									defectDict={defectDict}
									// setRows={setRows}
									actual={actual}
									predicted={predicted}
									pTotal={pTotal}
									aTotal={aTotal}
									precision={precision}
									recall={recall}
									navigate={navigate}
									labelCount={labelCount}
									// gridRows={gridRows}
								></Matrix>
							)}
						</ReactResizeDetector>
					</React.Fragment>
				</div>
			</div>
			<Grid className="trend">
				<Grid.Row className="trend-row">
					<Grid.Column className="trend-row-column">Unitwise Analysis</Grid.Column>
				</Grid.Row>
				<Grid.Row className="trend-row2">
					<Grid.Column className="trend-row2-column">
						<Button
							onClick={e =>
								history.push(`/performance/unitwise-analysis?model=${model}&session=${session}`)
							}
						>
							Learn More
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

export default ConfusionMatrix
