import React, { Component } from 'react'
import { BarChart, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'
import { Label } from 'semantic-ui-react'

/**
 * component to render custom chart tooltip
 */
class CustomTooltip extends Component {
	render() {
		const { active } = this.props

		if (active) {
			const { payload } = this.props
			return (
				<div
					className="custom-tooltip"
					style={{
						background: '#ffffff',
						width: 65.4,
						height: 40,
						textAlign: 'center',
						boxShadow: '0 10px 20px 2px rgba(28, 167, 229, 0.2)',
						borderRadius: 1,
					}}
				>
					<p
						style={{
							fontFamily: 'Futura',
							fontSize: 16,
							lineHeight: 2.3,
							color: '#02435d',
						}}
					>{`${payload[0].value}`}</p>
				</div>
			)
		}

		return null
	}
}

// export class CustomizedAxisTick extends React.Component {
//   render() {
//     const { x, y, stroke, payload } = this.props
// 	const Bar = getBarShape(x, y, width, height, [0, 0, 0, 0])
//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
//           {payload.value}
//         </text>
//       </g>
//     )
//   }
// }

const CustomBar = props => {
	console.log('TCL: props', props)
	const { fill, x, y, width, height } = props
	return <rect x={x} y={y} width={width} height={height} fill={fill} rx={15} ry={15}></rect>
}
const getBarShape = (x, y, width, height, radius) => {
	const [tl, tr, bl, br] = radius
	const d = `M${x},${y + tl}
		a${tl},${tl} 0 0 1 ${tl},${-tl}
		h${width - tl - tr}
		a${tr},${tr} 0 0 1 ${tr},${tr}
		v${height - tr - br}
		a${br},${br} 0 0 1 ${-br},${br}
		h${bl + (br - width)}
		a${bl},${bl} 0 0 1 ${-bl},${-bl}
		z`
	return ({ fill, fillOpacity, stroke }) => <path d={d} fill={fill} fillOpacity={fillOpacity} stroke={stroke} />
}
/**
 * component to display bar chart
 */
export default class DemoBarchart extends Component {
	render() {
		const data = this.props.data
		return (
			<BarChart
				data={data}
				margin={this.props.margin}
				width={this.props.width}
				height={this.props.height}
				shape={<CustomBar></CustomBar>}
			>
				<XAxis
					dataKey="name"
					// tick={<CustomizedAxisTick />}
					axisLine={false}
					tickLine={false}
					tick={false}
					scale={'auto'}
				/>
				<YAxis
					label={this.props.label_y}
					axisLine={false}
					tickLine={false}
					allowDecimals={false}
					type="number"
					allowDataOverflow={false}
				>
					<Label value="asdsadasd"></Label>
				</YAxis>
				<Tooltip content={<CustomTooltip></CustomTooltip>} active={true} cursor={false} />
				/>
				<Bar
					dataKey="value"
					fill={this.props.colors[0]}
					barSize={15}
					shape={({ x, y, width, height, value, background, fill }) => {
						let border = [0, 0, 0, 0]
						if (height === background.height) {
							border = [5, 5, 5, 5]
						}
						const Bar = getBarShape(x, y, width, height, border)
						const BarOuter = getBarShape(background.x, background.y, background.width, background.height, [
							5,
							5,
							5,
							5,
						])
						return (
							<g>
								<BarOuter fillOpacity={1} fill={this.props.bgColors[0]}></BarOuter>
								<Bar fillOpacity={1} fill={fill} />
							</g>
						)
					}}
				>
					<LabelList
						dataKey="name"
						position="bottom"
						dy={15}
						offset={5}
						angle={-25}
						fill="#80a1ae"
					></LabelList>
				</Bar>
			</BarChart>
		)
	}
}
