import React, { Component } from 'react'
import { BarChart, XAxis, YAxis, Bar, Legend, LabelList } from 'recharts'
/**
 * component to render custom chart tooltip
 */
// class CustomTooltip extends Component {
//   render() {
//     const { active } = this.props

//     if (active) {
//       const { payload } = this.props
//       return (
//         <div
//           className="custom-tooltip"
//           style={{
//             background: '#ffffff',
//             width: 65.4,
//             height: 40,
//             textAlign: 'center',
//             boxShadow: '0 10px 20px 2px rgba(28, 167, 229, 0.2)',
//             borderRadius: 1,
//           }}
//         >
//           <p
//             style={{
//               fontFamily: 'Futura',
//               fontSize: 16,
//               lineHeight: 2.3,
//               color: '#02435d',
//             }}
//           >{`${payload[0].value}`}</p>
//         </div>
//       )
//     }

//     return null
//   }
// }
const getBarShape = (x, y, width, height, radius) => {
	const [tl, tr, bl, br] = radius
	const d = `M${x},${y + tl}
		a${tl},${tl} 0 0 1 ${tl},${-tl}
		h${width - tl - tr}
		a${tr},${tr} 0 0 1 ${tr},${tr}
		v${height - tr - br}
		a${br},${br} 0 0 1 ${-br},${br}
		h${bl + (br - width)}
		a${bl},${bl} 0 0 1 ${-bl},${-bl}
		z`
	return ({ fill, fillOpacity, stroke }) => <path d={d} fill={fill} fillOpacity={fillOpacity} stroke={stroke} />
}
/**
 * component to display double bar chart
 */
export default class DemoDoubleBarChart extends Component {
	renderColorfulLegendText(value, entry) {
		const { color } = entry
		return (
			<span
				style={{
					color,
					wordBreak: 'break-word',
					width: '20px',
				}}
			>
				{value}
			</span>
		)
	}

	render() {
		const data = this.props.data
		return (
			<BarChart data={data} margin={this.props.margin} width={this.props.width} height={this.props.height}>
				<XAxis dataKey="name" axisLine={false} tickLine={false} tick={false} />
				<YAxis
					axisLine={false}
					tickLine={false}
					label={this.props.label_y}
					allowDecimals={false}
					type="number"
					// width={10}
					// scale={e=>{console.log(e  )}}
					allowDataOverflow={false}
				/>
				{/* <Tooltip
					content={<CustomTooltip></CustomTooltip>}
					active={true}
					cursor={false}
				/> */}
				<Legend
					iconType="circle"
					iconSize={8}
					align="left"
					verticalAlign="middle"
					layout="vertical"
					margin={{ right: 15 }}
				/>
				<Bar
					name="Burr counts"
					dataKey="burr"
					fill={this.props.colors[0]}
					barSize={15}
					shape={({ x, y, width, height, value, background, fill }) => {
						let border = [0, 0, 0, 0]
						if (height === background.height) {
							border = [5, 5, 5, 5]
						}
						const Bar = getBarShape(x, y, width, height, border)
						const BarOuter = getBarShape(background.x, background.y, background.width, background.height, [
							5,
							5,
							5,
							5,
						])
						return (
							<g>
								<BarOuter fillOpacity={1} fill={this.props.bgColors[0]}></BarOuter>
								<Bar fillOpacity={1} fill={fill} />
							</g>
						)
					}}
				>
					>
				</Bar>
				<Bar
					name="Units"
					dataKey="units"
					fill={this.props.colors[1]}
					barSize={15}
					shape={({ x, y, width, height, value, background, fill }) => {
						let border = [0, 0, 0, 0]
						if (height === background.height) {
							border = [5, 5, 5, 5]
						}
						const Bar = getBarShape(x, y, width, height, border)
						const BarOuter = getBarShape(background.x, background.y, background.width, background.height, [
							5,
							5,
							5,
							5,
						])
						return (
							<g>
								<BarOuter fillOpacity={1} fill={this.props.bgColors[0]}></BarOuter>
								<Bar fillOpacity={1} fill={fill} />
							</g>
						)
					}}
				>
					<LabelList
						dataKey="name"
						position="bottom"
						dy={15}
						dx={-10}
						offset={5}
						angle={-25}
						fill="#80a1ae"
					></LabelList>
				</Bar>
			</BarChart>
		)
	}
}
