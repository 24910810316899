import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Card, Dropdown } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import { setTitle, getInferenceData } from 'actions'
import { modelName } from 'helpers'

const OverallPerformance = () => {
	const { model, session, stateLoaded, accuracy, models } = useSelector(state => ({
		model: state.common.model,
		session: state.common.session,
		stateLoaded: state.common.stateLoaded,
		accuracy: state.overview.accuracy,
		models: state.common.models,
	}))

	const dispatch = useDispatch()
	const history = useHistory()

	const [adjusted, setAdjusted] = useState(false)

	useEffect(() => {
		dispatch(setTitle('Performance Monitoring of the Platform'))
	}, [dispatch])

	useEffect(() => {
		if (stateLoaded && session) {
			dispatch(getInferenceData(session))
		}
	}, [dispatch, session, stateLoaded])

	const items = [
		{
			header: 'Accuracy',
			meta: () => {
				return (
					<div className="meta">
						{parseFloat(accuracy * 100).toFixed(2)}
						<span className="per">%</span>
					</div>
				)
			},
		},
		// {
		//   header: 'Precision',
		//   meta: () => {
		//     return (
		//       <div className="meta">
		//         {info.data.response.precision}
		//         <span className="per">%</span>
		//       </div>
		//     )
		//   },
		// },
		// {
		//   header: 'Recall',
		//   meta: () => {
		//     return (
		//       <div className="meta">
		//         {info.data.response.recall}
		//         <span className="per">%</span>
		//       </div>
		//     )
		//   },
		// },
	]

	const options = [
		{ key: 1, text: 'Actual', value: false },
		{ key: 2, text: 'Adjusted', value: true },
	]

	return (
		<div className="overall">
			<Grid columns="equal" className="cards-list">
				<Grid.Row className="confusion-title">
					<Grid.Column width={11}>
						Overall performance of the classification on {modelName(models, model)}
					</Grid.Column>
					<Grid.Column>Display:</Grid.Column>
					<Grid.Column className="show-rows">
						<Dropdown
							options={options}
							selection
							value={adjusted}
							onChange={(e, { value }) => {
								setAdjusted(value)
							}}
						></Dropdown>
					</Grid.Column>
				</Grid.Row>
				<Card.Group items={items} />
			</Grid>
			<Grid columns="equal" className="learn-more">
				<Grid.Row>
					<span className="title">Confusion Matrix, Class-wise Precision & Recall</span>
				</Grid.Row>
				<Button onClick={e => history.push(`/performance/confusion-matrix?model=${model}&session=${session}`)}>
					Learn More
				</Button>
			</Grid>
		</div>
	)
}

export default OverallPerformance
