import React, { useState, useEffect } from 'react'
import { Grid, Image, Table, Dropdown, Button, Icon, Form, Radio } from 'semantic-ui-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import { BACKEND_URL } from 'constants/constants'

const options = {
	1: 'Original feedback',
	2: 'Adjusted feedback',
	3: 'Adjustments',
}

export const GridView = React.memo(props => {
	const data = props.data
	const predicted = data.predicted_defect
		? data.predicted_defect.organization_defect_code + ' (' + data.predicted_defect.name.substring(0, 3) + '..)'
		: ''
	const actual = data.actual_defect
		? data.actual_defect.organization_defect_code + ' (' + data.actual_defect.name.substring(0, 3) + '..)'
		: ''
	const adjusted = data.adjusted_defect
		? data.adjusted_defect.organization_defect_code + ' (' + data.adjusted_defect.name.substring(0, 3) + '..)'
		: ''
	const extra = data.extra_defect
		? data.extra_defect.organization_defect_code + ' (' + data.extra_defect.name.substring(0, 3) + '..)'
		: ''
	const [right, setRight] = useState(0)
	const [feedbackLabel, setFeedbackLabel] = useState('')
	const [feedbackName, setFeedbackName] = useState('')
	const [clickedWrong, setClickedWrong] = useState(false)

	useEffect(() => {
		setClickedWrong(false)
	}, [props.display])

	useEffect(() => {
		let r = -1
		let name = ''
		let label = ''
		if (props.display === 1) {
			predicted === actual ? (r = 1) : (r = -1)
			label = actual
			name = data.actual_defect ? data.actual_defect.name : ''
		} else if (props.display === 2) {
			if (adjusted) {
				predicted === adjusted ? (r = 1) : (r = -1)
				label = adjusted
				name = data.adjusted_defect ? data.adjusted_defect.name : ''
			} else {
				predicted === actual ? (r = 1) : (r = -1)
				label = actual
				name = data.actual_defect ? data.actual_defect.name : ''
			}
		} else if (props.display === 3) {
			predicted === adjusted ? (r = 1) : (r = -1)
			label = adjusted
			name = data.adjusted_defect ? data.adjusted_defect.name : ''
		}
		setRight(r)
		setFeedbackLabel(label)
		setFeedbackName(name)
	}, [
		predicted,
		actual,
		adjusted,
		props.display,
		props.inndex,
		data.predicted_defect.name,
		data.adjusted_defect,
		data.actual_defect,
	])
	return (
		<Grid className="card">
			<Grid.Row className="card-row">
				<Grid.Column className="index">{props.index + 1 + '.'}</Grid.Column>
				<Grid.Column width={4} className="card-row-column">
					<Image
						className="clickable"
						src={
							data.org_file.cloud_file_url
								? data.org_file.cloud_file_url
								: BACKEND_URL + data.org_file.file
						}
						onClick={e => props.openZoom(props.index)}
					></Image>
				</Grid.Column>
				<Grid.Column width={10} className="card-row-column2">
					{data.org_file.name}
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="card-row2">
				<Grid.Column className="title">AI Result: </Grid.Column>
				<Grid.Column className="code">
					<span data-tip data-for={'ai' + props.index}>
						{predicted}
					</span>
					<span data-tip data-for={'ex' + props.index}>
						{extra}
					</span>
				</Grid.Column>
				<Grid.Column className="right">
					<Button
						icon
						labelPosition="left"
						disabled={!props.canGiveFeedback}
						basic={!(right === 1)}
						color="green"
						onClick={e => {
							setFeedbackLabel(predicted)
							setFeedbackName(data.predicted_defect.name)
							setRight(1)
							setClickedWrong(false)
							props.predictRight(data.org_file.id, data.predicted_defect, true)
						}}
					>
						<Icon name="check" />
					</Button>
				</Grid.Column>
				<Grid.Column className="wrong">
					<Button
						icon
						labelPosition="left"
						disabled={!props.canGiveFeedback}
						basic={!(right === -1)}
						color="red"
						onClick={e => {
							setRight(-1)
							setClickedWrong(true)
							props.recordWrongFeedback(data.org_file.id)
						}}
					>
						<Icon name="close" />
					</Button>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="card-row3">
				<Grid.Column className="feedback">Feedback:</Grid.Column>
				<Grid.Column className="drpdwn">
					{clickedWrong ? (
						<Dropdown
							text="Defect Class"
							options={props.defects.map((val, i) => {
								return {
									key: i,
									text: val.organization_defect_code + '  (' + val.name + ')',
									value: val.id,
								}
							})}
							onChange={(e, { value }) => {
								let x = _.find(props.defects, { id: value })
								props.predictRight(data.org_file.id, x, x.name === data.predicted_defect.name)
								setFeedbackLabel(x.organization_defect_code)
								setFeedbackName(x.name)
								if (x === predicted) {
									setRight(1)
								} else {
									setRight(-1)
								}
								setClickedWrong(false)
							}}
						></Dropdown>
					) : (
						<span data-tip data-for={'feedback' + props.index}>
							{feedbackLabel}
						</span>
					)}
					<ReactTooltip
						id={'ai' + props.index}
						place="top"
						type="light"
						effect="solid"
						className="extraClass1"
					>
						<span>{data.predicted_defect.name}</span>
					</ReactTooltip>
					{data.extra_defect ? (
						<ReactTooltip
							id={'ex' + props.index}
							place="top"
							type="light"
							effect="solid"
							className="extraClass1"
						>
							<span>{data.extra_defect.name}</span>
						</ReactTooltip>
					) : (
						''
					)}
					<ReactTooltip
						id={'feedback' + props.index}
						place="top"
						type="light"
						effect="solid"
						className="extraClass1"
					>
						<span>{feedbackName}</span>
					</ReactTooltip>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	)
})

export const TableView = props => {
	return (
		<Grid>
			<Table striped className="misclass-table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>S.No.</Table.HeaderCell>
						<Table.HeaderCell>Unit</Table.HeaderCell>
						<Table.HeaderCell>Unit Name</Table.HeaderCell>
						<Table.HeaderCell>Unit's feedback</Table.HeaderCell>
						<Table.HeaderCell>Model's Results</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{props.data.map((item, i) => {
						return (
							<Table.Row className="misclass-row" key={i}>
								<Table.Cell>{i}</Table.Cell>
								<Table.Cell>
									<Image src={BACKEND_URL + item.product.resized}></Image>
								</Table.Cell>
								<Table.Cell>{item.product.name}</Table.Cell>
								<Table.Cell>{item.actual_defect.organization_defect_code}</Table.Cell>
								<Table.Cell>{item.predicted_defect.organization_defect_code}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		</Grid>
	)
}

const options1 = {
	1: 'All',
	2: 'Bookmarked',
	3: 'Not Bookmarked',
}

export const Filters = props => {
	const [val, setVal] = useState(0)
	const [bValue, setBValue] = useState(0)
	useEffect(() => {
		setVal(props.display)
	}, [props.display])

	useEffect(() => {
		setBValue(props.bookmarkDisplay)
	}, [props.bookmarkDisplay])
	// const [filter, setFilter] = useState(props.filters)
	// const [defectFilter, setDefectFilter] = useState({
	//   predicted: [],
	//   feedback: [],
	// })
	// const [searchValue, setSearchValue] = useState({
	//   predicted: '',
	//   feedback: '',
	// })

	// useEffect(() => {
	//   setDefectFilter(props.defectFilter)
	// }, [props.defectFilter])
	// useEffect(() => {
	//   setFilter(props.filters)
	// }, [props.filters])
	const handleRadioChange = (e, { value }) => {
		e.persist()
		e.preventDefault()
		e.stopPropagation()
		setVal(value)
	}

	// const onInputClick = useCallback(e => {
	//   e.stopPropagation()
	// }, [])

	// const checkDefectFilter = (id, key) => {
	//   const temp = defectFilter[key]
	//   temp[id].checked = !temp[id].checked
	//   setDefectFilter({
	//     ...defectFilter,
	//     [key]: temp,
	//   })
	// }
	// /**
	//  * update search value
	//  * @param {event object} e : passed by javascript
	//  */
	// const handleChange = (e, key) => {
	//   e.persist()
	//   const val = e.target.value
	//   if (val !== '') {
	//     const re = new RegExp(_.escapeRegExp(parseInt(val)), 'i')
	//     if (key === 1) {
	//       setSearchValue({
	//         ...searchValue,
	//         predicted: val,
	//       })
	//       const filteredData = props.filters.defects.predicted.filter(({ val }) => re.test(val))
	//       setFilter({
	//         ...filter,
	//         defects: {
	//           ...filter.defects,
	//           predicted: filteredData,
	//         },
	//       })
	//     } else if (key === 2) {
	//       setSearchValue({
	//         ...searchValue,
	//         feedback: val,
	//       })
	//       const filteredData = props.filters.defects.feedback.filter(({ val }) => re.test(val))
	//       setFilter({
	//         ...filter,
	//         defects: {
	//           ...filter.defects,
	//           feedback: filteredData,
	//         },
	//       })
	//     }
	//   } else {
	//     if (key === 1) {
	//       setSearchValue({
	//         ...searchValue,
	//         predicted: '',
	//       })
	//     } else if (key === 2) {
	//       setSearchValue({
	//         ...searchValue,
	//         feedback: '',
	//       })
	//     }
	//     setFilter(props.filters)
	//   }
	// }

	// /**
	//  * search defects on input change
	//  * @param {event object} e : passed by javascript
	//  */
	// const onInputChange = e => {
	//   e.persist()
	//   // this.props.onSearch(e.target.value)
	// }
	const handleBookmarkRadioChange = (e, { value }) => {
		e.persist()
		e.preventDefault()
		e.stopPropagation()
		setBValue(value)
	}
	return (
		<Grid.Row>
			<Grid.Column>
				<Dropdown
					multiple
					item
					icon={false}
					trigger={
						<Button
							content={options[props.display]}
							className="drop-btn"
							icon="caret down"
							labelPosition="right"
						></Button>
					}
				>
					<Dropdown.Menu className="displayDrop">
						<div className="displayMenu">
							<div className="dropTitle">Display</div>
							<div className="dropRadioBtn">
								<Form>
									<Form.Field>
										<Radio
											label="Original feedback"
											name="radioGroup"
											value={1}
											checked={val === 1}
											onChange={handleRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Adjusted feedback"
											name="radioGroup"
											value={2}
											checked={val === 2}
											onChange={handleRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Adjustments"
											name="radioGroup"
											value={3}
											checked={val === 3}
											onChange={handleRadioChange}
										/>
									</Form.Field>
								</Form>
							</div>
							<div>
								<Button className="applyBtn ss-big" onClick={e => props.setDisplay(val)}>
									Apply
								</Button>
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Column>
			<Grid.Column>
				<Dropdown
					multiple
					item
					icon={false}
					trigger={
						<Button
							content={options1[props.bookmarkDisplay]}
							className="drop-btn"
							icon="caret down"
							labelPosition="right"
						></Button>
					}
					onClose={() => setBValue(props.bookmarkDisplay)}
				>
					<Dropdown.Menu className="displayDrop">
						<div className="displayMenu">
							<div className="dropTitle">Display</div>
							<div className="dropRadioBtn">
								<Form>
									<Form.Field>
										<Radio
											label="All"
											name="radioGroup"
											value={1}
											checked={bValue === 1}
											onChange={handleBookmarkRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Bookmarked"
											name="radioGroup"
											value={2}
											checked={bValue === 2}
											onChange={handleBookmarkRadioChange}
										/>
									</Form.Field>
									<Form.Field>
										<Radio
											label="Not Bookmarked"
											name="radioGroup"
											value={3}
											checked={bValue === 3}
											onChange={handleBookmarkRadioChange}
										/>
									</Form.Field>
								</Form>
							</div>
							<div>
								<Button className="applyBtn ss-big" onClick={e => props.setBookmarkDisplay(bValue)}>
									Apply
								</Button>
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Column>
		</Grid.Row>
	)
}

export const SelectedFilters = props => {
	let actual = props.defectDict[props.actual] ? props.defectDict[props.actual].organization_defect_code : ''
	let predicted = props.defectDict[props.predicted] ? props.defectDict[props.predicted].organization_defect_code : ''
	return (
		<React.Fragment>
			<Grid.Row columns={1} className="selected-filters">
				<Grid.Column className="column-box2">
					<div className="select-filter-title">
						<div className="selected-title">Selected Filters</div>
					</div>
					<div className="selected-filter-results-list">
						{/* <div className="selected-result">
              <div className="selected-filter-title">Unit Name:</div>
              <div className="selected-filter-results">
                {props.name && (
                  <div className="label">
                    {props.name.slice(0, 10) + '...'}
                    <Icon name="close" onClick={e => props.resetNameFilter()} />
                  </div>
                )}
              </div>
            </div> */}
						{/* <div className="vr"></div>
            <div className="selected-result">
              <div className="selected-filter-title">Predicted</div>
              <div className="selected-filter-results">
                {props.defectFilter.predicted.map((val, i) => {
                  return val.checked ? (
                    <div className="label" key={i}>
                      {val.val}
                      <Icon name="close" onClick={e => props.removePredictedFilter(i, 'predicted')} />
                    </div>
                  ) : (
                    ''
                  )
                })}
              </div>
            </div>
            <div className="vr"></div>
            <div className="selected-result">
              <div className="selected-filter-title">Feedback</div>
              <div className="selected-filter-results">
                {props.defectFilter.feedback.map((val, i) => {
                  return val.checked ? (
                    <div className="label" key={i}>
                      {val.val}
                      <Icon name="close" onClick={e => props.removePredictedFilter(i, 'feedback')} />
                    </div>
                  ) : (
                    ''
                  )
                })}
              </div>
            </div>

            <div className="vr"></div> */}
						<div className="selected-result">
							<div className="selected-filter-title">Display</div>
							<div className="selected-filter-results">
								<div className="label">
									{options[props.display]}
									<Icon name="close" />
								</div>
								<div className="label">
									{options1[props.bookmarkDisplay]}
									<Icon name="close" />
								</div>
							</div>
						</div>
						{actual && predicted && (
							<div className="selected-result">
								<div className="selected-filter-title">Defects</div>
								<div className="selected-filter-results">
									<div className="label">
										{`AI Result : ${actual} Feedback : ${predicted}`}
										<Icon name="close" onClick={e => props.clearIdFilter()} />
									</div>
								</div>
							</div>
						)}
					</div>
				</Grid.Column>
			</Grid.Row>
		</React.Fragment>
	)
}
