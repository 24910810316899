import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import memoize from 'memoize-one'

const INITIAL_STATE = { index: 0 }
/**
 * component to display pareto chart
 */
export default class Paretochart extends Component {
	constructor(props) {
		super(props)
		this.state = INITIAL_STATE
	}
	/**
	 * update componemt
	 */
	shouldComponentUpdate(nextProps, nextState) {
		let changed = false
		let index = 0
		for (index in nextProps) {
			if (JSON.stringify(nextProps[index]) !== JSON.stringify(this.props[index])) {
				changed = true
			}
		}

		if (this.state.index !== nextState.index) {
			changed = true
		}
		return changed
	}
	/**
	 * component will update
	 */
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (!Object.keys(nextProps.data)[nextState.index]) {
			this.selectDataset(0)
		}
	}
	/**
	 * sort data
	 */
	sortData(data) {
		const orderedData = Object.entries(data)
			.sort((a, b) => b[1] - a[1])
			.reduce((ordered, entry) => {
				ordered[entry[0]] = entry[1]
				return ordered
			}, {})
		return orderedData
	}
	/**
	 * get color of chart
	 */
	getChartColor = index => {
		const colors = ['#1ca7e5']

		if (index > colors.length) {
			index -= colors.length
		}
		return colors[index]
	}

	/**
	 * get selected legend
	 */
	getSelectedLegend(data, index) {
		return Object.keys(data)[index]
	}
	/**
	 * get sorted data in chart
	 */
	getSortedData = memoize((data, index) => {
		const selectedLegend = this.getSelectedLegend(data, index)
		return this.sortData(data[selectedLegend])
	})

	/**
	 * get data for chart
	 */
	getChartData(data) {
		const total = this.getMaxYAxisValue(data)
		const labels = Object.keys(data)
		const barDataset = Object.values(data).reduce(
			(set, e) => {
				set.data.push(e)
				return set
			},
			{
				label: 'value',
				data: [],
				backgroundColor: this.getChartColor(this.state.index),
				maxBarThickness: 30,
			}
		)

		let before = 0
		const lineData = barDataset.data.map(d => {
			const sum = (100 * d) / total + before
			before = sum
			return parseFloat(sum).toFixed(3)
		})

		const lineDataset = {
			yAxisID: 'B',
			label: this.getLineLabel(),
			data: lineData,
			type: 'line',
			borderWidth: 1.5,
			backgroundColor: 'transparent',
			borderColor: '#ff7c7c',
			pointRadius: 3,
		}

		return { labels, datasets: [lineDataset, barDataset] }
	}
	/**
	 * max value of Y axis in chart
	 */
	getMaxYAxisValue(data) {
		const maxYAxisValue = Object.values(data).reduce((sum, e) => {
			sum += e
			return sum
		}, 0)

		return maxYAxisValue
	}

	datasetKeyProvider() {
		return Math.random()
	}
	/**
	 * get line label for chart
	 */
	getLineLabel() {
		return this.props.lineLabel || 'value'
	}

	render() {
		const temp = { defects: this.props.data }
		// const temp = { defects: { Normal: 5, Burr: 10 } };
		const data = this.getSortedData(temp, this.state.index)

		if (!data) {
			return <React.Fragment></React.Fragment>
		}

		const chartData = this.getChartData(data)

		const maxYAxisValue = this.getMaxYAxisValue(data)

		const lineLabel = this.getLineLabel()
		/**
		 * design of X and Y axia in chart
		 */
		const options = {
			legend: {
				display: false,
				// fontColor: '#80a1ae'
			},

			scales: {
				xAxes: [
					{
						position: 'bottom',
						gridLines: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							fontFamily: 'Cabin',
							lineHeight: 1.45,
							fontColor: '#80a1ae',
						},
					},
					{
						position: 'top',
						offset: true,
						gridLines: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							fontFamily: 'Cabin',
							lineHeight: 1.45,
							fontColor: '#02435d',
							fontSize: '16',
							callback: function(value, index, values) {
								let x = chartData.datasets[1].data[index]
								return x > 0 ? x : ''
							},
						},
					},
				],

				yAxes: [
					{
						id: 'A',
						type: 'linear',
						position: 'left',
						gridLines: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							min: 0,
							max: maxYAxisValue,
							precision: 0,
							fontFamily: 'Cabin',
							lineHeight: 1.45,
							fontColor: '#80a1ae',
						},
						scaleLabel: {
							display: true,
							labelString: 'F R E Q U E N C Y',
							fontFamily: 'Cabin',
							fontWeight: 'normal',
							lineHeight: 1.33,
							fontColor: '#02435d',
						},
					},
					{
						id: 'B',
						type: 'linear',
						position: 'right',
						gridLines: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							min: 0,
							max: 100,
							stepSize: 20,
							callback: function(value, index, values) {
								return value + '%'
							},
							fontFamily: 'Cabin',
							lineHeight: 1.45,
							fontColor: '#80a1ae',
						},
						scaleLabel: {
							display: true,
							labelString: 'C U M  U L A T I V E   % A G E',
							fontFamily: 'Cabin',
							fontSize: '12',
							lineHeight: 1.33,
							fontColor: '#02435d',
						},
					},
				],
			},
			tooltips: {
				enabled: true,
				callbacks: {
					label: function(tooltipItem, data) {
						var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || ''
						if (datasetLabel === lineLabel) {
							return datasetLabel + ': ' + parseFloat(tooltipItem.yLabel).toFixed(1)
						} else {
							return datasetLabel + ': ' + tooltipItem.yLabel
						}
					},
				},
			},
		}

		return (
			<div className="paretoChart_">
				<div className="chart_">
					<Bar
						datasetKeyProvider={this.datasetKeyProvider}
						// width={400}
						// height={300}
						// backgroundColor= 'rgb(232,246,252)'
						data={chartData}
						options={options}
					/>
				</div>
				<div className="legend_">{this.getLegend()}</div>
			</div>
		)
	}

	selectDataset(index) {
		this.setState({ index })
	}
	/**
	 * get legend for chart
	 */
	getLegend() {
		const labels = Object.keys(this.props.data)

		const labelsDivs = labels.map((label, index) => {
			const labelClass = index === this.state.index ? 'label_' : 'labelLineThrough'
			const color = this.getColor(index)
			return (
				<div key={`labels_${label}_${index}`} className="legendLabel" onClick={() => this.selectDataset(index)}>
					<div className={`labelSquare legend all ${color}_dark ${color}Border`} />
					<div className={labelClass}>{label}</div>
				</div>
			)
		})

		return <div className="legend">{labelsDivs}</div>
	}
	/**
	 * get color for chart
	 */
	getColor(index) {
		const colors = ['blue', 'pink', 'gray', 'green', 'blue_two', 'orange', 'black', 'purple']

		if (index >= colors.length) {
			index -= colors.length
		}

		return colors[index]
	}
}
