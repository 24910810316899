import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Loader } from 'semantic-ui-react'
import { useHistory, Link } from 'react-router-dom'

import Api from 'Api'
import { SelectedCards, GridGive } from '../Components'
import {
	setTitle,
	addInferenceBookmark,
	removeInferenceBookmark,
	setError,
	predictDefect,
	resetCheckedPredictions,
	checkInference,
	selectAllInferences,
	unselectAllInfereces,
	bookmarkCheckedInferences,
	uncheckInference,
} from 'actions'
import PerformanceImageZoom from 'components/ImageZoom/PerformanceImageZoom'
import { useZoom } from 'hooks/useZoom'
import { checkAnyBookmarked } from 'helpers'

import caretLeft from '../../../assests/images/caretLeft.svg'

const GiveFeedback = () => {
	const {
		model,
		session,
		total,
		inferences,
		loading,
		defects,
		defectDict,
		giveChecked,
		showChecked,
		showCheckedKey,
		singleSelected,
	} = useSelector(
		state => ({
			model: state.common.model,
			session: state.common.session,
			total: state.predictions.total,
			loading: state.predictions.loading,
			inferences: state.predictions.inferences,
			defects: state.common.modelDefects,
			defectDict: state.common.defectDict,
			giveChecked: state.predictions.giveChecked,
			showChecked: state.predictions.showChecked,
			showCheckedKey: state.predictions.showCheckedKey,
			singleSelected: state.predictions.singleSelected,
		}),
		shallowEqual
	)

	const dispatch = useDispatch()
	const history = useHistory()

	const [filteredData, setFilteredData] = useState([])
	const [allSelected, setAllSelected] = useState(false)
	const [checkedCount, setCheckedCount] = useState(0)

	const { zoomData, setZoomData, toggleZoomImage } = useZoom(filteredData)

	useEffect(() => {
		if (model && session) {
			if (!showChecked && !showCheckedKey && singleSelected === -1) {
				history.push(`/feedback/view?model=${model}&session=${session}`)
			}
		}
	}, [history, model, session, showChecked, showCheckedKey, singleSelected])

	useEffect(() => {
		let temp = []
		if (singleSelected !== -1) {
			temp = inferences.filter(inference => {
				return inference.org_file.id === singleSelected
			})
		} else {
			temp = inferences.filter(inference => {
				return inference[showCheckedKey]
			})
		}
		setFilteredData(temp)
	}, [inferences, showCheckedKey, singleSelected])

	useEffect(() => {
		if (giveChecked === inferences.length) {
			setAllSelected(true)
		}
	}, [giveChecked, inferences.length])

	useEffect(() => {
		if (!allSelected) {
			setCheckedCount(giveChecked)
		}
	}, [allSelected, giveChecked])

	useEffect(() => {
		dispatch(setTitle('Feedback'))
	}, [dispatch])

	const predictRight = async (fileId, defect, isCorrect) => {
		const formData = new FormData()
		formData.append('defectId', defect.id)
		dispatch(predictDefect(fileId, defect, isCorrect))
		if (zoomData.open && zoomData.data.org_file.id === fileId) {
			setZoomData(item => {
				return {
					...item,
					data: {
						...item.data,
						actual_defect: { ...defect },
					},
				}
			})
		}
		await Api.predictDefect(fileId, formData).catch(e => {
			dispatch(setError())
		})
	}

	const bookmark = (index, id, value) => {
		const formData = new FormData()
		formData.append('orgFileId', id)
		formData.append('sessionId', session)
		formData.append('isBookmark', value)
		value ? dispatch(addInferenceBookmark(id)) : dispatch(removeInferenceBookmark(id))
		if (zoomData.open && zoomData.data.org_file.id === id) {
			setZoomData(obj => {
				return {
					...obj,
					data: {
						...obj.data,
						is_bookmark: value,
					},
				}
			})
		}
		Api.updateInference(formData)
			.then(_ => {
				if (_.data.success) {
				}
			})
			.catch(e => {
				dispatch(setError())
			})
	}

	const bookmarkChecked = () => {
		let { hasAny, hasAll } = checkAnyBookmarked(inferences, 'giveChecked')
		let isBookmark = hasAll ? !hasAll : hasAny ? true : !hasAll
		const patchData = {
			filters: {
				session_id: parseInt(session),
			},
		}
		patchData['updates'] = {
			is_bookmark: isBookmark,
		}
		dispatch(bookmarkCheckedInferences('giveChecked'))
		if (allSelected) {
			setAllSelected(false)
		}
		Api.bookmarkAll(patchData).catch(e => dispatch(setError()))
	}

	const handleCardCheck = (id, value) => {
		value ? dispatch(checkInference(id, 'giveChecked')) : dispatch(uncheckInference(id, 'giveChecked'))
	}

	const handleSelectAll = value => {
		value ? dispatch(selectAllInferences('giveChecked')) : dispatch(unselectAllInfereces('giveChecked'))
		setAllSelected(val => !val)
		setCheckedCount(filteredData.length)
	}

	const recordWrongFeedback = fileId => {
		const formData = new FormData()
		formData.append('orgFileId', fileId)
		formData.append('sessionId', session)
		formData.append('isCorrect', false)
		Api.updateInference(formData).catch(e => dispatch(setError()))
	}

	const resetChecked = () => {
		setAllSelected(false)
		dispatch(resetCheckedPredictions('giveChecked'))
	}

	return (
		<React.Fragment>
			{zoomData.open && (
				<PerformanceImageZoom
					index={zoomData.index}
					data={zoomData.data}
					toggleZoomImage={toggleZoomImage}
					total={total}
					open={zoomData.open}
					predictRight={predictRight}
					bookmark={bookmark}
					defects={defects}
					canGiveFeedback={true}
				></PerformanceImageZoom>
			)}
			<div className="page feedback">
				<div className="link">
					<img src={caretLeft} alt="" />
					<Link to={`/feedback/view?model=${model}&session=${session}`}>Back to View Feedback</Link>
				</div>
				{/* <SelectFilters /> */}
				<SelectedCards
					checkedCount={checkedCount}
					selectAll={handleSelectAll}
					total={total}
					showTakeFeedback={true}
					allSelected={allSelected}
					reset={resetChecked}
					bookmark={bookmarkChecked}
				></SelectedCards>
				{loading ? (
					<Grid.Row>
						<Loader>Loading</Loader>
					</Grid.Row>
				) : (
					<div className="card-grid" id="card-grid">
						{filteredData.length > 0 &&
							filteredData.map((item, i) => {
								return (
									<GridGive
										onCheck={handleCardCheck}
										data={item}
										predictRight={predictRight}
										key={i}
										index={i}
										openZoom={toggleZoomImage}
										defects={defects}
										defectDict={defectDict}
										recordWrongFeedback={recordWrongFeedback}
									></GridGive>
								)
							})}
					</div>
				)}
			</div>
		</React.Fragment>
	)
}

export default GiveFeedback
