import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Progress, Button, Icon, Image, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import { setTitle } from 'actions/index'
import { Paretochart } from 'components/Charts'
import { DEMO1 } from 'constants/constants'
import { getInferenceData, pollInferenceSession } from 'actions'

import refresh from 'assests/images/reboot-progress.png'
import closeButton from 'assests/images/icons-close.png'

// const getOverkillRate = paretoChart => {
// 	let normal = paretoChart.Normal
// 	let burr = paretoChart.Burr
// 	let overkillRate = (normal / (burr + normal)) * 100
// 	if (isNaN(overkillRate)) {
// 		overkillRate = 0
// 	}
// 	overkillRate = overkillRate.toFixed(2)
// 	return overkillRate
// }

/**
 * overview screen component
 */
const Overview = () => {
	const {
		model,
		session,
		stateLoaded,
		totalFiles,
		uploadedFiles,
		inferencedFiles,
		loading,
		defectDict,
		defectStats,
		uploading,
		showInferenceProgress,
		models,
	} = useSelector(state => ({
		model: state.common.model,
		session: state.common.session,
		stateLoaded: state.common.stateLoaded,
		totalFiles: state.overview.totalFiles,
		uploadedFiles: state.overview.uploadedFiles,
		inferencedFiles: state.overview.inferencedFiles,
		loading: state.overview.loading,
		defectDict: state.common.defectDict,
		defectStats: state.overview.defectStats,
		uploading: state.overview.uploading,
		showInferenceProgress: state.overview.showInferenceProgress,
		models: state.common.models,
	}))

	const dispatch = useDispatch()
	const history = useHistory()

	const [paretoChart, setParetoChart] = useState({})

	useEffect(() => {
		if (stateLoaded && session) {
			if (uploading) dispatch(pollInferenceSession(session))
			else if (loading && !uploading) dispatch(getInferenceData(session))
		}
	}, [dispatch, loading, model, session, stateLoaded, uploading])

	useEffect(() => {
		let cData = {}
		defectStats.forEach(function(item) {
			let x = defectDict[item.predicted_defect]
			cData = {
				...cData,
				[`${x.organization_defect_code} ( ${x.name} )`]: item.total,
			}
		})
		setParetoChart(cData)
	}, [defectDict, defectStats])

	useEffect(() => {
		dispatch(setTitle('Classification results of rejected units'))
	}, [dispatch])

	const getModel = () => {
		let temp = {}
		models.forEach(item => {
			if (item.id === parseInt(model)) temp = item
		})
		return temp
	}

	const uploadProgress = totalFiles ? (uploadedFiles / totalFiles) * 100 : 0
	const inferenceProgress = totalFiles ? (inferencedFiles / totalFiles) * 100 : 0
	const uploadLabel = uploadedFiles + ' / ' + totalFiles + ' images uploaded'
	const inferenceLabel = inferencedFiles + ' / ' + totalFiles + ' images inferenced'
	// const overkillRate = process.env.REACT_APP_DEMO === DEMO1 ? getOverkillRate(paretoChart) : 0
	const chart = loading ? (
		<Loader>Loading</Loader>
	) : (
		<Paretochart data={paretoChart} model={getModel().id}></Paretochart>
	)
	const btnText = process.env.REACT_APP_DEMO === DEMO1 ? 'Sub Groups' : 'Unit Wise'

	return (
		<div className="page overview">
			<div className="clear">
				<Button primary>
					<Image src={closeButton} className="closeButton"></Image>Clear results
				</Button>
			</div>
			{showInferenceProgress && (
				<div className="progress-box">
					<div className="progress-status">Upload Progress</div>
					<Progress percent={uploadProgress} label={uploadLabel} />
					<Image src={refresh} className="refreshButton"></Image>
				</div>
			)}
			<div className="progress-box">
				<div className="progress-status">Inference Progress</div>
				<Progress percent={inferenceProgress} label={inferenceLabel} />
				<Image src={refresh} className="refreshButton"></Image>
			</div>
			<Grid columns={2}>
				<Grid.Column className="chart">
					<div className="chart-label">{`Pareto Chart of ${getModel().name}`}</div>
					{chart}
				</Grid.Column>
				<Grid.Column className="extra">
					{/* {process.env.REACT_APP_DEMO === DEMO1 ? (
						<Grid.Row className="overkill">
							<div className="overkill-machine">
								<p>Overkill by machine</p>
							</div>
							<div className="Over-rejected-by-mac">Over rejected by machine but saved by ai</div>
							<div className="progress">
								<Progress percent={Math.floor(overkillRate)}></Progress>
								<span className="rate">{overkillRate + '%'}</span>
							</div>
						</Grid.Row>
					) : (
						''
					)} */}
					<Grid.Row className="analysis">
						<div className="Futher-Analysis">3. Further Analysis</div>
						<Button
							icon
							labelPosition="right"
							className="ss-main next-overview"
							onClick={e =>
								process.env.REACT_APP_DEMO === DEMO1
									? history.push('/prediction/subgroups')
									: history.push(`/prediction/unitwise?model=${model}&session=${session}`)
							}
						>
							{btnText} <Icon name="arrow right"></Icon>
						</Button>
					</Grid.Row>
				</Grid.Column>
			</Grid>
		</div>
	)
}

export default Overview
