import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Img from 'react-image'
import { Loader, Icon, Button } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip'

import { BACKEND_URL } from 'constants/constants'
import { SelectDefect } from 'screens/Feedback/Components'

const PerformanceImageZoom = props => {
	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress)
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	})

	const handleKeyPress = e => {
		if (e.keyCode === 39) {
			props.toggleZoomImage(props.index + 1)
		} else if (e.keyCode === 37) {
			props.toggleZoomImage(props.index + -1)
		}
	}
	const data = props.data
	const predicted = data.predicted_defect
		? data.predicted_defect.organization_defect_code + ' (' + data.predicted_defect.name.substring(0, 3) + '..)'
		: ''
	const actual =
		data.actual_defect && data.actual_defect.organization_defect_code
			? data.actual_defect.organization_defect_code + ' (' + data.actual_defect.name.substring(0, 3) + '..)'
			: ''
	const extra = data.extra_defect
		? data.extra_defect.organization_defect_code + ' (' + data.extra_defect.name.substring(0, 3) + '..)'
		: ''
	const [right, setRight] = useState(0)
	const [clickedWrong, setClickedWrong] = useState(false)

	useEffect(() => {
		if (!data.is_correct) {
			setRight(data.predicted_defect.name === data.actual_defect.name ? 1 : -1)
		} else {
			setRight(data.is_correct ? 1 : -1)
		}
	}, [data.is_correct, data.predicted_defect.name, data.actual_defect.name])

	useEffect(() => {
		setClickedWrong(false)
	}, [props.display])

	const handleSelectDefect = defect => {
		setClickedWrong(false)
		let isCorrect = defect.name === data.predicted_defect.name
		props.predictRight(data.org_file.id, defect, isCorrect)
		setRight(isCorrect ? 1 : -1)
	}
	return (
		<Modal open={props.open} dimmer="inverted" className="p-modal">
			<Modal.Content image scrolling>
				<div className="data" style={{ margin: 'auto' }}>
					<TransformWrapper
						defaultScale={1}
						defaultPositionX={200}
						defaultPositionY={100}
						enableWheel={false}
						options={{ maxScale: 3 }}
						zoomIn={{ step: 50 }}
						zoomOut={{ step: 50 }}
						reset={{ step: 1 }}
					>
						{({
							zoomIn,
							zoomOut,
							resetTransform,
							setTransform,
							scale,
							setScale,
							previousScale,
							...rest
						}) => (
							<div style={{ display: 'flex', flexDirection: 'column', width: '1100px' }}>
								<div className="zoom-image">
									<div className="main-image">
										<TransformComponent>
											<Img
												src={
													props.data.org_file.cloud_file_url
														? props.data.org_file.cloud_file_url
														: BACKEND_URL + props.data.org_file.file
												}
												loader={<Loader></Loader>}
											></Img>
										</TransformComponent>
									</div>
									<div className="image-content">
										<div className="close-button">
											<Icon
												name="close"
												size="large"
												onClick={e => props.toggleZoomImage(-1, false)}
											></Icon>
										</div>
										<div className="image-number">{props.index + 1 + '.'}</div>
										<div className="image-name">
											<div className="name">{props.data.org_file.name.slice(0, 40) + '...'}</div>
											<div className="icon-book"></div>
										</div>
										<div className="image-result">
											<div className="ai-title">AI Result </div>
											<div className="fibre">
												<span>{predicted}</span>
												<span>{extra}</span>
											</div>
											<div className="right">
												<Button
													icon
													labelPosition="left"
													basic={!(right === 1)}
													disabled={!props.canGiveFeedback}
													color="green"
													onClick={e => {
														setRight(1)
														setClickedWrong(false)
														props.predictRight(
															props.data.id,
															props.data.predicted_defect,
															true
														)
													}}
												>
													<Icon name="check" />
												</Button>
											</div>
											<div className="wrong">
												<Button
													icon
													labelPosition="left"
													basic={!(right === -1)}
													disabled={!props.canGiveFeedback}
													color="red"
													onClick={e => {
														setRight(-1)
														setClickedWrong(true)
													}}
												>
													<Icon name="close" />
												</Button>
											</div>
										</div>
										<div className="image-feedback">
											<div className="feed-title">Feedback</div>
											{clickedWrong ? (
												<SelectDefect
													defects={props.defects}
													selectFeedback={handleSelectDefect}
												></SelectDefect>
											) : (
												<div className="fibre">{actual}</div>
											)}
										</div>
										<div className="pre-next">
											<Button
												className="b-primary"
												icon
												labelPosition="left"
												color="red"
												onClick={e => props.toggleZoomImage(props.index - 1)}
												disabled={props.index ? false : true}
											>
												Pre
												<Icon name="angle left"></Icon>
											</Button>
											<Button
												className="b-primary"
												icon
												labelPosition="right"
												color="red"
												onClick={e => props.toggleZoomImage(props.index + 1)}
												disabled={props.total > 1 && props.index === props.total - 1}
											>
												{props.loading ? (
													<Loader></Loader>
												) : (
													<React.Fragment>
														Next
														<Icon name="angle right"></Icon>
													</React.Fragment>
												)}
											</Button>
										</div>
									</div>
								</div>
								<div className="tools">
									<div className="scale">
										<Icon
											name="minus"
											className="clickable"
											size="small"
											data-tip
											data-for="zoomOut"
											onClick={e => zoomOut(e)}
										></Icon>
										<div>
											<input
												data-tip
												data-for="scale"
												id="range1"
												type="range"
												value={scale}
												min="1"
												max="3"
												step="any"
												onChange={e => setScale(e.target.value)}
											/>
										</div>
										<Icon
											name="plus"
											className="clickable"
											size="small"
											data-tip
											data-for="zoomIn"
											onClick={e => zoomIn(e)}
										></Icon>
										{/* <Icon
              name="undo"
              className="clickable"
              size="small"
              data-tip
              data-for="reset"
              onClick={this.props.resetTransform}
            ></Icon> */}
									</div>
									<ReactTooltip id="zoomOut" place="top" type="dark" effect="solid">
										<span>
											Zoom out <br />
											Press -
										</span>
									</ReactTooltip>
									<ReactTooltip id="zoomIn" place="top" type="dark" effect="solid">
										<span>
											Zoom in <br />
											Press +
										</span>
									</ReactTooltip>
									<ReactTooltip id="reset" place="top" type="dark" effect="solid">
										<span>
											Reset <br /> Press space
										</span>
									</ReactTooltip>
									<ReactTooltip id="scale" place="top" type="dark" effect="solid">
										<span>{parseFloat(scale).toFixed(0) * 100}</span>
									</ReactTooltip>
								</div>
							</div>
						)}
					</TransformWrapper>
				</div>
			</Modal.Content>
		</Modal>
	)
}
export default PerformanceImageZoom
