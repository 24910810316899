import AnnotationChannel from '../screens/DataAnnotation/AnnotationChannel'
import VIAAnalysis from '../screens/DataAnnotation/VIAAnalysis'
import Overview from '../screens/Prediction/Overview'
import Unitwise from '../screens/Prediction/Unitwise'
import Glossary from '../screens/Glossary'
import PerformancePage from 'screens/PerformanceMonitor'
import Feedback from 'screens/Feedback'
import DataAcquisition from 'screens/DataAcquisition'

/**
 * demo 4 configration
 */
export const demo4 = {
	manyModels: true,
	routes: [
		{
			path: '/data-acquisition',
			component: DataAcquisition,
			name: 'Data Acquisition',
		},
		{
			path: '/prediction/overview',
			component: Overview,
			name: 'Over View',
		},
		{
			path: '/prediction/unitwise',
			component: Unitwise,
			name: 'Unit Wise',
		},
		{
			path: '/glossary',
			component: Glossary,
			name: 'Glossary',
		},
		{
			path: '/data-annotation/channel',
			component: AnnotationChannel,
			name: 'Annotation Channel',
		},
		{
			path: '/data-annotation/via-analysis',
			component: VIAAnalysis,
			name: 'VIA Analysis',
		},
		{
			path: '/performance',
			component: PerformancePage,
			name: 'Performance',
		},
		{
			path: '/feedback',
			component: Feedback,
			name: 'Feedback',
		},
	],
	table: [
		{
			title: 'S.No.',
			icon: '',
			dropdown: '',
			width: 1,
		},
		{
			title: 'UNIT',
			icon: {
				name: 'arrow up',
			},
			dropdown: '',
			width: 2,
		},

		{
			title: 'UNIT NAME',
			icon: '',
			dropdown: true,
			width: 8,
			dropdownName: 'unit',
		},
		{
			title: 'DEFECT CLASS',
			icon: '',
			dropdown: true,
			width: null,
			dropdownName: 'defect',
		},
		// {
		//   title: 'CONFIDENCE SCORE',
		//   icon: '',
		//   dropdown: '',
		//   width: null,
		// },
	],
	paretoChart: {
		1: { '205-CU_VOIDS': 0, '216-CU_PUDDLE': 0, '209-CROWN': 0 },
		2: {
			PATT_SMALL_DEF: 0,
			SMALL_DEF: 0,
			PATT_SURF_BIG_DEF: 0,
			SURF_BIG_DEF: 0,
			BLK_DEP_DEF: 0,
			PIN_HOLE_CU_VOIDS: 0,
			PATT_FLAKE: 0,
			FLAKE: 0,
			PATT_RESIDUE: 0,
			RESIDUE: 0,
			WATERMARK: 0,
			ARC_SCRATCHES: 0,
			CU_VOIDS: 0,
			KILLER_SCRATCHES: 0,
			PEELING: 0,
			BOMB_DEF: 0,
		},
		3: {
			SMALL_DEF: 0,
			UNKNOWN_BIN: 0,
			'SURF_BIG_DEF / BLK_DEP_DEF': 0,
			PIN_HOLE_CU_VOIDS: 0,
			FLAKE: 0,
			RESIDUE: 0,
			WATERMARK: 0,
			ARC_SCRATCHES: 0,
			CU_VOIDS: 0,
			KILLER_SCRATCHES: 0,
			PEELING: 0,
			BOMB_DEF: 0,
		},
		4: {
			BLOCK_ETCH: 0,
			EMB_PARTICLE: 0,
			CU_VOIDS: 0,
			SUBSTRATE_DEF: 0,
			PATT_RESIDUE: 0,
			PATT_FLAKE: 0,
			SMALL_DEF: 0,
			FLAKE: 0,
			CU_PUDDLE: 0,
			CROWN: 0,
			MICRO_SCRATCH: 0,
			PATT_SURF_BIG_DEF: 0,
			KILLER_SCRATCHES: 0,
			PATT_SMALL_DEF: 0,
			SURF_BIG_DEF: 0,
		},
		5: {
			SMALL_DEF: 0,
			SURF_BIG_DEF: 0,
			RESIDUE: 0,
			WATERMARK: 0,
			BOMB_DEF: 0,
			FILAMENT: 0,
			ARCING: 0,
			BLK_DEP_DEF: 0,
			MTAL_CRACK: 0,
			WHISKER: 0,
			HDMD_PART: 0,
			FLAKE: 0,
			CRATER: 0,
			ARC_SCRATCHES: 0,
			KILLER_SCRATCHES: 0,
			UNKNOWN_BIN: 0,
		},
		6: {
			PATT_SMALL_DEF: 0,
			SMALL_DEF: 0,
			PATT_SURF_BIG_DEF: 0,
			SURF_BIG_DEF: 0,
			PATT_RESIDUE: 0,
			RESIDUE: 0,
			WATERMARK: 0,
			BOMB_DEF: 0,
			FILAMENT: 0,
			ARCING: 0,
			PEELING: 0,
			PATT_FLAKE: 0,
			FLAKE: 0,
			'CONT/VIA_MERGE': 0,
			HONEY_COMB: 0,
			STEP_HEIGHT: 0,
			PLUG_DEFECT: 0,
			ARC_SCRATCHES: 0,
			KILLER_SCRATCHES: 0,
			UNKNOWN_BIN: 0,
		},
	},
	defectsList: {
		1: ['205-CU_VOIDS', '216-CU_PUDDLE', '209-CROWN'],
		2: [
			'PATT_SMALL_DEF',
			'SMALL_DEF',
			'PATT_SURF_BIG_DEF',
			'SURF_BIG_DEF',
			'BLK_DEP_DEF',
			'PIN_HOLE_CU_VOIDS',
			'PATT_FLAKE',
			'FLAKE',
			'PATT_RESIDUE',
			'RESIDUE',
			'WATERMARK',
			'ARC_SCRATCHES',
			'CU_VOIDS',
			'KILLER_SCRATCHES',
			'PEELING',
			'BOMB_DEF',
		],
		3: [
			'SMALL_DEF',
			'UNKNOWN_BIN',
			'SURF_BIG_DEF / BLK_DEP_DEF',
			'PIN_HOLE_CU_VOIDS',
			'FLAKE',
			'RESIDUE',
			'WATERMARK',
			'ARC_SCRATCHES',
			'CU_VOIDS',
			'KILLER_SCRATCHES',
			'PEELING',
			'BOMB_DEF',
		],
		4: [
			'BLOCK_ETCH',
			'EMB_PARTICLE',
			'CU_VOIDS',
			'SUBSTRATE_DEF',
			'PATT_RESIDUE',
			'PATT_FLAKE',
			'SMALL_DEF',
			'FLAKE',
			'CU_PUDDLE',
			'CROWN',
			'MICRO_SCRATCH',
			'PATT_SURF_BIG_DEF',
			'KILLER_SCRATCHES',
			'PATT_SMALL_DEF',
			'SURF_BIG_DEF',
		],
	},
}
