import React,{ useEffect } from 'react'
import { Route, Router } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setTitle } from 'actions'
import ViewFeedback from './ViewFeedback'
import GiveFeedback from './GiveFeedback'

const Feedback = props => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setTitle('Feedback'))
	}, [dispatch])

	return (
		<Router history={props.history}>
			<Route path={`/feedback/view`} component={ViewFeedback}></Route>
			<Route path={`/feedback/give`} component={GiveFeedback}></Route>
		</Router>
	)
}

export default Feedback
