import { put, takeEvery, fork, delay, race, call, take, select } from 'redux-saga/effects'

import {
	UPLOAD_HISTORY_REQ,
	UPLOAD_IMAGE_REQ,
	PREDICT_DEFECT_UPDATE,
	PREDICT_DEFECT_REQ,
	DEFECTS_REQ,
	POLL_SESSION_REQ,
	INFERENCE_DATA_REQ,
	DEFECT_INFERENCES_REQ,
	CONFUSION_MATRIX_REQ,
	STOP_INFERENCE_POLLING,
	MODEL_DEFECTS_REQ,
} from '../constants/action-types'
import Api from '../Api'
import {
	saveDefects,
	setStateState,
	saveUploadHistory,
	setError,
	saveInferenceData,
	saveDefectInferences,
	saveConfusionMatrix,
	updateUploadCount,
	setUploadingStatus,
	stopInferencePolling,
	saveModelDefects,
} from 'actions'

const SLICE = parseInt(process.env.REACT_APP_SLICE)

function* uploadHistory(action) {
	try {
		const response = yield Api.uploadHistory(action.model)
		if (response && response.data.success) {
			yield put(saveUploadHistory(response.data.response))
		}
	} catch (e) {
		yield put(setError())
	}
}

function* uploadHistoryWatcher() {
	yield takeEvery(UPLOAD_HISTORY_REQ, uploadHistory)
}

function* inferenceHistory(action) {
	try {
		const response = yield Api.getInferences(action.sessionId)
		yield put(setUploadingStatus(false, response.data.response.file_count, false))
		yield put(saveInferenceData(response.data.response))
	} catch (error) {
		yield put(setError())
	}
}

function* inferenceHistoryWatcher() {
	yield takeEvery(INFERENCE_DATA_REQ, inferenceHistory)
}
function* defectInferences(action) {
	try {
		let extraParams = ''
		if (action.actual) {
			extraParams += `&actualDefectId=${action.actual}`
		}
		if (action.predicted) {
			extraParams += `&predictedDefectId=${action.predicted}`
		}
		if (action.bookmarked === 2) {
			extraParams += '&isBookmark=' + true
		}
		if (action.bookmarked === 3) {
			extraParams += '&isBookmark=' + false
		}
		const response = yield Api.defectInferences(action.sessionId, action.offset, extraParams)
		if (response) {
			yield put(saveDefectInferences(response.data.response))
		}
	} catch (error) {
		yield put(setError())
	}
}

function* defectInferencesWatcher() {
	yield takeEvery(DEFECT_INFERENCES_REQ, defectInferences)
}
function getFormData(action) {
	const formData = new FormData()
	formData.append('sessionId', action.payload.session)
	formData.append('performInference', true)
	if (action.payload.id) {
		formData.append('parentId', action.payload.id)
	}
	return formData
}
function* uploadImage(action) {
	try {
		const files = action.payload.files
		for (let i = 0; i < files.length; i += SLICE) {
			const formData = getFormData(action)
			files.slice(i, i + SLICE).forEach(function(o) {
				formData.append('file', o)
			})
			const response = yield Api.classifyImage(formData)
			if (response && response.data.success) {
				yield put(updateUploadCount(files.length))
			}
			// yield put({ type: UPLOAD_IMAGE_RESULT, result: response.data })
			// if (response && response.data.response.errors.length) {
			//   response.data.response.errors.forEach(function(o) {
			//     toast.error(o, { position: toast.POSITION.TOP_RIGHT })
			//   })
			// }
			// const response = yield call(uploadImageTask, formData)

			// if (response.data.response.errors.length) {
			//   const newFormData = getFormData(action)
			//   response.data.response.errors.forEach(function(o) {
			//     files.filter(function(i) {
			//       if (i.name === o.file) {
			//         newFormData.append('file', i)
			//       }
			//       return ''
			//     })
			//   })
			//   // yield call(uploadImageTask, newFormData)
			// }
		}
	} catch (error) {
		// toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
		console.log(error)
	}
}

function* uploadImageWatcher() {
	yield takeEvery(UPLOAD_IMAGE_REQ, uploadImage)
}

function* predictDefect(action) {
	try {
		yield Api.predictDefect(action.payload.queryParams)
		yield put({
			type: PREDICT_DEFECT_UPDATE,
			id: action.payload.id,
			predicted: true,
			predictedDefect: action.payload.predictedDefect,
		})
	} catch (e) {
		yield put(setError())
	}
}

function* predictDefectWatcher() {
	yield takeEvery(PREDICT_DEFECT_REQ, predictDefect)
}

export function* getDefects(payload) {
	try {
		const response = yield Api.defects(payload.model)
		if (response && response.data.success) {
			yield put(saveDefects(response.data.response))
			yield put(setStateState(true))
		} else {
			throw Error
		}
	} catch (e) {
		yield put(setError())
	}
}

export function* getModelDefects(payload) {
	try {
		const response = yield Api.defects(payload.model)
		if (response && response.data.success) {
			yield put(saveModelDefects(response.data.response))
		} else {
			throw Error
		}
	} catch (e) {
		yield put(setError())
	}
}
export function* defectsWatcher() {
	yield takeEvery(DEFECTS_REQ, getDefects)
	yield takeEvery(MODEL_DEFECTS_REQ, getModelDefects)
}

export function* pollSession(action) {
	const { session, totalFiles } = yield select(state => ({
		session: state.common.session,
		totalFiles: state.overview.totalFiles,
	}))
	while (true) {
		try {
			const response = yield Api.getInferences(session)
			if (response && response.data.success) {
				const inference_count = response.data.response.inference_file_count
				yield put(saveInferenceData(response.data.response))
				if (totalFiles === inference_count) yield put(stopInferencePolling())
			}
		} catch (e) {
			yield put(setError())
		}
		yield delay(3000)
	}
}

export function* pollSessionWatcher() {
	while (true) {
		yield take(POLL_SESSION_REQ)
		yield race([call(pollSession), take(STOP_INFERENCE_POLLING)])
	}
}

// export function* chartHistory(payload) {
//   try {
//     const response = yield Api.inference_session(payload.session)
//     if (response && response.data.success) {
//       yield put({ type: SAVE_SESSION_DATA, data: response.data.response })
//     }
//   } catch (e) {
//    yield put(setError())
//   }
// }

// export function* chartHistoryWatcher() {
//   yield takeEvery(CHART_HISTORY_REQ, chartHistory)
// }

export function* confusionMatrix(action) {
	try {
		const response = yield Api.confusionMatrix(action.sessionId)
		yield put(saveConfusionMatrix(response.data.response))
	} catch (error) {
		yield put(setError())
	}
}
export function* confusionMatrixWatcher() {
	yield takeEvery(CONFUSION_MATRIX_REQ, confusionMatrix)
}
export default function* apiSaga() {
	yield fork(uploadHistoryWatcher)
	yield fork(inferenceHistoryWatcher)
	yield fork(uploadImageWatcher)
	yield fork(predictDefectWatcher)
	yield fork(defectsWatcher)
	yield fork(pollSessionWatcher)
	yield fork(defectInferencesWatcher)
	yield fork(confusionMatrixWatcher)
}
