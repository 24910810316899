import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Grid, Loader } from 'semantic-ui-react'

import VIAiFrame from 'screens/DataAnnotation/VIAiFrame'
import Box from 'components/Box'
import { setTitle, uploadImageReq, getUploadHistory } from 'actions/index'
import { File, Folder, BACKEND_URL, VIADefaultAttributes } from 'constants/constants'
import { FileHistory, FolderHistory, DbHistory } from 'components/History'
import { getServerState } from 'actions'

class AnnotationChannel extends Component {
	_isMounted = false

	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			fileHistory: [],
			folderHistory: [],
			openVIA: false,
		}
		this.inputOpenFileRef = React.createRef()
		this.inputOpenFolderRef = React.createRef()
		this.showOpenFileDlg = this.showOpenFileDlg.bind(this)
		this.showOpenFolderDlg = this.showOpenFolderDlg.bind(this)
		this.handleFileUpload = this.handleFileUpload.bind(this)
		this.handleFolderUpload = this.handleFolderUpload.bind(this)
	}
	async componentDidMount() {
		this._isMounted = true

		this.props.setTitle({ title: 'Select your data annotation channel' })
		this.props.uploadHistory()
		if (this._isMounted) {
			this.setState({
				loading: false,
			})
		}
	}
	componentWillUnmount() {
		this._isMounted = false
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.fileHistory.length !== prevState.fileHistory.length ||
			nextProps.folderHistory.length !== prevState.folderHistory.length
		) {
			return {
				fileHistory: nextProps.fileHistory,
				folderHistory: nextProps.folderHistory,
			}
		}
		return null
	}
	componentDidUpdate() {
		// This codebase have been written for the StateButton
		// In the original case, when uploading image files/folders, the "Channel" page was not redirected to "VIA Analysis". Just have been changing "BROWSE" buttons' status to display uploading progress
		// Currently, the "Channel" page is redirected to "VIA Analysis" page where uploading progressbar is, once started uploading
		// In the further, if the logic is changed, reuse this codebase
		if (this.props.uploadingStatus.type && this.props.uploadingStatus.status === 'default') {
			let jsonVGG = {}
			for (let index = 0; index < this.props.files.length; index++) {
				const file = this.props.files[index]
				const vgg = {
					filename: BACKEND_URL + file.resized,
					name: file.name,
					size: -1,
					regions: [],
					file_attributes: {},
				}
				jsonVGG[BACKEND_URL + file.resized] = vgg
			}
			// Redirect to VGG HTML
			localStorage.setItem('viaDefaultAttributes', JSON.stringify(VIADefaultAttributes))
			localStorage.setItem('jsonVGG', JSON.stringify(jsonVGG))
			if (!this.state.openVIA) {
				this.setState({
					openVIA: true,
				})
			}
		}
	}
	showOpenFileDlg(e) {
		this.inputOpenFileRef.current.click()
	}
	showOpenFolderDlg(e) {
		this.inputOpenFolderRef.current.click()
	}
	async handleFileUpload(e) {
		e.persist()
		const files = Array.from(e.target.files)

		this.props.reset({ total: files.length })

		// This redux call has been designed for StateButton case which mentioned on above componentDidUpdate lifecycle
		// Currently, it has no meaning about action
		// In the further, if the logic is changed, reuse this codebase
		this.props.setUploadingStatus({ type: File, status: 'processing' })

		const URL = BACKEND_URL + '/api/v1/session'
		await axios.get(URL).then(response => {
			sessionStorage.setItem('session_id', response.data.session_id)
		})
		const session = sessionStorage.getItem('session_id')
		for (let i = 0; i < files.length; i++) {
			this.props.uploadImageReq({
				file: files[i],
				index: i,
				type: File,
				folder_name: '',
				session: session,
				isLastFile: !!(i === files.length - 1),
			})
		}
		// Once started uploading, redirect to "VIA Analysis" page where uploading progressbar is displayed
		this.props.history.push('/data-annotation/via-analysis')
	}
	async handleFolderUpload(e) {
		e.persist()
		const files = Array.from(e.target.files)

		this.props.reset({ total: files.length })

		// This redux call has been designed for StateButton case which mentioned on above componentDidUpdate lifecycle
		// Currently, it has no meaning about action
		// In the further, if the logic is changed, reuse this codebase
		this.props.setUploadingStatus({ type: Folder, status: 'processing' })

		const URL = BACKEND_URL + '/api/v1/session'
		await axios.get(URL).then(response => {
			sessionStorage.setItem('session_id', response.data.session_id)
		})
		const session = sessionStorage.getItem('session_id')
		const folder_name = files[0].webkitRelativePath.split('/')[0]
		for (let i = 0; i < files.length; i++) {
			this.props.uploadImageReq({
				file: files[i],
				index: i,
				type: Folder,
				folder_name: folder_name,
				session: session,
				isLastFile: !!(i === files.length - 1),
			})
		}
		// Once started uploading, redirect to "VIA Analysis" page where uploading progressbar is displayed
		this.props.history.push('/data-annotation/via-analysis')
	}
	seeResults = (session, type) => {
		sessionStorage.setItem('session_id', session)
		this.props.sessionHistory({ session: session, type: type })
		this.props.history.push('/data-annotation/via-analysis')
	}
	render() {
		return (
			<div className="DataAnnotation">
				<Box
					icon="database"
					content_header="Connect to your Database"
					content_data="Support for Orcale, PROMIS, SQL"
					button="CONNECT"
				></Box>
				<Box
					id={File}
					currentmenu="data-annotation"
					icon="image"
					content_header="Upload image files"
					content_data="Support for image files in .tif, .bmp and .png format"
					button="BROWSE"
					handleclick={this.showOpenFileDlg}
				></Box>
				<Box
					id={Folder}
					currentmenu="data-annotation"
					icon="folder open"
					content_header="Upload image folders"
					content_data="Support for image folders with images in .tif, .bmp and .png format"
					button="BROWSE"
					handleclick={this.showOpenFolderDlg}
				></Box>
				<Grid columns={1} className="history">
					{this.state.loading ? (
						<React.Fragment>
							<Grid.Row>
								<Loader active inline="centered" size="medium">
									Loading
								</Loader>
							</Grid.Row>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Grid.Row>
								<p>Connected Database paths</p>
							</Grid.Row>
							<DbHistory></DbHistory>
							<Grid.Row>
								<p>Uploaded Image file batches</p>
							</Grid.Row>
							<FileHistory
								uploadHistory={this.state.fileHistory}
								seeResults={this.seeResults}
							></FileHistory>

							<Grid.Row>
								<p>Uploaded Image folder batches</p>
							</Grid.Row>
							<FolderHistory
								uploadHistory={this.state.folderHistory}
								seeResults={this.seeResults}
							></FolderHistory>
						</React.Fragment>
					)}
				</Grid>

				<input
					ref={this.inputOpenFileRef}
					type="file"
					style={{ display: 'none' }}
					multiple
					onChange={this.handleFileUpload}
					accept="image/png,image/tif,image/bmp"
				/>
				<input
					ref={this.inputOpenFolderRef}
					directory=""
					webkitdirectory=""
					type="file"
					style={{ display: 'none' }}
					onChange={this.handleFolderUpload}
					accept="image/png,image/tif,image/bmp"
				/>

				<VIAiFrame open={this.state.openVIA}></VIAiFrame>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		uploadingStatus: state.app.uploadingStatus,
		files: state.app.files,
		fileHistory: state.app.fileHistory,
		folderHistory: state.app.folderHistory,
		django: state.app.django,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		setTitle: title => dispatch(setTitle(title)),
		// setUploadingStatus: status => dispatch(setUploadingStatus(status)),
		// sessionHistory: payload => dispatch(getSessionHistory(payload)),
		// reset: total => dispatch(reset(total)),
		setState: state => dispatch(getServerState(state)),
		uploadImageReq: data => dispatch(uploadImageReq(data)),
		uploadHistory: () => dispatch(getUploadHistory()),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AnnotationChannel)
