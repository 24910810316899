import React, { Component } from 'react'
import { Grid, Button, Image, Icon, Progress } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { setTitle } from 'actions'

import VIAImageCanvas from 'components/VIAImageCanvas'
import VIAImageZoom from 'components/VIAImageZoom'
import VIAConfigure from 'components/VIAConfigure'
import { BACKEND_URL } from 'constants/constants'

import no_data from 'assests/images/no_data.png'

class VIAAnalysis extends Component {
	constructor(props) {
		super(props)

		this.state = {
			vggData: [],
			image: null,
			name: null,
			uploaded: 0,
			total: 0,
			openImageZoom: false,
			index: 0,
			openVIAEditor: false,
			openVIAConfigure: false,
		}
	}

	componentDidMount() {
		this.props.setTitle({ title: "VGG Image Annotator's Analysis" })

		// Once processed images on VIA tool, the json result is saved to browser localstorage with 'jsonVGG' key
		// On this component's lifecycle, if there is json VIA result saved, then fetch that and drawing VIA images/canvas on grid
		const strJsonVGG = localStorage.getItem('jsonVGG')
		if (strJsonVGG) {
			const jsonVGG = JSON.parse(strJsonVGG)
			const vggData = []
			let key
			for (key in jsonVGG) {
				let value = jsonVGG[key]
				const res = value.filename.split('/')
				value.viaKey = key
				value.name = res[res.length - 1]
				vggData.push(value)
			}
			if (vggData && vggData.length > 0) {
				this.setState({
					vggData,
					total: vggData.length,
					uploaded: vggData.length,
				})
			}
		}
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		// updating component states upon uploading images to the back-end
		if (nextProps.files.length && nextProps.files.length !== prevState.vggData.length) {
			const vggData = nextProps.files.map(file => {
				const vgg = {
					viaKey: BACKEND_URL + file.resized,
					filename: BACKEND_URL + file.resized,
					name: file.name,
					size: -1,
					regions: [],
					file_attributes: {},
				}
				return vgg
			})
			return {
				vggData,
				total: nextProps.total,
				uploaded: nextProps.file_count,
			}
		}
		return null
	}

	showImages = index => {
		window.addEventListener('keydown', this.handleKeyPress)
		this.setState({
			openImageZoom: true,
			image: this.state.vggData[index].filename,
			name: this.state.vggData[index].name,
			index: index,
		})
	}
	// Next/Prev image on dialog magnified view
	toggleImage = value => {
		let index = this.state.index + value
		if (index >= 0 && index < this.state.vggData.length) {
			this.setState({
				index: index,
				image: this.state.vggData[index].filename,
				name: this.state.vggData[index].name,
			})
		}
	}
	// Close the dialog magnified view
	hideImages = e => {
		window.removeEventListener('keydown', this.handleKeyPress)
		this.setState({
			openImageZoom: false,
			index: 0,
			image: '',
			name: '',
		})
	}
	handleKeyPress(event) {
		if (event.keyCode === 39) {
			this.toggleImage(1)
		} else if (event.keyCode === 37) {
			this.toggleImage(-1)
		}
	}
	// Handle to open VIA Configuration Dialog
	openSixsenseVIAConfigure = e => {
		if (!this.state.openVIAConfigure) {
			this.setState({
				openVIAConfigure: true,
			})
		}
	}
	// Handle to close VIA Configuration Dialog
	closeSixsenseVIAConfgiure = e => {
		if (this.state.openVIAConfigure) {
			this.setState({
				openVIAConfigure: false,
			})
		}
	}
	// Handle to switch VIA iframe and image/region table, within magnified view
	handleVIAEditor = e => {
		const { openVIAEditor } = this.state
		// if (!openVIAEditor) {
		this.setState({
			openVIAEditor: !openVIAEditor,
		})
		// }
	}

	render() {
		const { vggData, total, uploaded, openVIAEditor } = this.state
		const progress = total ? (uploaded / total) * 100 : 0
		let label = uploaded + ' / ' + total + ' images uploaded'
		return (
			<React.Fragment>
				<div className="via-analysis">
					<div className="progress-box">
						<div className="progress-status">Progress Status</div>
						<Progress percent={progress} label={label} />
					</div>
					<VIAImageZoom
						open={this.state.openImageZoom}
						index={this.state.index}
						data={this.state.vggData[this.state.index]}
						hideImages={this.hideImages}
						name={this.state.name}
						toggleImage={this.toggleImage}
						total={this.state.total}
						openVIAEditor={openVIAEditor}
						handleVIAEditor={this.handleVIAEditor}
					></VIAImageZoom>
					<div style={{ display: this.state.showImages ? 'none' : '' }}>
						<div className="filter-result">
							<div className="total-images">{vggData.length} Images</div>
							<Button primary className="via-configuration-btn" onClick={this.openSixsenseVIAConfigure}>
								<Icon name="sign-in alternate"></Icon>VIA Configuration
							</Button>
						</div>
						{this.state.vggData.length ? (
							<div>
								<Grid columns={4} padded>
									{vggData.map((vgg, index) => (
										<Grid.Column key={index}>
											<VIAImageCanvas
												data={vgg}
												idPrefix="via-analysis-grid-"
												index={index}
												showImages={this.showImages}
											></VIAImageCanvas>
										</Grid.Column>
									))}
								</Grid>
							</div>
						) : (
							<div className="no-data">
								<Image src={no_data} style={{ margin: 'auto' }}></Image>
							</div>
						)}
					</div>
				</div>
				<VIAConfigure
					open={this.state.openVIAConfigure}
					handleClose={this.closeSixsenseVIAConfgiure}
				></VIAConfigure>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		files: state.files,
		file_count: state.uploaded,
		total: state.total,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		setTitle: title => dispatch(setTitle(title)),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VIAAnalysis)
