import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import { setTitle, setModel, setError, reset, saveParams, getModelDefects } from 'actions'
import { AddModelForm } from 'components/AddModelForm/AddModelForm'
import Api from 'Api'

const SelectDemo = () => {
	const { models } = useSelector(state => ({
		models: state.common.models,
	}))

	const dispatch = useDispatch()
	const history = useHistory()

	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		dispatch(setTitle('Select Inference Model'))
	}, [dispatch])

	const handleClick = model => {
		dispatch(setModel(model))
		dispatch(saveParams({ session: '', actualDefectId: '', predictedDefectId: '', bookmarked: '' }))
		dispatch(getModelDefects(model))
		dispatch(reset())
		let q = 'model=' + model
		history.push(`/data-acquisition?${q}`)
	}

	const handleSubmit = form => {
		Api.addModel(form).catch(e => dispatch(setError()))
		setIsOpen(false)
	}

	return (
		<div className="page SelectDemo">
			<AddModelForm isOpen={isOpen} handleSubmit={handleSubmit}></AddModelForm>
			{models.length ? (
				<Grid columns={1} stackable container>
					{models.map((val, i) => {
						return (
							<Grid.Column textAlign="center" key={i}>
								<Button size="massive" className="ss-big" onMouseUp={e => handleClick(val.id)}>
									{val.name}
								</Button>
							</Grid.Column>
						)
					})}
					<Grid.Column textAlign="center">
						<Button size="massive" className="ss-big" onClick={e => setIsOpen(true)}>
							Add Model
						</Button>
					</Grid.Column>
				</Grid>
			) : (
				<Grid.Column textAlign="center">
					<Loader>Loading</Loader>
				</Grid.Column>
			)}
		</div>
	)
}

export default SelectDemo
