import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'

// This component originally has been written to show VIA tool as a seperated dialog/iframe
// Currently, VIA tool iframe was integrated into VIAImageZoom component
// In the further, if the logic is changed, reuse this component
export default class VIAiFrame extends Component {
	render() {
		return (
			<Modal open={this.props.open} size="large" dimmer="inverted" className="modal-viaiframe">
				<Modal.Content image scrolling className="modal-viaiframe-content">
					<iframe
						id="via-iframe"
						src="/via.html"
						height="100%"
						width="100%"
						frameBorder="0"
						title="VIA"
					></iframe>
				</Modal.Content>
			</Modal>
		)
	}
}
