import React from 'react'
import { Table, Image, Icon, Button } from 'semantic-ui-react'

import connect from 'assests/images/connect.png'

const DbHistory = () => {
	return (
		<Table className="file-table db-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>S.No.</Table.HeaderCell>
					<Table.HeaderCell>STATUS</Table.HeaderCell>
					<Table.HeaderCell>
						PATH
						<Icon name="caret down" size="small" fitted></Icon>
					</Table.HeaderCell>
					<Table.HeaderCell>
						CONNECTION ON
						<Icon name="caret down" size="small" fitted></Icon>
					</Table.HeaderCell>
					<Table.HeaderCell>ACTIONS</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row className="unit-row">
					<Table.Cell>1</Table.Cell>
					<Table.Cell className="db-status">
						<div className="connect">
							<Image src={connect}></Image>
						</div>
						<div className="data">
							<div className="status">Connected</div>
							<div>1200 image</div>
							<div>transferred / second</div>
						</div>
					</Table.Cell>
					<Table.Cell>/User/Images</Table.Cell>
					<Table.Cell>Apr 14’19</Table.Cell>
					<Table.Cell>
						<Button className="app-button-secondary results ss-main">Disconnect</Button>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	)
}

export default DbHistory
