import { hot } from 'react-hot-loader/root'
import React, { useEffect } from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import Header from 'components/Header'
import AppMenu from 'components/Menu'
import ParseParams from 'components/ParseParams'
import { demo } from 'config'
import SelectDemo from 'screens/SelectDemo/SelectDemo'
import { getServerState } from 'actions'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getServerState())
	}, [dispatch])

	const routes = demo.routes
	
	return (
		<div className="container">
			<BrowserRouter>
				<ParseParams many={demo.manyModels} />
				<Header></Header>
				<div className="data-container">
					<AppMenu many={demo.manyModels}></AppMenu>
					{routes.map((route, i) => {
						return <Route key={i} path={route.path} component={route.component}></Route>
					})}
					{demo.manyModels ? <Route exact path="/" component={SelectDemo}></Route> : ''}
				</div>
			</BrowserRouter>
			<ToastContainer autoClose={3000} />
		</div>
	)
}

export default hot(App)
