import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import rootSaga from 'sagas/'
import { createBrowserHistory } from 'history'
import rootReducer from 'reducers/demo4'

export const history = createBrowserHistory()

let store = null

/**
 * load reducer based on demo set in env file
 */
const composeEnhancers = composeWithDevTools({
	// options like actionSanitizer, stateSanitizer
	// persistedStore,
})
const sagaMiddleware = createSagaMiddleware()

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
// if (process.env.REACT_APP_DEMO === DEMO1) {
//   store = createStore(demo1, enhancer)
// } else if (process.env.REACT_APP_DEMO === DEMO3) {
//   store = createStore(demo3, enhancer)
// } else if (process.env.REACT_APP_DEMO === DEMO4) {
// }

store = createStore(rootReducer, enhancer)
// store.subscribe(() => {
//   saveState(store.getState())
// })
sagaMiddleware.run(rootSaga)
export default store
