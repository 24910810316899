import axios from 'axios'
import escapeRegExp from 'lodash/escapeRegExp'
import _ from 'lodash'
import sum from 'lodash/sum'

import {
	BACKEND_URL,
	ASC,
	DESC,
	DEFECT,
	UNIT,
	VIA_REGION_SHAPE,
	VIA_REGION_POINT_RADIUS,
	VIA_THEME_REGION_BOUNDARY_WIDTH,
	VIA_THEME_BOUNDARY_LINE_COLOR,
	VIA_THEME_BOUNDARY_FILL_COLOR,
	VIA_REGION_LABEL_FONT,
} from '../constants/constants'

/**
 * method to download pdf report
 */
export const downloadReport = async () => {
	const URL = BACKEND_URL + '/api/v1/pdf_report?session=' + sessionStorage.getItem('session_id')
	await axios
		.get(URL)
		.then(response => {
			const link = document.createElement('a')
			link.href = BACKEND_URL + '/' + response.data.file
			link.setAttribute('download', 'file.pdf')
			link.setAttribute('target', 'blank')
			document.body.appendChild(link)
			link.click()
		})
		.catch(err => console.log(err))
}

/**
 * helper function to compare two values and sort accordingly
 * @param {*} defect1 : file data
 * @param {*} defect2 : file data
 * @param {*} sortDirection : direction of sorting asc(1) or desc(-1)
 * @param {*} type : to sort on unit name or defect class
 * @returns sorted data
 */
export function compare(defect1, defect2, sortDirection, type) {
	// Use toUpperCase() to ignore character casing
	let nameA = null
	let nameB = null
	if (type === UNIT) {
		nameA = defect1.org_file.name.toUpperCase()
		nameB = defect2.org_file.name.toUpperCase()
	} else if (type === DEFECT) {
		nameA = defect1.org_file.defect.name.toUpperCase()
		nameB = defect2.org_file.defect.name.toUpperCase()
	}

	let comparison = 0
	if (nameA > nameB) {
		comparison = 1
	} else if (nameA < nameB) {
		comparison = -1
	}
	return comparison * sortDirection
}

/**
 * method to sort unit in ascending direction
 * @param {object} defect1 : file data
 * @param {object} defect2 : file data
 * @returns sorted data
 */
export const sortUnitAsc = (defect1, defect2) => {
	return compare(defect1, defect2, ASC, UNIT)
}

/**
 * method to sort unit in descending direction
 * @param {object} defect1 : file data
 * @param {object} defect2 : file data
 * @returns sorted data
 */
export const sortUnitDesc = (defect1, defect2) => {
	return compare(defect1, defect2, DESC, UNIT)
}

/**
 * method to sort defects in ascending direction
 * @param {object} defect1 : file data
 * @param {object} defect2 : file data
 * @returns sorted data
 */
export const sortDefectAsc = (defect1, defect2) => {
	return compare(defect1, defect2, ASC, DEFECT)
}

/**
 * method to sort defects in descending direction
 * @param {object} defect1 : file data
 * @param {object} defect2 : file data
 * @returns sorted data
 */
export const sortDefectDesc = (defect1, defect2) => {
	return compare(defect1, defect2, DESC, DEFECT)
}

/**
 * method to apply combined filter on unit and defect column based on selected values
 * @param {array} unitList : list of values to be searched in unit column
 * @param {array} defectList : list of values to be filtered in defect column
 */
export function combinedFilter(unitList, defectList, files) {
	let temp = files
	if (unitList.length > 0) {
		let z = []
		unitList.forEach(function(item) {
			const re = new RegExp(escapeRegExp(item), 'i')
			const isMatch = result => re.test(result.org_file.name)
			let x = _.filter(temp, isMatch)
			z = [...z, ...x]
		})
		temp = z.length > 0 ? z : temp
	}
	let hasDefectFilter = _.find(defectList, {
		checked: true,
	})
	let y = []
	if (hasDefectFilter) {
		let l = _.filter(defectList, { checked: true })
		l.forEach(function(item) {
			temp.forEach(function(value) {
				if (value.org_file.defect.name === item.defect) {
					y = [...y, value]
				}
			})
			// let x = _.filter(temp, { defect: item.defect })
			// y = [...y, ...x]
		})
	}
	temp = y.length > 0 ? y : temp
	return temp
}

export const ec2Instance = () => {
	var AWS = require('aws-sdk')

	var ec2 = new AWS.EC2({
		accessKeyId: 'AKIAQXOX3L5NEYIADTET',
		secretAccessKey: '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',
		region: 'us-east-1',
	})
	var params = {
		InstanceIds: ['i-0cc68cb84738d4731'],
	}
	return { ec2, params }
}
/**
 * Import annotations object from VIA JSON
 * @public
 */
export function import_annotations_from_json(data) {
	if (!data) {
		return
	}

	let via_img_metadata = new file_metadata(data.filename, data.size, data.viaKey)

	// copy file attributes
	let file_key
	for (file_key in data.file_attributes) {
		if (file_key === '') {
			continue
		}
		via_img_metadata.file_attributes[file_key] = data.file_attributes[file_key]
	}

	// copy regions
	const regions = data.regions
	let region_index
	for (region_index in regions) {
		const region_i = new file_region()
		let shape_attribute_key
		for (shape_attribute_key in regions[region_index].shape_attributes) {
			region_i.shape_attributes[shape_attribute_key] = regions[region_index].shape_attributes[shape_attribute_key]
		}
		let region_attribute_key
		for (region_attribute_key in regions[region_index].region_attributes) {
			if (region_attribute_key === '') {
				continue
			}
			region_i.region_attributes[region_attribute_key] =
				regions[region_index].region_attributes[region_attribute_key]
		}

		// add regions only if they are present
		if (Object.keys(region_i.shape_attributes).length > 0 || Object.keys(region_i.region_attributes).length > 0) {
			via_img_metadata.regions.push(region_i)
		}
	}

	return via_img_metadata
}

/**
 * Generate File Metadata Class
 * @private
 */
function file_metadata(filename, size) {
	this.filename = filename
	this.size = size // file size in bytes
	this.regions = [] // array of file_region()
	this.file_attributes = {} // image attributes
}

/**
 * Initialize file attributes objects
 * @private
 */
function file_region() {
	this.shape_attributes = {} // region shape attributes
	this.region_attributes = {} // region attributes
}

/**
 * Entry for drawing regions on image/canvas
 * @private  {Object}         via_img_metadata   img via object
 * @private  {HTML Element}   _via_image         html image element for drawing regions
 * @private  {HTML Element}   _via_reg_canvas    html canvas element for drawing regions
 */
export function via_show_img(via_img_metadata, _via_image, _via_reg_canvas) {
	let _via_current_image_width = _via_image.naturalWidth
	let _via_current_image_height = _via_image.naturalHeight
	if (_via_current_image_width === 0 || _via_current_image_height === 0) {
		// for error image icon
		_via_current_image_width = 200
		_via_current_image_height = 150
	}

	let _via_canvas_width = _via_current_image_width
	let _via_canvas_height = _via_current_image_height

	const _via_current_image_css_width = _via_image.width
	const _via_current_image_css_height = _via_image.height

	if (_via_canvas_width > _via_current_image_css_width) {
		// resize image to match the panel width
		const scale_width = _via_current_image_css_width / _via_current_image_width
		_via_canvas_width = _via_current_image_css_width
		_via_canvas_height = _via_current_image_height * scale_width
	}

	if (_via_canvas_height > _via_current_image_css_height) {
		// resize further image if its height is larger than the image panel
		const scale_height = _via_current_image_css_height / _via_canvas_height
		_via_canvas_height = _via_current_image_css_height
		_via_canvas_width = _via_current_image_css_width * scale_height
	}

	_via_canvas_width = Math.round(_via_canvas_width)
	_via_canvas_height = Math.round(_via_canvas_height)

	const _via_canvas_scale = _via_current_image_width / _via_canvas_width

	via_img_metadata.regions = _via_load_canvas_regions(via_img_metadata.regions, _via_canvas_scale)

	_via_reg_canvas.width = _via_canvas_width
	_via_reg_canvas.height = _via_canvas_height

	let _via_reg_ctx = _via_reg_canvas.getContext('2d')
	_via_redraw_reg_canvas(_via_reg_canvas, _via_reg_ctx, via_img_metadata)
}

/**
 * Transform regions in image space to canvas space
 * @private
 */
function _via_load_canvas_regions(regions, _via_canvas_scale) {
	// load all existing annotations into _via_canvas_regions
	const _via_canvas_regions = []
	for (let i = 0; i < regions.length; ++i) {
		const region_i = new file_region()
		let key
		for (key in regions[i].shape_attributes) {
			region_i.shape_attributes[key] = regions[i].shape_attributes[key]
		}
		_via_canvas_regions.push(region_i)

		let x, y, cx, cy, r, rx, ry, width, height
		switch (_via_canvas_regions[i].shape_attributes['name']) {
			case VIA_REGION_SHAPE.RECT:
				x = regions[i].shape_attributes['x'] / _via_canvas_scale
				y = regions[i].shape_attributes['y'] / _via_canvas_scale
				width = regions[i].shape_attributes['width'] / _via_canvas_scale
				height = regions[i].shape_attributes['height'] / _via_canvas_scale

				_via_canvas_regions[i].shape_attributes['x'] = Math.round(x)
				_via_canvas_regions[i].shape_attributes['y'] = Math.round(y)
				_via_canvas_regions[i].shape_attributes['width'] = Math.round(width)
				_via_canvas_regions[i].shape_attributes['height'] = Math.round(height)
				break

			case VIA_REGION_SHAPE.CIRCLE:
				cx = regions[i].shape_attributes['cx'] / _via_canvas_scale
				cy = regions[i].shape_attributes['cy'] / _via_canvas_scale
				r = regions[i].shape_attributes['r'] / _via_canvas_scale
				_via_canvas_regions[i].shape_attributes['cx'] = Math.round(cx)
				_via_canvas_regions[i].shape_attributes['cy'] = Math.round(cy)
				_via_canvas_regions[i].shape_attributes['r'] = Math.round(r)
				break

			case VIA_REGION_SHAPE.ELLIPSE:
				cx = regions[i].shape_attributes['cx'] / _via_canvas_scale
				cy = regions[i].shape_attributes['cy'] / _via_canvas_scale
				rx = regions[i].shape_attributes['rx'] / _via_canvas_scale
				ry = regions[i].shape_attributes['ry'] / _via_canvas_scale
				// rotation in radians
				const theta = regions[i].shape_attributes['theta']
				_via_canvas_regions[i].shape_attributes['cx'] = Math.round(cx)
				_via_canvas_regions[i].shape_attributes['cy'] = Math.round(cy)
				_via_canvas_regions[i].shape_attributes['rx'] = Math.round(rx)
				_via_canvas_regions[i].shape_attributes['ry'] = Math.round(ry)
				_via_canvas_regions[i].shape_attributes['theta'] = theta
				break

			case VIA_REGION_SHAPE.POLYLINE: // handled by polygon
			case VIA_REGION_SHAPE.POLYGON:
				const all_points_x = regions[i].shape_attributes['all_points_x'].slice(0)
				const all_points_y = regions[i].shape_attributes['all_points_y'].slice(0)
				for (let j = 0; j < all_points_x.length; ++j) {
					all_points_x[j] = Math.round(all_points_x[j] / _via_canvas_scale)
					all_points_y[j] = Math.round(all_points_y[j] / _via_canvas_scale)
				}
				_via_canvas_regions[i].shape_attributes['all_points_x'] = all_points_x
				_via_canvas_regions[i].shape_attributes['all_points_y'] = all_points_y
				break

			case VIA_REGION_SHAPE.POINT:
				cx = regions[i].shape_attributes['cx'] / _via_canvas_scale
				cy = regions[i].shape_attributes['cy'] / _via_canvas_scale

				_via_canvas_regions[i].shape_attributes['cx'] = Math.round(cx)
				_via_canvas_regions[i].shape_attributes['cy'] = Math.round(cy)
				break

			default:
				break
		}
	}
	return _via_canvas_regions
}

/**
 * Canvas update routines
 * @private
 */
function _via_redraw_reg_canvas(_via_reg_canvas, _via_reg_ctx, _via_img_metadata) {
	_via_reg_ctx.clearRect(0, 0, _via_reg_canvas.width, _via_reg_canvas.height)
	draw_all_regions(_via_reg_ctx, _via_img_metadata.regions)
	draw_all_regions_id(_via_reg_ctx, _via_img_metadata)
}

/**
 * Draw all regions from json
 * @private
 */
function draw_all_regions(_via_reg_ctx, _via_canvas_regions) {
	let attr
	for (let i = 0; i < _via_canvas_regions.length; ++i) {
		attr = _via_canvas_regions[i].shape_attributes

		// region stroke style may depend on attribute value
		_via_reg_ctx.strokeStyle = VIA_THEME_BOUNDARY_FILL_COLOR

		switch (attr['name']) {
			case VIA_REGION_SHAPE.RECT:
				_via_draw_rect_region(_via_reg_ctx, attr['x'], attr['y'], attr['width'], attr['height'])
				break

			case VIA_REGION_SHAPE.CIRCLE:
				_via_draw_circle_region(_via_reg_ctx, attr['cx'], attr['cy'], attr['r'])
				break

			case VIA_REGION_SHAPE.ELLIPSE:
				_via_draw_ellipse_region(_via_reg_ctx, attr['cx'], attr['cy'], attr['rx'], attr['ry'], attr['theta'])
				break

			case VIA_REGION_SHAPE.POLYLINE: // handled by polygon
			case VIA_REGION_SHAPE.POLYGON:
				_via_draw_polygon_region(_via_reg_ctx, attr['all_points_x'], attr['all_points_y'], attr['name'])
				break

			case VIA_REGION_SHAPE.POINT:
				_via_draw_point_region(_via_reg_ctx, attr['cx'], attr['cy'])
				break

			default:
				break
		}
	}
}

/**
 * Draw Rect region
 * @private
 */
function _via_draw_rect_region(_via_reg_ctx, x, y, w, h) {
	// draw a fill line
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 2
	_via_draw_rect(_via_reg_ctx, x, y, w, h)
	_via_reg_ctx.stroke()

	if (w > VIA_THEME_REGION_BOUNDARY_WIDTH && h > VIA_THEME_REGION_BOUNDARY_WIDTH) {
		// draw a boundary line on both sides of the fill line
		_via_reg_ctx.strokeStyle = VIA_THEME_BOUNDARY_LINE_COLOR
		_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 4
		_via_draw_rect(
			_via_reg_ctx,
			x - VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			y - VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			w + VIA_THEME_REGION_BOUNDARY_WIDTH,
			h + VIA_THEME_REGION_BOUNDARY_WIDTH
		)
		_via_reg_ctx.stroke()

		_via_draw_rect(
			_via_reg_ctx,
			x + VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			y + VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			w - VIA_THEME_REGION_BOUNDARY_WIDTH,
			h - VIA_THEME_REGION_BOUNDARY_WIDTH
		)
		_via_reg_ctx.stroke()
	}
}

function _via_draw_rect(_via_reg_ctx, x, y, w, h) {
	_via_reg_ctx.beginPath()
	_via_reg_ctx.moveTo(x, y)
	_via_reg_ctx.lineTo(x + w, y)
	_via_reg_ctx.lineTo(x + w, y + h)
	_via_reg_ctx.lineTo(x, y + h)
	_via_reg_ctx.closePath()
}

/**
 * Draw Circle region
 * @private
 */
function _via_draw_circle_region(_via_reg_ctx, cx, cy, r) {
	// draw a fill line
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 2
	_via_draw_circle(_via_reg_ctx, cx, cy, r)
	_via_reg_ctx.stroke()

	if (r > VIA_THEME_REGION_BOUNDARY_WIDTH) {
		// draw a boundary line on both sides of the fill line
		_via_reg_ctx.strokeStyle = VIA_THEME_BOUNDARY_LINE_COLOR
		_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 4
		_via_draw_circle(_via_reg_ctx, cx, cy, r - VIA_THEME_REGION_BOUNDARY_WIDTH / 2)
		_via_reg_ctx.stroke()
		_via_draw_circle(_via_reg_ctx, cx, cy, r + VIA_THEME_REGION_BOUNDARY_WIDTH / 2)
		_via_reg_ctx.stroke()
	}
}

function _via_draw_circle(_via_reg_ctx, cx, cy, r) {
	_via_reg_ctx.beginPath()
	_via_reg_ctx.arc(cx, cy, r, 0, 2 * Math.PI, false)
	_via_reg_ctx.closePath()
}

/**
 * Draw Ellipse region
 * @private
 */
function _via_draw_ellipse_region(_via_reg_ctx, cx, cy, rx, ry, rr) {
	// draw a fill line
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 2
	_via_draw_ellipse(_via_reg_ctx, cx, cy, rx, ry, rr)
	_via_reg_ctx.stroke()

	if (rx > VIA_THEME_REGION_BOUNDARY_WIDTH && ry > VIA_THEME_REGION_BOUNDARY_WIDTH) {
		// draw a boundary line on both sides of the fill line
		_via_reg_ctx.strokeStyle = VIA_THEME_BOUNDARY_LINE_COLOR
		_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 4
		_via_draw_ellipse(
			_via_reg_ctx,
			cx,
			cy,
			rx + VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			ry + VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			rr
		)
		_via_reg_ctx.stroke()
		_via_draw_ellipse(
			_via_reg_ctx,
			cx,
			cy,
			rx - VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			ry - VIA_THEME_REGION_BOUNDARY_WIDTH / 2,
			rr
		)
		_via_reg_ctx.stroke()
	}
}

function _via_draw_ellipse(_via_reg_ctx, cx, cy, rx, ry, rr) {
	_via_reg_ctx.save()

	_via_reg_ctx.beginPath()
	_via_reg_ctx.ellipse(cx, cy, rx, ry, rr, 0, 2 * Math.PI)

	_via_reg_ctx.restore() // restore to original state
	_via_reg_ctx.closePath()
}

/**
 * Draw Polygon region
 * @private
 */
function _via_draw_polygon_region(_via_reg_ctx, all_points_x, all_points_y, shape) {
	// draw a fill line
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 2
	_via_reg_ctx.beginPath()
	_via_reg_ctx.moveTo(all_points_x[0], all_points_y[0])
	for (let i = 0; i < all_points_x.length; ++i) {
		_via_reg_ctx.lineTo(all_points_x[i], all_points_y[i])
	}
	if (shape === VIA_REGION_SHAPE.POLYGON) {
		_via_reg_ctx.lineTo(all_points_x[0], all_points_y[0]) // close loop
	}
	_via_reg_ctx.stroke()
}

/**
 * Draw Point region
 * @private
 */
function _via_draw_point_region(_via_reg_ctx, cx, cy) {
	// draw a fill line
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 2
	_via_draw_point(_via_reg_ctx, cx, cy, VIA_REGION_POINT_RADIUS)
	_via_reg_ctx.stroke()

	// draw a boundary line on both sides of the fill line
	_via_reg_ctx.strokeStyle = VIA_THEME_BOUNDARY_LINE_COLOR
	_via_reg_ctx.lineWidth = VIA_THEME_REGION_BOUNDARY_WIDTH / 4
	_via_draw_point(_via_reg_ctx, cx, cy, VIA_REGION_POINT_RADIUS - VIA_THEME_REGION_BOUNDARY_WIDTH / 2)
	_via_reg_ctx.stroke()
	_via_draw_point(_via_reg_ctx, cx, cy, VIA_REGION_POINT_RADIUS + VIA_THEME_REGION_BOUNDARY_WIDTH / 2)
	_via_reg_ctx.stroke()
}

function _via_draw_point(_via_reg_ctx, cx, cy, r) {
	_via_reg_ctx.beginPath()
	_via_reg_ctx.arc(cx, cy, r, 0, 2 * Math.PI, false)
	_via_reg_ctx.closePath()
}

/**
 * Draw ID Number badge for every region
 * @private
 */
function draw_all_regions_id(_via_reg_ctx, _via_img_metadata) {
	_via_reg_ctx.shadowColor = 'transparent'
	_via_reg_ctx.font = VIA_REGION_LABEL_FONT
	for (let i = 0; i < _via_img_metadata.regions.length; ++i) {
		const canvas_reg = _via_img_metadata.regions[i]

		const bbox = get_region_bounding_box(canvas_reg)
		let x = bbox[0]
		let y = bbox[1]
		let w = Math.abs(bbox[2] - bbox[0])

		const char_width = _via_reg_ctx.measureText('M').width
		const char_height = 1.8 * char_width

		let annotation_str = (i + 1).toString()
		let bgnd_rect_width
		const strw = _via_reg_ctx.measureText(annotation_str).width
		if (strw > w) {
			// if text overflows, crop it
			const str_max = Math.floor((w * annotation_str.length) / strw)
			if (str_max > 1) {
				annotation_str = annotation_str.substr(0, str_max - 1) + '.'
				bgnd_rect_width = w
			} else {
				annotation_str = annotation_str.substr(0, 1) + '.'
				bgnd_rect_width = 2 * char_width
			}
		} else {
			bgnd_rect_width = strw + char_width
		}

		if (
			canvas_reg.shape_attributes['name'] === VIA_REGION_SHAPE.POLYGON ||
			canvas_reg.shape_attributes['name'] === VIA_REGION_SHAPE.POLYLINE
		) {
			// put label near the first vertex
			x = canvas_reg.shape_attributes['all_points_x'][0]
			y = canvas_reg.shape_attributes['all_points_y'][0]
		} else {
			// center the label
			x = x - (bgnd_rect_width / 2 - w / 2)
		}

		// ensure that the text is within the image boundaries
		if (y < char_height) {
			y = char_height
		}

		// first, draw a background rectangle first
		_via_reg_ctx.fillStyle = 'black'
		_via_reg_ctx.globalAlpha = 0.8
		_via_reg_ctx.fillRect(
			Math.floor(x),
			Math.floor(y - 1.1 * char_height),
			Math.floor(bgnd_rect_width),
			Math.floor(char_height)
		)

		// then, draw text over this background rectangle
		_via_reg_ctx.globalAlpha = 1.0
		_via_reg_ctx.fillStyle = 'yellow'
		_via_reg_ctx.fillText(annotation_str, Math.floor(x + 0.4 * char_width), Math.floor(y - 0.35 * char_height))
	}
}

function get_region_bounding_box(region) {
	const d = region.shape_attributes
	let bbox = new Array(4)

	switch (d['name']) {
		case 'rect':
			bbox[0] = d['x']
			bbox[1] = d['y']
			bbox[2] = d['x'] + d['width']
			bbox[3] = d['y'] + d['height']
			break

		case 'circle':
			bbox[0] = d['cx'] - d['r']
			bbox[1] = d['cy'] - d['r']
			bbox[2] = d['cx'] + d['r']
			bbox[3] = d['cy'] + d['r']
			break

		case 'ellipse':
			let radians = d['theta']
			let radians90 = radians + Math.PI / 2
			let ux = d['rx'] * Math.cos(radians)
			let uy = d['rx'] * Math.sin(radians)
			let vx = d['ry'] * Math.cos(radians90)
			let vy = d['ry'] * Math.sin(radians90)

			let width = Math.sqrt(ux * ux + vx * vx) * 2
			let height = Math.sqrt(uy * uy + vy * vy) * 2

			bbox[0] = d['cx'] - width / 2
			bbox[1] = d['cy'] - height / 2
			bbox[2] = d['cx'] + width / 2
			bbox[3] = d['cy'] + height / 2
			break

		case 'polyline': // handled by polygon
		case 'polygon':
			const all_points_x = d['all_points_x']
			const all_points_y = d['all_points_y']

			let minx = Number.MAX_SAFE_INTEGER
			let miny = Number.MAX_SAFE_INTEGER
			let maxx = 0
			let maxy = 0
			for (let i = 0; i < all_points_x.length; ++i) {
				if (all_points_x[i] < minx) {
					minx = all_points_x[i]
				}
				if (all_points_x[i] > maxx) {
					maxx = all_points_x[i]
				}
				if (all_points_y[i] < miny) {
					miny = all_points_y[i]
				}
				if (all_points_y[i] > maxy) {
					maxy = all_points_y[i]
				}
			}
			bbox[0] = minx
			bbox[1] = miny
			bbox[2] = maxx
			bbox[3] = maxy
			break

		case 'point':
			bbox[0] = d['cx'] - VIA_REGION_POINT_RADIUS
			bbox[1] = d['cy'] - VIA_REGION_POINT_RADIUS
			bbox[2] = d['cx'] + VIA_REGION_POINT_RADIUS
			bbox[3] = d['cy'] + VIA_REGION_POINT_RADIUS
			break

		default:
			break
	}
	return bbox
}

export function formatDate(date) {
	var monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

	var day = date.getDate()
	var monthIndex = date.getMonth()
	var year = date.getFullYear()

	return day + ' ' + monthNames[monthIndex] + ', ' + year
}

export function unitwiseDefectFilter(defectFilter, data, display, name) {
	let predicted = _.filter(defectFilter.predicted, {
		checked: true,
	})
	let feedback = _.filter(defectFilter.feedback, {
		checked: true,
	})
	let temp = []
	let initData = data
	let hasFilter = false
	if (name) {
		hasFilter = true
		temp = _.filter(data, function(o) {
			return o.product.name === name
		})
		initData = temp
	}
	if (predicted.length || feedback.length) {
		hasFilter = true
		let a = []
		predicted.forEach(function(item) {
			let x = _.filter(initData, function(o) {
				return o.predicted_defect.organization_specific_code === item.val
			})
			a = [...a, ...x]
		})
		temp = [...a]
		feedback.forEach(function(item) {
			let d = _.filter(initData, 'adjusted_defect')
			let y = _.filter(d, function(o) {
				return o.adjusted_defect.organization_specific_code === item.val
			})
			temp = [...temp, ...y]
		})
	}
	if (!hasFilter) {
		temp = data
	}
	if (display === 3) {
		temp = _.filter(temp, 'adjusted_defect')
	}
	const filters = {
		name: name,
		defects: defectFilter,
		display: display,
	}
	sessionStorage.setItem('performance_filters', JSON.stringify(filters))
	return { temp, hasFilter }
}

export function getUnitwisePredictedDefects(data) {
	let p = _.chain(data)
		.map((val, i) => {
			return {
				val: val.predicted_defect.organization_specific_code,
				checked: false,
			}
		})
		.uniqBy('val')
		.sortBy([
			function(o) {
				return parseInt(o.val)
			},
		])
		.value()

	return p
}

export function getUnitwiseFeedbackDefects(data) {
	let f = _.chain(data)
		.filter('adjusted_defect')
		.map((val, i) => {
			return {
				val: val.adjusted_defect ? val.adjusted_defect.organization_specific_code : '',
				checked: false,
			}
		})
		.uniqBy('val')
		.sortBy([
			function(o) {
				return parseInt(o.val)
			},
		])
		.value()

	return f
}

export function validateFiles(files) {
	let errors = []
	let validFiles = []
	let nameRe = /[/\\]/
	let fileTypeRe = /\.(.bmp|jpg|jpeg|tiff|tif|png)/
	files.forEach(function(i) {
		let size = i.size / 1024 / 1024
		let hasError = false
		if (nameRe.test(i.name)) {
			hasError = true
			errors.push({
				name: i.name,
				error: 'Invalid file name',
			})
		}
		if (size > 5) {
			hasError = true
			errors.push({
				name: i.name,
				error: 'File size too large',
			})
		}
		if (!fileTypeRe.test(i.name)) {
			hasError = true
			errors.push({
				name: i.name,
				error: 'File format not supported',
			})
		}
		if (!hasError) {
			validFiles.push(i)
		}
	})
	return { errors, validFiles }
}

/**
 * method to download pdf report
 */
export const downloadPredictionReport = async (actualDefectId, predictedDefectId, session) => {
	const URL =
		BACKEND_URL +
		'/api/v1/predictions?actualDefectId=' +
		actualDefectId +
		'&predictedDefectId=' +
		predictedDefectId +
		'&sessionId=' +
		session +
		'&csv=true'
	const link = document.createElement('a')
	link.href = URL
	link.setAttribute('download', 'file.pdf')
	link.setAttribute('target', 'blank')
	document.body.appendChild(link)
	link.click()
}

export const downloadConfusionReport = async (adjusted, model, session) => {
	const URL =
		BACKEND_URL +
		'/api/v1/confusion-matrix?organization_id=1&actualSideBoundaries=' +
		0 +
		'&actualSideBoundaries=' +
		255 +
		'&predictedSideBoundaries=' +
		0 +
		'&predictedSideBoundaries=' +
		255 +
		'&adjusted=' +
		adjusted +
		'&sessionId=' +
		session +
		'&modelNo=' +
		model +
		'&csv=1'
	const link = document.createElement('a')
	link.href = URL
	link.setAttribute('download', 'file.pdf')
	link.setAttribute('target', 'blank')
	document.body.appendChild(link)
	link.click()
}

export function convertTimeZone(utcDate) {
	const date1 = new Date(utcDate)
	const date = new Date(date1.toString())
	const minutes = date.getMinutes()
	const hours = date.getHours()
	const day = date.getDate()
	const month = date.toLocaleString('default', { month: 'short' })
	const year = date
		.getFullYear()
		.toString()
		.substring(2)

	return month + ' ' + day + "'" + year + ' ' + hours + ':' + minutes
}

export const modelName = (models, model) => {
	let name = ''
	models.forEach(function(item) {
		if (item.id === parseInt(model)) {
			name = item.name
		}
	})
	return name
}

export function parseMatrix(matrix) {
	let x = [...matrix]
	let data = []
	let recall = []
	let precision = []
	let predicted = []
	let pTotal = []
	let aTotal = []
	let actual = []
	x.forEach(function(item, j) {
		if (j === 0) {
			actual.push(...item)
			actual.pop()
			actual.pop()
			actual.shift()
		} else {
			let temp = item.splice(Math.max(item.length - 2, 2))
			let i = item.splice(0, 1)
			predicted.push(i[0])
			recall.push(parseFloat(temp[0] * 100).toFixed(2))
			precision.push(parseFloat(temp[1] * 100).toFixed(2))
			pTotal.push(sum(item))
			data.push(item)
			item.forEach(function(value, index) {
				if (typeof aTotal[index] === 'undefined') {
					aTotal[index] = value
				} else {
					aTotal[index] += value
				}
			})
		}
	})
	return { data, recall, precision, predicted, aTotal, pTotal, actual }
}

export function checkAnyBookmarked(inferences, key) {
	let checked = inferences.filter(item => item[key])
	let temp = checked.filter(item => item.is_bookmark)
	let hasAny = false
	let hasAll = false
	if (temp.length) hasAny = true
	if (temp.length === checked.length) hasAll = true
	return { hasAny, hasAll }
}
