import {
	ERROR,
	SAVE_DEFECTS,
	SAVE_MODELS,
	SET_TITLE,
	DJANGO_STATUS_UPDATE,
	AWS_STATUS_UPDATE,
	SET_MODEL,
	SET_STATE_STATE,
	SAVE_SESSION_ID,
	SAVE_PARAMS,
	SAVE_MODEL_DEFECTS,
} from 'constants/action-types'

const initialState = {
	defects: [],
	modelDefects: [],
	defectDict: {},
	models: [],
	error: false,
	stateLoaded: false,
	title: '',
	gpu: '',
	django: '',
	session: '',
	model: '',
	actualDefectId: '',
	predictedDefectId: '',
	bookmarked: '',
}

function common(state = initialState, action) {
	switch (action.type) {
		case SAVE_DEFECTS:
			let defectDict = {}
			action.defects.forEach(function(defect, index) {
				defectDict[defect.id] = {
					...defect,
				}
				this[index] = {
					...defect,
					checked: false,
				}
			}, action.defects)
			return {
				...state,
				defects: action.defects,
				defectDict: defectDict,
			}
		case SAVE_MODEL_DEFECTS:
			return {
				...state,
				modelDefects: [...action.defects],
			}
		case SAVE_MODELS:
			return {
				...state,
				models: action.models,
			}
		case ERROR:
			return {
				...state,
				error: true,
			}
		case SET_STATE_STATE:
			return {
				...state,
				stateLoaded: action.state,
			}
		case SET_TITLE:
			return {
				...state,
				title: action.title,
			}
		case AWS_STATUS_UPDATE:
			return {
				...state,
				gpu: action.state,
			}
		case DJANGO_STATUS_UPDATE:
			return {
				...state,
				django: action.state,
			}
		case SET_MODEL:
			return {
				...state,
			}
		case SAVE_SESSION_ID:
			return {
				...state,
				session: action.sessionId,
			}
		case SAVE_PARAMS:
			return {
				...state,
				...action.params,
			}
		default:
			return state
	}
}

export default common
