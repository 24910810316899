/**
 * actions used by reducers
 */

export const RESET = 'RESET'

export const SET_TITLE = 'SET_TITLE'

export const START_GPU = 'START_GPU'

export const STOP_GPU = 'STOP_GPU'

export const DJANGO_STATUS_UPDATE = 'DJANGO_STATUS_UPDATE'

export const AWS_STATUS_UPDATE = 'AWS_STATUS_UPDATE'

export const SERVER_STATUS_REQ = 'SERVER_STATUS_REQ'

export const START_DJANGO_POLLING = 'START_DJANGO_POLLING'

export const STOP_DJANGO_POLLING = 'STOP_DJANGO_POLLING'

export const START_AWS_POLLING = 'START_AWS_POLLING'

export const STOP_AWS_POLLING = 'STOP_AWS_POLLING'

export const UPLOAD_HISTORY_REQ = 'UPLOAD_HISTORY_REQ'

export const UPLOAD_HISTORY_SAVE = 'UPLOAD_HISTORY_SAVE'

export const UPLOAD_IMAGE_REQ = 'UPLOAD_IMAGE_REQ'

export const PREDICT_DEFECT_REQ = 'PREDICT_DEFECT_REQ'

export const PREDICT_DEFECT_UPDATE = 'PREDICT_DEFECT_UPDATE'

export const SET_DEMO = 'SET_DEMO'

export const SET_MODEL = 'SET_MODEL'

export const SAVE_MODELS = 'SAVE_MODELS'

export const SET_LOADING = 'SET_LOADING'

export const SAVE_SESSION_AND_TYPE = 'SAVE_SESSION_AND_TYPE'

export const LOAD_STATE = 'LOAD_STATE'

export const SET_ERROR = 'SET_ERROR'

export const DEFECTS_REQ = 'DEFECTS_REQ'

export const SAVE_DEFECTS = 'SAVE_DEFECTS'

export const RESET_STATE = 'RESET_STATE'

export const POLL_SESSION_REQ = 'POLL_SESSION_REQ'

export const SAVE_SESSION_DATA = 'SAVE_SESSION_DATA'

export const CHART_HISTORY_REQ = 'CHART_HISTORY_REQ'

export const ERROR = 'ERROR'

export const SAVE_UPLOAD_HISTORY = 'SAVE_UPLOAD_HISTORY'

export const SET_SESSION = 'SET_SESSION'

export const SET_STATE_STATE = 'SET_STATE_STATE'

export const SAVE_SESSION_ID = 'SAVE_SESSION_ID'

export const INFERENCE_DATA_REQ = 'INFERENCE_DATA_REQ'

export const SAVE_INFERENCE_DATA = 'SAVE_INFERENCE_DATA'

export const DEFECT_INFERENCES_REQ = 'DEFECT_INFERENCES_REQ'

export const SAVE_DEFECT_INFERENCES = 'SAVE_DEFECT_INFERENCES'

export const OVERALL_PERFORMANCE_REQ = 'OVERALL_PERFORMANCE_REQ'

export const SAVE_OVERALL_PERFORMANCE = 'SAVE_OVERALL_PERFORMANCE'

export const CONFUSION_MATRIX_REQ = 'CONFUSION_MATRIX_REQ'

export const SAVE_CONFUSION_MATRIX = 'SAVE_CONFUSION_MATRIX'

export const CHECK_INFERENCE = 'CHECK_INFERENCE'

export const UNCHECK_INFERENCE = 'UNCHECK_INFERENCE'

export const SELECT_ALL_INFERENCES = 'SELECT_ALL_INFERENCES'

export const UNSELECT_ALL_INFERENCES = 'UNSELECT_ALL_INFERENCES'

export const ADD_INFERENCE_BOOKMARK = 'ADD_INFERENCE_BOOKMARK'

export const REMOVE_INFERENCE_BOOKMARK = 'REMOVE_INFERENCE_BOOKMARK'

export const PREDICT_DEFECT = 'PREDICT_DEFECT'

export const RESET_PREDICTIONS = 'RESET_PREDICTIONS'

export const RESET_CHECKED_PREDICTIONS = 'RESET_CHECKED_PREDICTIONS'

export const BOOKMARK_CHECKED_INFERENCES = 'BOOKMARK_CHECKED_INFERENCES'

export const SET_SHOW_CHECKED = 'SET_SHOW_CHECKED'

export const SET_INFERENCE_LOADING = 'SET_INFERENCE_LOADING'

export const GIVE_SINGLE_FEEDBACK = 'GIVE_SINGLE_FEEDBACK'

export const REMOVE_INFERENCE = 'REMOVE_INFERENCE'

export const SET_UPLOADING_STATUS = 'SET_UPLOADING_STATUS'

export const UPDATE_UPLOAD_COUNT = 'UPDATE_UPLOAD_COUNT'

export const STOP_INFERENCE_POLLING = 'STOP_INFERENCE_POLLING'

export const SAVE_PARAMS = 'SAVE_PARAMS'

export const MODEL_DEFECTS_REQ = 'MODEL_DEFECTS_REQ'

export const SAVE_MODEL_DEFECTS = 'SAVE_MODEL_DEFECTS'
