import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import queryString from 'query-string'

import { SET_ERROR } from 'constants/action-types'
import { saveParams } from 'actions'

/**
 * component to sroll page to top
 */
const ParseParams = props => {
	const { error } = useSelector(state => ({
		error: state.common.error,
	}))
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		const params = queryString.parse(history.location.search)
		if (params) {
			if (params.bookmarked) {
				params.bookmarked = params.bookmarked === 'true' ? 2 : params.bookmarked === 'false' ? 3 : 1
			}
			dispatch(saveParams(params))
		}
	}, [dispatch, history.location.search])

	useEffect(() => {
		if (error) {
			toast.error(
				"Oops! Something went wrong. Please try again. If it still doesn't work, please reach out to our team",
				{ position: toast.POSITION.TOP_RIGHT }
			)
			dispatch({ type: SET_ERROR, error: false })
		}
	}, [dispatch, error])

	useEffect(() => {
		let windowY = sessionStorage.getItem('windowY')
		if (history.location.pathname === '/prediction/unitwise/analyse') {
			window.scrollTo(0, 0)
		} else {
			if (windowY) {
				window.scrollTo(0, parseFloat(windowY))
				sessionStorage.removeItem('windowY')
			} else {
				window.scrollTo(0, 0)
			}
		}
	}, [history.location.pathname])

	return ''
}

export default withRouter(ParseParams)
