import { SAVE_UPLOAD_HISTORY } from 'constants/action-types'
import filter from 'lodash/filter'
import { RESET } from 'constants/action-types'
const initialState = {
	fileHistory: [],
	folderHistory: [],
	loading: true,
}

function uploadHistory(state = initialState, action) {
	switch (action.type) {
		case SAVE_UPLOAD_HISTORY: {
			let files = filter(action.history, { folder_name: null })
			let folders = filter(action.history, 'folder_name')
			return {
				...state,
				fileHistory: [...state.fileHistory, ...files],
				folderHistory: [...state.folderHistory, ...folders],
				loading: false,
			}
		}
		case RESET:
			return { ...initialState }
		default:
			return state
	}
}
export default uploadHistory
