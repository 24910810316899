import { combineReducers } from 'redux'
import uploadHistory from './uploadHistory'
import common from './common'
import overview from './overview'
import { predictions } from './predictions'
import matrix from './matrix'

export default combineReducers({
	common,
	uploadHistory,
	overview,
	predictions,
	matrix,
})
