import { demo1 } from './demo1'
import { demo3 } from './demo3'
import { demo4 } from './demo4'
import { demo5 } from './demo5'

import { DEMO1, DEMO3, DEMO4, DEMO5 } from '../constants/constants'

let demo = null
if (process.env.REACT_APP_DEMO === DEMO1) {
	demo = demo1
} else if (process.env.REACT_APP_DEMO === DEMO3) {
	demo = demo3
} else if (process.env.REACT_APP_DEMO === DEMO4) {
	demo = demo4
} else if (process.env.REACT_APP_DEMO === DEMO5) {
	demo = demo5
}
export { demo }
