import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'

import { import_annotations_from_json, via_show_img } from 'helpers'

import './VIAImageCanvas.scss'

export default class VIAImageCanvas extends Component {
	handleClick = (e, val) => {
		e.preventDefault()
		this.props.showImages(val)
	}

	// Once loaded image from url, getting started drawing VIA regions
	// Get image/canvas element, Call Helper function for drawing
	handleImgLoad(data, idPrefix, index) {
		const via_img_metadata = import_annotations_from_json(data)
		const viaImage = document.getElementById(idPrefix + 'img' + index)
		if (viaImage) {
			const viaCanvas = document.getElementById(idPrefix + 'canvas' + index)
			via_show_img(via_img_metadata, viaImage, viaCanvas)
		}
	}
	render() {
		return (
			<React.Fragment>
				<div className="via-cover">
					<Image
						id={this.props.idPrefix + 'img' + this.props.index}
						src={this.props.data.filename}
						className="via-image"
						onLoad={() => this.handleImgLoad(this.props.data, this.props.idPrefix, this.props.index)}
					></Image>
					<canvas
						id={this.props.idPrefix + 'canvas' + this.props.index}
						width="1"
						height="1"
						tabIndex="1"
						onClick={e => this.handleClick(e, this.props.index)}
					></canvas>
				</div>
			</React.Fragment>
		)
	}
}
