import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Grid, Dropdown, Loader, Popup } from 'semantic-ui-react'

import arrow_right from '../../../assests/images/arrow_right.png'
import arrow_down from '../../../assests/images/arrow_down.png'

export const VHeader = props => {
	return (
		<Grid.Column className="v-nav">
			<Grid.Row className="title">
				<div className="info-icon">i</div>
				<span>Predicted</span>
				<img src={arrow_down} alt="arrow down"></img>
			</Grid.Row>
			<Grid.Row>
				<Dropdown text="Defect Code" className="defect">
					<Dropdown.Menu>
						{props.defects.map((val, i) => {
							return (
								<Dropdown.Item key={i}>
									{val.organization_defect_code + '  (' + val.name + ')'}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Row>
		</Grid.Column>
	)
}
export const HHeader = props => {
	const options = () => {
		let temp = []
		for (let index = 5; index < props.maxRows; index++) {
			temp.push({ key: index, text: index + 1, value: index + 1 })
		}
		return temp
	}
	return (
		<Grid.Row className="h-nav">
			<Grid.Column className="title" width={6}>
				<div className="info-icon">i</div>
				Actual
				<img src={arrow_right} alt="arrow right"></img>
			</Grid.Column>
			<Grid.Column width={2}>
				<Dropdown text="Defect Code" className="defect">
					<Dropdown.Menu>
						{props.defects.map((val, i) => {
							return (
								<Dropdown.Item key={i}>
									{val.organization_defect_code + '  (' + val.name + ')'}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
			</Grid.Column>
			<Grid.Column width={6} className="show-rows">
				<span>Show Rows</span>
				<Dropdown
					text={`${props.rows}`}
					options={options()}
					onChange={(e, { value }) => props.setRows(value)}
				></Dropdown>
			</Grid.Column>
		</Grid.Row>
	)
}

export const Matrix = props => {
	;(function() {
		$('#h3').scroll(function() {
			$('#h2').prop('scrollTop', this.scrollTop)
			$('#h4').prop('scrollTop', this.scrollTop)
			$('#tV').prop('scrollTop', this.scrollTop)
			$('#h1').prop('scrollLeft', this.scrollLeft)
			$('#h5').prop('scrollLeft', this.scrollLeft)
			$('#tH').prop('scrollLeft', this.scrollLeft)
		})
	})()
	const [maxRows, setMaxRows] = useState(0)
	const [rows, setRows] = useState(0)
	const [currentRow, setCurrentRow] = useState(0)

	useEffect(() => {
		if (props.width) {
			let rows = parseInt((props.width * 16) / 960)
			if (props.data.length < rows) {
				setMaxRows(props.data.length)
			} else {
				setMaxRows(rows)
			}
		}
	}, [props.data.length, props.width])

	useEffect(() => {
		currentRow ? (maxRows < currentRow ? setRows(maxRows) : setRows(currentRow)) : setRows(maxRows)
	}, [currentRow, maxRows])

	const handleRowChange = val => {
		setCurrentRow(val)
	}
	const getClassName = (i, j) => {
		let styleClass = 'dataGrid'
		if (i === j) styleClass += ' diagonal'
		if (i === props.labelCount - 1 && j <= props.labelCount - 1) styleClass += ' hSeparate'
		if (j === props.labelCount - 1 && i <= props.labelCount - 1) styleClass += ' vSeparate'
		return styleClass
	}

	const gridData = () => {
		let temp = []
		props.data.forEach(function(item, i) {
			let x = []
			item.forEach(function(cell, j) {
				x.push(
					<div
						className={getClassName(i, j)}
						key={j}
						onClick={() => props.navigate(props.actual[i], props.predicted[j])}
					>
						{cell}
					</div>
				)
			})
			temp.push(x)
		})
		return temp
	}
	const l = props.data.length

	return (
		<Grid.Column>
			<Grid className="matrix">
				<HHeader {...props} maxRows={maxRows} setRows={handleRowChange} rows={rows}></HHeader>
				{props.loading ? (
					<div className="loader">
						<Loader>Loading</Loader>
					</div>
				) : (
					<div
						className="container"
						style={{
							width: `${(rows + 3) * 41 + 50}px`,
							height: `${(rows + 3) * 41 + 50}px`,
							gridTemplateColumns: `[col-start] 41px [line2] ${rows *
								41}px [line3] 30px [line4] 41px [col-end]`,
							gridTemplateRows: `[row-start] 41px [row2] ${rows *
								41}px [row3] 30px [row4] 41px [row-end]`,
						}}
					>
						<div className="empty1"></div>
						<div
							className="actualData"
							id="h1"
							style={{
								gridTemplateColumns: `repeat(${l}, 41px)`,
							}}
						>
							{props.actual.map((item, i) => {
								return (
									<Popup
										position="top center"
										content={props.defectDict[item].name}
										trigger={
											<div key={i} className="dataCell">
												{props.defectDict[item].organization_defect_code}
											</div>
										}
										key={i}
									></Popup>
								)
							})}
						</div>
						<div className="pEmpty">
							<div className="info-icon">i</div>
							Total Predicted
						</div>
						<div className="empty2">
							<div className="info-icon">i</div>
							Class-wise Precision
						</div>
						<div
							className="predicted"
							id="h2"
							style={{
								gridTemplateRows: ` repeat(${l}, 41px)`,
							}}
						>
							{props.predicted.map((item, i) => {
								return (
									<Popup
										position="top center"
										content={props.defectDict[item] ? props.defectDict[item].name : ''}
										trigger={
											<div className="dataCell" key={i}>
												{props.defectDict[item]
													? props.defectDict[item].organization_defect_code
													: ''}
											</div>
										}
										key={i}
									></Popup>
								)
							})}
						</div>
						<div
							className="data-container"
							id="h3"
							style={{
								gridTemplateColumns: `repeat(${l}, 41px)`,
								gridTemplateRows: ` repeat(${l}, 41px)`,
								width: `${rows * 41}px`,
								height: `${rows * 41}px`,
							}}
						>
							{gridData()}
						</div>
						<div
							className="pTotal"
							id="tV"
							style={{
								gridTemplateRows: ` repeat(${l}, 41px)`,
							}}
						>
							{props.pTotal.map((item, i) => {
								return (
									<div
										className="pTotalCell clickable"
										key={i}
										onClick={() => props.navigate(props.actual[i], '')}
									>
										{item}
									</div>
								)
							})}
						</div>
						<div
							className="precision"
							id="h4"
							style={{
								gridTemplateRows: ` repeat(${l}, 41px)`,
							}}
						>
							{props.precision.map((item, i) => {
								return (
									<div className="dataCell" key={i}>
										{item}
									</div>
								)
							})}
						</div>
						<div className="aEmpty">
							<div className="info-icon">i</div>
							Total Actual
						</div>
						<div
							className="aTotal"
							id="tH"
							style={{
								gridTemplateColumns: `repeat(${l}, 41px)`,
							}}
						>
							{props.aTotal.map((item, i) => {
								return (
									<div
										className="aTotalCell clickable"
										key={i}
										onClick={() => props.navigate('', props.predicted[i])}
									>
										{item}
									</div>
								)
							})}
						</div>
						<div className="aEmpty2"></div>
						<div className="aEmpty3"></div>
						<div className="empty3">
							<div className="info-icon">i</div>
							Class-wise Recall
						</div>
						<div
							className="recall"
							id="h5"
							style={{
								gridTemplateColumns: `repeat(${l}, 41px)`,
							}}
						>
							{props.recall.map((item, i) => {
								return (
									<div className="dataCell" key={i}>
										{item}
									</div>
								)
							})}
						</div>
						<div className="empty4"></div>
					</div>
				)}
			</Grid>
		</Grid.Column>
	)
}
