import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { setTitle } from 'actions/index'

import glossary from 'assests/images/glossary.png'

/**
 * component to show glossary
 */
class Glossary extends Component {
	componentDidMount() {
		this.props.setTitle({ title: 'Glossary' })
	}
	render() {
		return <Image src={glossary}></Image>
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setTitle: title => dispatch(setTitle(title)),
	}
}
export default connect(
	null,
	mapDispatchToProps
)(Glossary)
