import React, { Component } from 'react'
import {
	Modal,
	Menu,
	Segment,
	Image,
	Input,
	Radio,
	Checkbox,
	Button,
	Dropdown,
	Grid,
	Label,
	Icon,
	Message,
} from 'semantic-ui-react'

import { VIA_ATTRIBUTE_TYPE, VIA_ATTRIBUTE_DROPDOWN_TYPES } from 'constants/constants'

import arrow_close from 'assests/images/arrow_close.png'

import './VIAConfigure.scss'

class VIAConfigure extends Component {
	constructor(props) {
		super(props)

		this.state = {
			_via_attributes: {
				region: {},
				file: {},
			},
			_via_attribute_being_updated: 'region',
			attribute_ids: [],
			attr_id: '',
			attribute_name: '',
			attribute_description: '',
			attribute_default_value: '',
			attr_input_type: VIA_ATTRIBUTE_TYPE.TEXT,
			_via_current_attribute_id: '',
			attribute_new_option_id: '',
			showMessage: false,
			message: '',
			message_color: 'red',
		}
	}

	componentDidMount() {
		// Should fetch default attributes data from back-end server.
		// As of now, fetching from local storage
		const viaDefaultAttributes = localStorage.getItem('viaDefaultAttributes')
		if (viaDefaultAttributes) {
			this.setState(
				{
					_via_attributes: JSON.parse(viaDefaultAttributes),
				},
				() => {
					this.update_attributes_update_panel()
				}
			)
		}
	}

	// Handle to switch Region and File attribute
	handleItemClick = (e, { name }) => {
		const { _via_attributes } = this.state
		const attr_list = Object.keys(_via_attributes[name])
		let _via_current_attribute_id
		if (attr_list.length) {
			_via_current_attribute_id = attr_list[0]
		} else {
			_via_current_attribute_id = ''
		}
		this.setState(
			{
				_via_attribute_being_updated: name,
				_via_current_attribute_id,
			},
			() => {
				this.update_attributes_update_panel()
			}
		)
	}

	// Process general input value
	handleInput = e => {
		const input_name = e.target.name
		const input_value = e.target.value
		if (this.state[e.target.name] !== e.target.value) {
			this.setState(
				{
					[input_name]: input_value,
				},
				() => {
					if (input_name !== 'attr_id' || input_name !== 'attribute_new_option_id') {
						this.attribute_property_on_update(input_name, input_value)
					}
				}
			)
		}
	}

	// Handle Attribute Type Change (text, dropdown, radio, checkbox)
	handleAttributeTypeChange = (e, { value }) => {
		this.attribute_property_on_update('attribute_type', value)
	}

	// Update attribute propertis upon input id, value
	attribute_property_on_update = (input_id, attr_value) => {
		let { _via_attributes } = this.state
		const { _via_attribute_being_updated, _via_current_attribute_id } = this.state

		const attr_id = _via_current_attribute_id
		const attr_type = _via_attribute_being_updated

		switch (input_id) {
			// Case of editing attribute name
			case 'attribute_name':
				if (attr_value !== attr_id) {
					Object.defineProperty(
						_via_attributes[attr_type],
						attr_value,
						Object.getOwnPropertyDescriptor(_via_attributes[attr_type], attr_id)
					)

					delete _via_attributes[attr_type][attr_id]
					this.setState(
						{
							_via_current_attribute_id: attr_value,
							_via_attributes,
						},
						() => {
							this.update_attributes_update_panel()
						}
					)
				}
				break
			// Case of editing attribute description
			case 'attribute_description':
				_via_attributes[attr_type][attr_id].description = attr_value
				this.setState(
					{
						_via_attributes,
					},
					() => {
						this.update_attributes_update_panel()
					}
				)
				break
			// Case of editing attribute default value
			case 'attribute_default_value':
				_via_attributes[attr_type][attr_id].default_value = attr_value
				this.setState(
					{
						_via_attributes,
					},
					() => {
						this.update_attributes_update_panel()
					}
				)
				break
			// Case of editing attribute type (text, dropdown, radio, checkbox)
			case 'attribute_type':
				_via_attributes[attr_type][attr_id].type = attr_value
				if (attr_value === VIA_ATTRIBUTE_TYPE.TEXT) {
					_via_attributes[attr_type][attr_id].default_value = ''
					delete _via_attributes[attr_type][attr_id].options
					delete _via_attributes[attr_type][attr_id].default_options
					this.setState({
						attr_input_type: VIA_ATTRIBUTE_TYPE.TEXT,
						_via_attributes,
					})
				} else {
					// preserve existing options
					if (!_via_attributes[attr_type][attr_id].hasOwnProperty('options')) {
						_via_attributes[attr_type][attr_id].options = {}
						_via_attributes[attr_type][attr_id].default_options = {}
					}

					if (_via_attributes[attr_type][attr_id].hasOwnProperty('default_value')) {
						delete _via_attributes[attr_type][attr_id].default_value
					}

					// collect existing attribute values and add them as options
					// const attr_values = attribute_get_unique_values(attr_type, attr_id)
					// let i
					// for ( i = 0; i < attr_values.length; ++i ) {
					//   const attr_val = attr_values[i]
					//   if ( attr_val !== '' ) {
					//     _via_attributes[attr_type][attr_id].options[attr_val] = attr_val
					//   }
					// }
					this.setState({
						_via_attributes,
					})
				}
				this.show_attribute_properties()
				break
			default:
				console.log('Attribute ' + input_id + ' is unavailable')
		}
	}

	// Handle to success/error message
	handleMessage = (message, level) => {
		this.setState(
			{
				message,
				message_color: level === 'error' ? 'red' : 'blue',
				showMessage: true,
			},
			() => {
				setTimeout(
					function() {
						this.setState({ showMessage: false })
					}.bind(this),
					2000
				)
			}
		)
	}

	// Handle to change attribute from dropdown
	handleAttributeChange = (e, { value }) => {
		this.setState(
			{
				_via_current_attribute_id: value,
			},
			() => {
				this.update_attributes_update_panel()
			}
		)
	}

	// Add new attribute when user added new from input
	add_new_attribute_from_user_input = e => {
		const { attr_id, _via_attribute_being_updated } = this.state
		if (!attr_id) {
			this.handleMessage('Enter the name of attribute that you wish to add.', 'error')
			return
		}
		if (this.attribute_property_id_exists(attr_id)) {
			this.handleMessage(
				'The ' + _via_attribute_being_updated + ' attribute [' + attr_id + '] already exists.',
				'error'
			)
		} else {
			this.setState(
				{
					_via_current_attribute_id: attr_id,
					attr_id: '',
				},
				async () => {
					await this.add_new_attribute(attr_id)
					await this.update_attributes_update_panel()
					await this.handleMessage(
						'Added ' + _via_attribute_being_updated + ' attribute [' + attr_id + '].',
						'success'
					)
				}
			)
		}
	}

	// Add new attribute value to attributes object
	add_new_attribute = attribute_id => {
		const { _via_attribute_being_updated } = this.state
		let { _via_attributes } = this.state
		_via_attributes[_via_attribute_being_updated][attribute_id] = {}
		_via_attributes[_via_attribute_being_updated][attribute_id].type = VIA_ATTRIBUTE_TYPE.TEXT
		_via_attributes[_via_attribute_being_updated][attribute_id].description = ''
		_via_attributes[_via_attribute_being_updated][attribute_id].default_value = ''
		this.setState({
			_via_attributes,
		})
	}

	// Update attributes name lists when adding/editing/deleting attribute
	// Update attribute properties values upong changing attribute
	update_attributes_update_panel = async () => {
		await this.update_attributes_name_list()
		await this.show_attribute_properties()
	}

	// Update attributes name lists when adding/editing/deleting attribute
	update_attributes_name_list = () => {
		const { _via_attributes, _via_attribute_being_updated } = this.state
		const attribute_ids = []
		let attr_name
		for (attr_name in _via_attributes[_via_attribute_being_updated]) {
			attribute_ids.push({
				value: attr_name,
				text: attr_name,
			})
		}
		this.setState({
			attribute_ids,
		})
	}

	// Update attribute properties values upong changing attribute
	show_attribute_properties = () => {
		const { attribute_ids, _via_attributes, _via_attribute_being_updated } = this.state
		let { _via_current_attribute_id } = this.state
		if (attribute_ids.length === 0) {
			return
		}
		if (!_via_current_attribute_id) {
			_via_current_attribute_id = attribute_ids[0].value
		}

		const attr_id = _via_current_attribute_id
		const attr_type = _via_attribute_being_updated
		const attr_input_type = _via_attributes[attr_type][attr_id].type
		const attr_desc = _via_attributes[attr_type][attr_id].description

		this.setState({
			_via_current_attribute_id,
			attribute_name: attr_id,
			attribute_description: attr_desc,
		})
		if (attr_input_type === VIA_ATTRIBUTE_TYPE.TEXT) {
			const attr_default_value = _via_attributes[attr_type][attr_id].default_value
			this.setState({
				attr_input_type: attr_input_type,
				attribute_default_value: attr_default_value,
			})
		} else {
			this.setState({
				attr_input_type: attr_input_type,
			})
		}
	}

	// Update attribute options values upong changing attribute property (text, dropdown, radio, checkbox)
	show_attribute_options = () => {
		const { attribute_ids, _via_current_attribute_id, _via_attributes, _via_attribute_being_updated } = this.state
		if (attribute_ids.length === 0 || !_via_attributes[_via_attribute_being_updated][_via_current_attribute_id]) {
			return ''
		}

		const attr_id = _via_current_attribute_id
		const attr_type = _via_attributes[_via_attribute_being_updated][attr_id].type
		let dynamic_options_components = ''
		// populate additional options based on attribute type
		switch (attr_type) {
			case VIA_ATTRIBUTE_TYPE.TEXT:
				// text does not have any additional properties
				break
			case VIA_ATTRIBUTE_TYPE.CHECKBOX: // handled by next case
			case VIA_ATTRIBUTE_TYPE.DROPDOWN: // handled by next case
			case VIA_ATTRIBUTE_TYPE.RADIO:
				const options = _via_attributes[_via_attribute_being_updated][attr_id].options
				dynamic_options_components = Object.keys(options).map(option_id => {
					const option_desc = options[option_id]
					const option_default =
						_via_attributes[_via_attribute_being_updated][attr_id].default_options[option_id]
					return this.attribute_property_add_option(
						attr_id,
						option_id,
						option_desc,
						option_default,
						attr_type
					)
				})
				break
			default:
				console.log('Attribute type ' + attr_type + ' is unavailable')
		}

		return dynamic_options_components
	}

	// Add options to (dropdown, radio, checkbox) attribute property dynamically
	attribute_property_add_option = (attr_id, option_id, option_desc, option_default, attribute_type) => {
		return (
			<Grid.Row key={option_id}>
				<Grid.Column width={6}>
					<Input
						id={'_via_attribute_option_id_' + option_id}
						value={option_id}
						name="attribute-option-id"
						onChange={this.attribute_property_on_option_update}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Input
						id={'_via_attribute_option_description_' + option_id}
						value={option_desc}
						name="attribute-option-desc"
						onChange={this.attribute_property_on_option_update}
					/>
				</Grid.Column>
				<Grid.Column width={2}>
					{attribute_type === VIA_ATTRIBUTE_TYPE.RADIO || attribute_type === VIA_ATTRIBUTE_TYPE.DROPDOWN ? (
						<Radio
							id={'_via_attribute_option_default_' + option_id}
							name={attr_id}
							checked={option_default === true}
							onChange={this.attribute_property_on_option_update}
						/>
					) : (
						<Checkbox
							id={'_via_attribute_option_default_' + option_id}
							checked={option_default}
							onClick={this.attribute_property_on_option_update}
						/>
					)}
				</Grid.Column>
			</Grid.Row>
		)
	}

	// Update attribute property options upon changing option value
	// Case of changing option id/description, default options
	attribute_property_on_option_update = (e, { checked }) => {
		const input_id = e.target.id
		const input_value = e.target.value

		const { _via_attributes, _via_current_attribute_id, _via_attribute_being_updated } = this.state
		const attr_id = _via_current_attribute_id
		if (input_id.startsWith('_via_attribute_option_id_')) {
			const old_key = input_id.substr('_via_attribute_option_id_'.length)
			const new_key = input_value
			if (old_key !== new_key) {
				const option_id_test = this.attribute_property_option_id_is_valid(attr_id, new_key)
				if (option_id_test.is_valid) {
					Object.defineProperty(
						_via_attributes[_via_attribute_being_updated][attr_id].options,
						new_key,
						Object.getOwnPropertyDescriptor(
							_via_attributes[_via_attribute_being_updated][attr_id].options,
							old_key
						)
					)

					delete _via_attributes[_via_attribute_being_updated][attr_id].options[old_key]

					this.setState({
						_via_attributes,
					})
				} else {
					this.handleMessage(option_id_test.message, 'error')
				}
			}
		}
		if (input_id.startsWith('_via_attribute_option_description_')) {
			const key = input_id.substr('_via_attribute_option_description_'.length)
			const old_value = _via_attributes[_via_attribute_being_updated][attr_id].options[key]
			const new_value = input_value
			if (new_value !== old_value) {
				_via_attributes[_via_attribute_being_updated][attr_id].options[key] = new_value
				this.setState({
					_via_attributes,
				})
			}
		}
		if (input_id.startsWith('_via_attribute_option_default_')) {
			const new_default_option_id = input_id.substr('_via_attribute_option_default_'.length)
			const old_default_option_id_list = Object.keys(
				_via_attributes[_via_attribute_being_updated][attr_id].default_options
			)

			if (!old_default_option_id_list.length) {
				// default set for the first time
				_via_attributes[_via_attribute_being_updated][attr_id].default_options[new_default_option_id] = true
			} else {
				switch (_via_attributes[_via_attribute_being_updated][attr_id].type) {
					case VIA_ATTRIBUTE_TYPE.DROPDOWN: // fallback
					case VIA_ATTRIBUTE_TYPE.RADIO: // fallback
						// to ensure that only one radio button is selected at a time
						_via_attributes[_via_attribute_being_updated][attr_id].default_options = {}
						_via_attributes[_via_attribute_being_updated][attr_id].default_options[
							new_default_option_id
						] = true
						break
					case VIA_ATTRIBUTE_TYPE.CHECKBOX:
						_via_attributes[_via_attribute_being_updated][attr_id].default_options[
							new_default_option_id
						] = checked
						break
					default:
						break
				}
			}

			this.setState({
				_via_attributes,
			})
		}
	}

	// Validation for attribute property option
	attribute_property_option_id_is_valid = (attr_id, new_option_id) => {
		const { _via_attributes, _via_attribute_being_updated } = this.state
		let option_id
		for (option_id in _via_attributes[_via_attribute_being_updated][attr_id].options) {
			if (option_id === new_option_id) {
				return { is_valid: false, message: 'Option id [' + attr_id + '] already exists' }
			}
		}

		if (new_option_id.includes('__')) {
			// reserved separator for attribute-id, row-id, option-id
			return { is_valid: false, message: 'Option id cannot contain two consecutive underscores' }
		}

		return { is_valid: true }
	}

	// Add new attribute property option from user input 'Add new option id'
	attribute_property_on_option_add = e => {
		const {
			attribute_new_option_id,
			_via_current_attribute_id,
			_via_attributes,
			_via_attribute_being_updated,
		} = this.state
		if (!attribute_new_option_id) {
			return
		}

		const attr_id = _via_current_attribute_id
		const option_id = attribute_new_option_id
		const option_id_test = this.attribute_property_option_id_is_valid(attr_id, option_id)
		if (option_id_test.is_valid) {
			_via_attributes[_via_attribute_being_updated][attr_id].options[option_id] = ''
			this.setState({
				_via_attributes,
				attribute_new_option_id: '',
			})
		} else {
			this.handleMessage(option_id_test.message, 'error')
		}
	}

	// Delete the existing attribute
	delete_existing_attribute_with_confirm = e => {
		const { attr_id } = this.state
		if (!attr_id) {
			this.handleMessage('Enter the name of attribute that you wish to delete.', 'error')
			return
		}
		if (this.attribute_property_id_exists(attr_id)) {
			this.delete_existing_attribute()
		} else {
			this.handleMessage('Attribute [' + attr_id + '] does not exist!', 'error')
		}
	}

	delete_existing_attribute = () => {
		const { _via_attributes, _via_attribute_being_updated, attr_id } = this.state
		let _via_current_attribute_id
		if (_via_attributes[_via_attribute_being_updated].hasOwnProperty(attr_id)) {
			const attr_id_list = Object.keys(_via_attributes[_via_attribute_being_updated])
			if (attr_id_list.length === 1) {
				_via_current_attribute_id = ''
			} else {
				const current_index = attr_id_list.indexOf(attr_id)
				let next_index = current_index + 1
				if (next_index === attr_id_list.length) {
					next_index = current_index - 1
				}
				_via_current_attribute_id = attr_id_list[next_index]
			}
			delete _via_attributes[_via_attribute_being_updated][attr_id]
			this.setState(
				{
					_via_current_attribute_id,
					attr_id: '',
				},
				async () => {
					await this.update_attributes_update_panel()
					await this.handleMessage(
						'Deleted ' + _via_attribute_being_updated + ' attribute [' + attr_id + ']',
						'success'
					)
				}
			)
		}
	}

	// Validation for attribute property id when trying to add/delete it
	attribute_property_id_exists = name => {
		const { _via_attributes, _via_attribute_being_updated } = this.state
		let attr_name
		for (attr_name in _via_attributes[_via_attribute_being_updated]) {
			if (attr_name === name) {
				return true
			}
		}
		return false
	}

	// Handle to close VIA Configuration Dialog
	handleClose = e => {
		// Currently, Save VIA Default Configuration to local storage.
		// It should be saved to back-end database later.
		localStorage.setItem('viaDefaultAttributes', JSON.stringify(this.state._via_attributes))
		this.props.handleClose()
	}

	render() {
		const {
			showMessage,
			message,
			message_color,
			_via_attribute_being_updated,
			_via_current_attribute_id,
			attribute_ids,
			attr_id,
			attribute_name,
			attribute_description,
			attribute_default_value,
			attr_input_type,
			attribute_new_option_id,
		} = this.state

		return (
			<Modal open={this.props.open} dimmer="inverted" className="modal-viaconfigure">
				<Modal.Content scrolling className="modal-viaconfigure-content">
					<div className="configuration-header">
						<Image src={arrow_close} className="close" onClick={this.handleClose}></Image>
						<div className="title">Attributes</div>
					</div>
					<div>
						<Menu pointing secondary>
							<Menu.Item
								name="region"
								active={_via_attribute_being_updated === 'region'}
								onClick={this.handleItemClick}
							/>
							<Menu.Item
								name="file"
								active={_via_attribute_being_updated === 'file'}
								onClick={this.handleItemClick}
							/>
						</Menu>

						{/* Add new attribute or delete existing one */}
						<div className="add-delete-attribute">
							{showMessage && <Message color={message_color}>{message}</Message>}
							<Input
								name="attr_id"
								icon="chess pawn"
								iconPosition="left"
								placeholder="Attribute Name..."
								onChange={this.handleInput}
								value={attr_id}
							/>
							<Button
								primary
								icon="plus"
								className="add-attribute"
								onClick={this.add_new_attribute_from_user_input}
							></Button>
							<Button
								primary
								icon="minus"
								className="delete-attribute"
								onClick={this.delete_existing_attribute_with_confirm}
							></Button>
						</div>

						{/* Select attribute */}
						<Dropdown
							className="dropdown-attribute"
							placeholder="Select Attribute"
							fluid
							selection
							options={this.state.attribute_ids}
							onChange={this.handleAttributeChange}
							value={_via_current_attribute_id}
						/>

						{/* Attribute properties & options */}
						{attribute_ids.length > 0 && (
							<Grid columns={2} divided className="grid-bottom">
								<Grid.Row stretched>
									<Grid.Column>
										<Label>
											<Icon name="cog" />
											Properties
										</Label>
										<Segment raised>
											<Grid>
												<Grid.Row>
													<Grid.Column width={5}>
														<span>Name</span>
													</Grid.Column>
													<Grid.Column width={10}>
														<Input
															name="attribute_name"
															value={attribute_name}
															onChange={this.handleInput}
														/>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row>
													<Grid.Column width={5}>
														<span>Desc.</span>
													</Grid.Column>
													<Grid.Column width={10}>
														<Input
															name="attribute_description"
															value={attribute_description}
															onChange={this.handleInput}
														/>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row>
													<Grid.Column width={5}>
														<span>Type</span>
													</Grid.Column>
													<Grid.Column width={10}>
														<Dropdown
															className="dropdown-types"
															fluid
															selection
															options={VIA_ATTRIBUTE_DROPDOWN_TYPES}
															onChange={this.handleAttributeTypeChange}
															value={attr_input_type}
														/>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Segment>
									</Grid.Column>

									<Grid.Column>
										<Label>
											<Icon name="cogs" />
											Options
										</Label>
										<Segment raised>
											{attr_input_type === VIA_ATTRIBUTE_TYPE.TEXT ? (
												<Grid>
													<Grid.Row>
														<Grid.Column width={5}>
															<span>Def.</span>
														</Grid.Column>
														<Grid.Column width={10}>
															<Input
																name="attribute_default_value"
																value={attribute_default_value}
																onChange={this.handleInput}
															/>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											) : (
												<div>
													<Grid>
														<Grid.Row>
															<Grid.Column width={6}>
																<span title="When selected, this is the value that appears in exported annotations">
																	id
																</span>
															</Grid.Column>
															<Grid.Column width={8}>
																<span title="This is the text shown as an option to the annotator">
																	description
																</span>
															</Grid.Column>
															<Grid.Column width={2}>
																<span title="The default value of this attribute">
																	def.
																</span>
															</Grid.Column>
														</Grid.Row>
													</Grid>
													<Grid celled="internally">{this.show_attribute_options()}</Grid>
													<div className="new_option_id_entry">
														<Grid>
															<Grid.Row>
																<Grid.Column width={8}>
																	<Input
																		id="_via_attribute_new_option_id"
																		name="attribute_new_option_id"
																		placeholder="Add new option id"
																		onChange={this.handleInput}
																		value={attribute_new_option_id}
																	/>
																</Grid.Column>
																<Grid.Column width={8}></Grid.Column>
															</Grid.Row>
														</Grid>
														<Button
															primary
															icon="plus"
															className="add-attribute-property-option"
															onClick={this.attribute_property_on_option_add}
														></Button>
													</div>
												</div>
											)}
										</Segment>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						)}
					</div>
				</Modal.Content>
			</Modal>
		)
	}
}

export default VIAConfigure
