import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setTitle } from 'actions'
import { Grid, Button, Dropdown } from 'semantic-ui-react'

const TrendCharts = props => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setTitle({ title: 'Performance Monitoring of the Platform' }))
	}, [dispatch])
	return (
		<div className="trend-charts">
			<Grid className="trendc">
				<Grid.Row className="trendc-row">Trend Charts</Grid.Row>
				<Grid.Row className="trendc-row2">
					<Button>Add a Chart </Button>
				</Grid.Row>
				<Grid.Row columns={4} className="trendc-row3">
					<Grid.Column>
						<Dropdown
							multiple
							item
							icon={false}
							trigger={
								<Button
									content="Start and End date"
									className="drop-btn"
									icon="caret down"
									labelPosition="right"
								></Button>
							}
						>
							<Dropdown.Menu>
								<Dropdown.Item text="New" />
							</Dropdown.Menu>
						</Dropdown>
					</Grid.Column>
					<Grid.Column>
						<Dropdown
							multiple
							item
							icon={false}
							trigger={
								<Button
									content="Accuracy"
									className="drop-btn"
									icon="caret down"
									labelPosition="right"
								></Button>
							}
						>
							<Dropdown.Menu>
								<Dropdown.Item text="New" />
							</Dropdown.Menu>
						</Dropdown>
					</Grid.Column>
					<Grid.Column>
						<Dropdown
							multiple
							item
							icon={false}
							trigger={
								<Button
									content="Overall Classes"
									className="drop-btn"
									icon="caret down"
									labelPosition="right"
								></Button>
							}
						>
							<Dropdown.Menu>
								<Dropdown.Item text="New" />
							</Dropdown.Menu>
						</Dropdown>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="charts-div">
				<Grid className="chart"></Grid>
				<Grid className="chart2"></Grid>
			</div>
		</div>
	)
}

export default TrendCharts
