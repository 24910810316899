import React from 'react'
import { Grid, Button, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'

/**
 * data acquistion sub component for file and folder upload
 */
const Box = props => (
	<Grid columns={3} className="box">
		<div className="mask">
			<Icon name={props.icon} size="big"></Icon>
		</div>
		<div className="content">
			<div className="box-header">{props.content_header}</div>
			<div className="box-data">{props.content_data}</div>
		</div>
		<Button className="box-button ss-big" onClick={props.handleclick}>
			{props.button}
		</Button>
	</Grid>
)

Box.propTypes = {
	icon: PropTypes.string,
	content_header: PropTypes.string,
	content_data: PropTypes.string,
	handleclick: PropTypes.func,
}

export default Box
