import React from 'react'
import { Table, Icon, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { Folder } from 'constants/constants'
import { convertTimeZone } from 'helpers'

/**
 * component to display uploaded folders history
 */
export const FolderHistory = props => {
	return (
		<Table className="file-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>S.No.</Table.HeaderCell>
					<Table.HeaderCell>UPLOADED ON</Table.HeaderCell>
					<Table.HeaderCell>
						FOLDER NAMES
						<Icon name="caret down" size="small" fitted></Icon>
					</Table.HeaderCell>
					<Table.HeaderCell>
						TOTAL NUMBER OF IMAGES
						<Icon name="caret down" size="small" fitted></Icon>
					</Table.HeaderCell>
					<Table.HeaderCell>ACTIONS</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{props.uploadHistory.map((value, index) => {
					return (
						<Table.Row className="unit-row" key={index}>
							<Table.Cell>{index + 1}</Table.Cell>
							<Table.Cell>{convertTimeZone(value.created_at)}</Table.Cell>
							<Table.Cell>{value.folder_name}</Table.Cell>
							<Table.Cell>Batch of {value.file_count} images</Table.Cell>
							<Table.Cell>
								<Button className="ss-main results" onClick={e => props.seeResults(value.id, Folder)}>
									See Results
								</Button>
							</Table.Cell>
						</Table.Row>
					)
				})}
			</Table.Body>
		</Table>
	)
}

FolderHistory.propTypes = {
	uploadHistory: PropTypes.array,
	seeResults: PropTypes.func,
}

export default FolderHistory
