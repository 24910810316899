import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Grid, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import Box from 'components/Box'
import { setTitle, uploadImageReq, getUploadHistory, reset, setUploadingStatus, stopInferencePolling, saveSessionId } from 'actions'
import { FileHistory, FolderHistory, DbHistory } from 'components/History'
import { validateFiles } from 'helpers'
import Api from 'Api'

let REQUESTS = 6
/**
 * file upload and history component
 */
const DataAcquisition = () => {
	const { model, stateLoaded, fileHistory, folderHistory, loading } = useSelector(
		state => ({
			model: state.common.model,
			stateLoaded: state.common.stateLoaded,
			fileHistory: state.uploadHistory.fileHistory,
			folderHistory: state.uploadHistory.folderHistory,
			loading: state.uploadHistory.loading,
		}),
		shallowEqual
	)

	const history = useHistory()
	const dispatch = useDispatch()
	
	const inputOpenFileRef = useRef(null)
	const inputOpenFolderRef = useRef(null)

	useEffect(() => {
		dispatch(setTitle('Select your data acquisition channel'))
	}, [dispatch])

	useEffect(() => {
		if (stateLoaded && model) {
			dispatch(getUploadHistory(model))
		}
	}, [dispatch, model, stateLoaded])

	// open file browser top upload files on button click
	/**
	 * show file browser on upload button click
	 * @param {event object} e : passed by javascript
	 */
	const showOpenFileDlg = e => {
		inputOpenFileRef.current.click()
	}

	/**
	 * open file browser to upload folders
	 * @param {event object} e : passed by javascript
	 */
	const showOpenFolderDlg = e => {
		inputOpenFolderRef.current.click()
	}

	/**
	 * api call to process uploaded image files
	 * @param {event object} e : passed by javascript
	 */
	async function handleFileUpload(e) {
		dispatch(stopInferencePolling())
		const files = Array.from(e.target.files)
		const { errors, validFiles } = validateFiles(files)
		// dispatch(reset({ total: validFiles.length, stateLoaded: true }))
		if (errors.length) {
			errors.forEach(function(i) {
				toast.error(i.error + 'for ' + i.name, {
					position: toast.POSITION.TOP_RIGHT,
				})
			})
		}
		let result = await Api.getSessionId(model).then(response => response)
		const session = result.data.response.id
		dispatch(reset())
		dispatch(saveSessionId(session))
		// dispatch({ type: SAVE_SESSION_AND_TYPE, file_type: 1, uploading: true })
		dispatch(setUploadingStatus(true, validFiles.length, true))
		let RANGE = Math.ceil(validFiles.length / REQUESTS)
		RANGE = validFiles.length < 30 ? 5 : RANGE
		for (let i = 0; i < REQUESTS; i++) {
			let x = validFiles.slice(i * RANGE, (i + 1) * RANGE)
			if (x.length) {
				dispatch(
					uploadImageReq({
						files: x,
						session: session,
					})
				)
			}
		}
		history.push(`/prediction/overview?model=${model}&session=${session}`)
	}

	/**
	 * api call to process uploaded image folder
	 * @param {event object} e : passed by javascript
	 */
	async function handleFolderUpload(e) {
		dispatch(stopInferencePolling())
		const files = Array.from(e.target.files)
		const { errors, validFiles } = validateFiles(files)
		// dispatch(reset({ total: validFiles.length, stateLoaded: true }))
		if (errors.length) {
			errors.forEach(function(i) {
				toast.error(i.error + 'for ' + i.name, {
					position: toast.POSITION.TOP_RIGHT,
				})
			})
		}
		const folder_name = files[0].webkitRelativePath.split('/')[0]
		let result = await Api.getSessionId(model).then(response => response)
		const formData = new FormData()
		formData.append('folder_name', folder_name)
		let response = await Api.folderUpload(formData)
			.then(_ => _)
			.catch(e => e)

		const session = result.data.response.id
		// dispatch({ type: SAVE_SESSION_AND_TYPE, file_type: 2, uploading: true })
		dispatch(reset())
		dispatch(saveSessionId(session))
		dispatch(setUploadingStatus(true, validFiles.length, true))
		let RANGE = Math.ceil(validFiles.length / REQUESTS)
		RANGE = validFiles.length < 30 ? 5 : RANGE
		for (let i = 0; i < REQUESTS; i++) {
			let x = validFiles.slice(i * RANGE, (i + 1) * RANGE)
			if (x.length) {
				dispatch(
					uploadImageReq({
						files: x,
						session: session,
						id: response.data.response.id,
					})
				)
			}
		}
		history.push(`/prediction/overview?model=${model}&session=${session}`)
	}

	/**
	 * fetch data for passed session from backend
	 * @param {uuid} session : session id
	 * @param {int} type : file or folder
	 */
	const seeResults = (session, type) => {
		// dispatch(reset({ total: 0, stateLoaded: false }))
		dispatch(stopInferencePolling())
		dispatch(reset())
		// dispatch({ type: SAVE_SESSION_AND_TYPE, session: session, file_type: type, uploading: false })
		history.push(`/prediction/overview?model=${model}&session=${session}`)
	}

	return (
		<div className="page DataAcquisition">
			<Box
				icon="database"
				content_header="Connect to your Database"
				content_data="Support for Oracle, PROMIS, SQL"
				button="Connect"
			></Box>
			<Box
				icon="image"
				content_header="Upload image files"
				content_data="Support for image files in .tif, .bmp and .png format"
				button="Browse"
				handleclick={e => showOpenFileDlg()}
			></Box>
			<Box
				icon="folder open"
				content_header="Upload image folders"
				content_data="Support for image folders with images in .tif, .bmp and .png format"
				button="Browse"
				handleclick={e => showOpenFolderDlg()}
			></Box>
			<Grid columns={1} className="history">
				<Grid.Row>
					<p>Connected Database paths</p>
				</Grid.Row>
				<DbHistory loading={fileHistory ? false : true}></DbHistory>
				<Grid.Row>
					<p>Uploaded Image file batches</p>
				</Grid.Row>
				{loading ? (
					<React.Fragment>
						<Grid.Row>
							<Loader active inline="centered" size="medium">
								Loading
							</Loader>
						</Grid.Row>
					</React.Fragment>
				) : (
					<React.Fragment>
						<FileHistory uploadHistory={fileHistory} seeResults={seeResults}></FileHistory>
						<Grid.Row>
							<p>Uploaded Image folder batches</p>
						</Grid.Row>
						<FolderHistory uploadHistory={folderHistory} seeResults={seeResults}></FolderHistory>
					</React.Fragment>
				)}
			</Grid>

			<input
				ref={inputOpenFileRef}
				type="file"
				style={{ display: 'none' }}
				multiple
				onChange={e => handleFileUpload(e)}
				accept="image/png,image/tif,image/bmp"
			/>
			<input
				ref={inputOpenFolderRef}
				directory=""
				webkitdirectory=""
				type="file"
				style={{ display: 'none' }}
				onChange={e => handleFolderUpload(e)}
				accept="image/png,image/tif,image/bmp"
			/>
		</div>
	)
}

export default DataAcquisition
