import axios from 'axios'

import { BACKEND_URL } from 'constants/constants'

/**
 * get status of aws
 */
const awsStatus = () =>
	new Promise(function(resolve, reject) {
		var AWS = require('aws-sdk')
		var state = ''
		var ec2 = new AWS.EC2({
			accessKeyId: 'AKIAQXOX3L5NEYIADTET',
			secretAccessKey: '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',
			region: 'us-east-1',
		})
		var params = {
			InstanceIds: ['i-0e0f8971472b3c51c'],
		}
		ec2.describeInstances(params, function(err, data) {
			if (err) {
				console.log(err, err.stack)
				reject(err)
			} else {
				state = data.Reservations[0].Instances[0].State.Name
				resolve(state)
			}
		})
	})
/**
 * stop aws
 */
const stopAws = () =>
	new Promise(function(resolve, reject) {
		var AWS = require('aws-sdk')
		var ec2 = new AWS.EC2({
			accessKeyId: 'AKIAQXOX3L5NEYIADTET',
			secretAccessKey: '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',
			region: 'us-east-1',
		})
		var params = {
			InstanceIds: ['i-0e0f8971472b3c51c'],
		}
		ec2.stopInstances(params, function(err, data) {
			if (err) {
				console.log(err, err.stack)
				reject(err)
			} // an error occurred
			else {
				let state = data.StoppingInstances[0].CurrentState.Name
				resolve(state)
			}
		})
	})
/**
 * star aws
 */
const startAws = () =>
	new Promise(function(resolve, reject) {
		var AWS = require('aws-sdk')
		var ec2 = new AWS.EC2({
			accessKeyId: 'AKIAQXOX3L5NEYIADTET',
			secretAccessKey: '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',
			region: 'us-east-1',
		})
		var params = {
			InstanceIds: ['i-0e0f8971472b3c51c'],
		}
		ec2.startInstances(params, function(err, data) {
			if (err) {
				console.log(err, err.stack)
				reject(err)
			} // an error occurred
			else {
				let state = data.StartingInstances[0].CurrentState.Name
				resolve(state)
			}
		})
	})
/**
 * get django server status
 */
const getDjangoServerStatus = () => axios.get(BACKEND_URL + '/api/v1/server-status')

const uploadHistory = model =>
	axios.get(`${BACKEND_URL}/api/v2/inference-sessions?offset=0&limit=1000&modelId=${model}`)

const getInferences = session => axios.get(`${BACKEND_URL}/api/v2/inference-session/${session}`)

const getSessionId = model => axios.post(BACKEND_URL + '/api/v2/inference-session/create', { modelId: model })

const classifyImage = formData => axios.post(BACKEND_URL + '/api/v2/upload-files', formData)

// const predictDefect = queryParams => axios.get(BACKEND_URL + '/api/v1/predict' + queryParams)

const products = url =>
	axios.get(BACKEND_URL + url + '?organizationId=1&sessionId=' + sessionStorage.getItem('session_id'))

const defects = model => axios.get(`${BACKEND_URL}/api/v2/defects${model ? '?modelId=' + model : ''}`)

const confusionMatrix = session => axios.get(`${BACKEND_URL}/api/v2/inference-session/${session}/confusion-matrix`)

const predictDefect = (file, formData) => axios.patch(`${BACKEND_URL}/api/v2/org-file/${file}/update`, formData)

const reviewPrediction = formData => axios.post(BACKEND_URL + '/api/v1/review-prediction', formData)

const models = () => axios.get(BACKEND_URL + '/api/v2/ml-models')

const folderUpload = formData => axios.post(`${BACKEND_URL}/api/v2/org-file/create`, formData)

const CancelToken = axios.CancelToken
let cancel
const defectInferences = (session, offset, extraParams) => {
	cancel && cancel()
	return axios
		.get(
			`${BACKEND_URL}/api/v2/inference-session/${session}/defect-inferences?offset=${offset}&limit=20${extraParams}`,
			{
				cancelToken: new CancelToken(function executor(c) {
					cancel = c
				}),
			}
		)
		.catch(error => {
			if (axios.isCancel(error)) {
			}
		})
}

const addModel = formData => axios.post(`${BACKEND_URL}/api/v2/ml_model/migrate`, formData)

const updateInference = formData => axios.patch(`${BACKEND_URL}/api/v2/defect-inference/update`, formData)

const bookmarkAll = formData => axios.patch(`${BACKEND_URL}/api/v2/defect-inference/bulk-update`, formData)

export default {
	getDjangoServerStatus,
	awsStatus,
	stopAws,
	startAws,
	uploadHistory,
	getInferences,
	getSessionId,
	classifyImage,
	predictDefect,
	products,
	defects,
	confusionMatrix,
	reviewPrediction,
	models,
	folderUpload,
	defectInferences,
	addModel,
	updateInference,
	bookmarkAll,
}
