import React, { Component } from 'react'
import { Button, Icon, Container, Grid, Image } from 'semantic-ui-react'

import { BACKEND_URL } from 'constants/constants'

import location from 'assests/images/location.png'
import search from 'assests/images/search.png'
import stats from 'assests/images/stats.png'
import rect from 'assests/images/rect.png'

/**
 * component to analyse defect data
 */
export default class Analyse extends Component {
	componentDidUpdate() {
		window.scrollTo(0, 0)
	}
	render() {
		const { name, index, img, defective_patch, defective_patch_detection, defective_patch_segmentation } = {
			...this.props.history.location.state,
		}
		return (
			<div className="analyse">
				<div className="back">
					<Button primary onClick={e => this.props.history.push('/prediction/unitwise')}>
						<Icon name="arrow left"></Icon>Back to units list
					</Button>
				</div>
				<Container className="box">
					<Grid columns="equal" stackable>
						<Grid.Row className="header">
							<Grid.Column width={1}>{index}</Grid.Column>
							<Grid.Column>{name}</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column className="col1">
								<Grid.Row>
									<Image src={location} className="icon"></Image>
								</Grid.Row>
								<Grid.Row className="label">Defects location in the unit</Grid.Row>
								<Grid.Row>
									<Image src={BACKEND_URL + img} className="defect-image"></Image>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column className="col2">
								<Grid.Row>
									<Image src={search} className="icon"></Image>
								</Grid.Row>
								<Grid.Row className="label">Zoomed in defect area</Grid.Row>
								<Grid.Row>
									{defective_patch.map((value, index) => {
										return (
											<div key={index} className="data">
												<Image src={BACKEND_URL + value.img} className="defect-image"></Image>
												{value.defects.map((val, key) => {
													return (
														<Button primary className="defect-button" key={key}>
															{val}
															<Icon name="close"></Icon>
														</Button>
													)
												})}
											</div>
										)
									})}
								</Grid.Row>
							</Grid.Column>
							<Grid.Column className="col2">
								<Grid.Row>
									<Image src={stats} className="icon"></Image>
								</Grid.Row>
								<Grid.Row className="label">Number of defects</Grid.Row>
								<Grid.Row>
									{defective_patch_detection.map((value, index) => {
										return (
											<div key={index} className="data">
												<Image src={BACKEND_URL + value.img} className="defect-image"></Image>
												<div className="defect-label">{value.count + ' Burrs'}</div>
											</div>
										)
									})}
								</Grid.Row>
							</Grid.Column>
							<Grid.Column className="col2">
								<Grid.Row>
									<Image src={rect} className="area"></Image>
								</Grid.Row>
								<Grid.Row className="label">Area occupied by each defect</Grid.Row>
								{defective_patch_segmentation.map((value, index) => {
									return (
										<div key={index} className="data">
											<Image src={BACKEND_URL + value.img} className="defect-image"></Image>
											<div className="list1">
												{value.pixels.map((val, key) => {
													return (
														<React.Fragment key={key}>
															<span>{'Burr ' + key + ':' + val}</span>
															<br></br>
														</React.Fragment>
													)
												})}
											</div>
										</div>
									)
								})}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		)
	}
}

/* <Table cellSpacing={10}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Grid.Row>
                      <Image src={location} className="icon"></Image>
                    </Grid.Row>
                    <Grid.Row className="label">
                      Defects location in the unit
                    </Grid.Row>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Grid.Row>
                      <Image src={search} className="icon"></Image>
                    </Grid.Row>
                    <Grid.Row className="label">Zoomed in defect area</Grid.Row>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Grid.Row>
                      <Image src={stats} className="icon"></Image>
                    </Grid.Row>
                    <Grid.Row className="label">Number of defects</Grid.Row>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Grid.Row>
                      <Image src={rect} className="icon"></Image>
                    </Grid.Row>
                    <Grid.Row className="label">
                      Area occupied by each defect
                    </Grid.Row>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row></Table.Row>
              </Table.Body>
            </Table> */
