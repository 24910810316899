import React, { useEffect } from 'react'
import { Modal, Image, Loader, Icon, Button } from 'semantic-ui-react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Img from 'react-image'
import ReactTooltip from 'react-tooltip'

import { BACKEND_URL } from 'constants/constants'

import unmarked from '../../assests/images/unmarked.png'
import feedback from '../../assests/images/feedback.png'
import marked from '../../assests/images/marked.png'

const PredictionImageZoom = props => {
	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress)
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	})

	const handleKeyPress = e => {
		if (e.keyCode === 39) {
			props.toggleZoomImage(props.index + 1)
		} else if (e.keyCode === 37) {
			props.toggleZoomImage(props.index - 1)
		}
	}
	const predicted = props.data.predicted_defect
		? props.data.predicted_defect.organization_defect_code + ' (' + props.data.predicted_defect.name + ')'
		: ''
	const extra = props.data.extra_defect
		? props.data.extra_defect.organization_defect_code + ' (' + props.data.extra_defect.name + ')'
		: ''
	return (
		<Modal open={props.open} dimmer="inverted" className="p-modal">
			<Modal.Content image scrolling>
				<div className="data" style={{ margin: 'auto' }}>
					<TransformWrapper
						defaultScale={1}
						defaultPositionX={200}
						defaultPositionY={100}
						enableWheel={false}
						options={{ maxScale: 3 }}
						zoomIn={{ step: 50 }}
						zoomOut={{ step: 50 }}
						reset={{ step: 1 }}
					>
						{({
							zoomIn,
							zoomOut,
							resetTransform,
							setTransform,
							scale,
							setScale,
							previousScale,
							...rest
						}) => (
							<div style={{ display: 'flex', flexDirection: 'column', width: '1100px' }}>
								<div className="zoom-image">
									<div className="main-image">
										<TransformComponent>
											<Img
												src={
													props.data.org_file.cloud_file_url
														? props.data.org_file.cloud_file_url
														: BACKEND_URL + props.data.org_file.file
												}
												loader={<Loader></Loader>}
											></Img>
										</TransformComponent>
									</div>
									<div className="image-content">
										<div className="close-button">
											<Icon
												name="close"
												size="large"
												onClick={e => props.toggleZoomImage(-1, false)}
											></Icon>
										</div>
										<div className="image-number">{props.index + 1 + '.'}</div>
										<div className="image-name">
											<div className="name">{props.data.org_file.name.slice(0, 40) + '...'}</div>
											<div className="icon-book"></div>
										</div>
										<div className="image-result">
											<div className="ai-title">AI Result </div>
											<div className="fibre">
												<span>{predicted}</span>
												<span>{extra}</span>
											</div>
										</div>
										<div className="actions">
											<div className="action">
												<Image src={feedback} alt="feedback" />
												<span>Feedback</span>
											</div>
											{/* <div className="action">
												<Image src={analyse} alt="analyse" className="analyze" />
												<span>Analyse</span>
											</div>
											<div className="action">
												<Image src={tag} alt="tag" />
												<span>Add a Tag</span>
											</div>
											<div className="action">
												<Image src={send} alt="send" />
												<span>Send for Review</span>
											</div> */}
											<div
												className="action-last"
												onClick={e =>
													props.bookmark(
														props.index,
														props.data.org_file.id,
														!props.data.is_bookmark
													)
												}
											>
												<Image
													src={props.data.is_bookmark ? marked : unmarked}
													alt="unmarked"
												/>
												<span>Bookmark</span>
											</div>
										</div>
										<div className="pre-next">
											<Button
												className="b-primary"
												icon
												labelPosition="left"
												color="red"
												onClick={e => props.toggleZoomImage(props.index - 1)}
												disabled={props.index ? false : true}
											>
												Pre
												<Icon name="angle left"></Icon>
											</Button>
											<Button
												className="b-primary"
												icon
												labelPosition="right"
												color="red"
												onClick={e => props.toggleZoomImage(props.index + 1)}
												disabled={props.total > 1 && props.index === props.total - 1}
											>
												{props.loading ? (
													<Loader></Loader>
												) : (
													<React.Fragment>
														Next
														<Icon name="angle right"></Icon>
													</React.Fragment>
												)}
											</Button>
										</div>
									</div>
								</div>
								<div className="tools">
									<div className="scale">
										<Icon
											name="minus"
											className="clickable"
											size="small"
											data-tip
											data-for="zoomOut"
											onClick={e => zoomOut(e)}
										></Icon>
										<div>
											<input
												data-tip
												data-for="scale"
												id="range1"
												type="range"
												value={scale}
												min="1"
												max="3"
												step="any"
												onChange={e => setScale(e.target.value)}
											/>
										</div>
										<Icon
											name="plus"
											className="clickable"
											size="small"
											data-tip
											data-for="zoomIn"
											onClick={e => zoomIn(e)}
										></Icon>
										{/* <Icon
              name="undo"
              className="clickable"
              size="small"
              data-tip
              data-for="reset"
              onClick={this.props.resetTransform}
            ></Icon> */}
									</div>
									<ReactTooltip
										id="zoomOut"
										place="top"
										type="dark"
										effect="solid"
										className="prediction-tip"
									>
										<span>
											Zoom out <br />
											Press -
										</span>
									</ReactTooltip>
									<ReactTooltip
										id="zoomIn"
										place="top"
										type="dark"
										effect="solid"
										className="prediction-tip"
									>
										<span>
											Zoom in <br />
											Press +
										</span>
									</ReactTooltip>
									<ReactTooltip
										id="reset"
										place="top"
										type="dark"
										effect="solid"
										className="prediction-tip"
									>
										<span>
											Reset <br /> Press space
										</span>
									</ReactTooltip>
									<ReactTooltip
										id="scale"
										place="top"
										type="dark"
										effect="solid"
										className="prediction-tip"
									>
										<span>{parseFloat(scale).toFixed(0) * 100}</span>
									</ReactTooltip>
								</div>
							</div>
						)}
					</TransformWrapper>
				</div>
			</Modal.Content>
		</Modal>
	)
}
export default PredictionImageZoom
