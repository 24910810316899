import { SAVE_INFERENCE_DATA, SET_UPLOADING_STATUS, RESET, UPDATE_UPLOAD_COUNT } from 'constants/action-types'

const initialState = {
	defectStats: [],
	totalFiles: 0,
	uploadedFiles: 0,
	inferencedFiles: 0,
	loading: true,
	accuracy: 0,
	uploading: false,
	showInferenceProgress: false,
}

function overview(state = initialState, action) {
	switch (action.type) {
		case SAVE_INFERENCE_DATA:
			return {
				...state,
				uploadedFiles: action.data.file_count,
				inferencedFiles: action.data.inference_file_count,
				defectStats: [...state.defectStats, ...action.data.defect_stats],
				accuracy: action.data.accuracy,
				loading: false,
			}
		case SET_UPLOADING_STATUS:
			return {
				...state,
				uploading: action.status,
				totalFiles: action.totalFiles,
				uploadedFiles: 0,
				inferencedFiles: 0,
				showInferenceProgress: action.showInferenceStatus,
			}
		case UPDATE_UPLOAD_COUNT:
			return {
				...state,
				uploadedFiles: state.uploadedFiles + action.count,
			}
		case RESET:
			return { ...initialState, showInferenceProgress: false }
		default:
			return state
	}
}

export default overview
