import React, { Component } from 'react'
import { Modal, Image, Table, Button, Icon } from 'semantic-ui-react'

import ReactImageAnnotate from 'react-image-annotate'

import VIAImageCanvas from 'components/VIAImageCanvas'
import classnames from 'classnames'
import { import_annotations_from_json, via_show_img } from 'helpers'

import arrow_close from 'assests/images/arrow_close.png'
import arrow_left from 'assests/images/arrow_left.png'
import arrow_right from 'assests/images/arrow_right.png'

import 'components/VIAImageZoom/VIAImageZoom.scss'

export default class VIAImageZoom extends Component {
	// Once loaded image from url, getting started drawing VIA regions
	// Get image/canvas element, Call Helper function for drawing
	handleImgLoad(data) {
		const via_img_metadata = import_annotations_from_json(data)
		const viaImage = document.getElementById('via-zoom-image')
		if (viaImage) {
			const viaCanvas = document.getElementById('via-zoom-canvas')
			via_show_img(via_img_metadata, viaImage, viaCanvas)
		}
	}
	render() {
		const regions = this.props.data ? this.props.data.regions : []
		const region_attributes_array = regions.map(region => {
			return region.region_attributes
		})
		const region_table_headers = region_attributes_array[0] ? Object.keys(region_attributes_array[0]) : []
		const region_table_data =
			region_attributes_array.length > 0 ? (
				region_attributes_array.map((value, index) => {
					return (
						<Table.Row key={index} className="unit-row">
							<Table.Cell>{index + 1}</Table.Cell>
							{Object.keys(value).map((attribute, key) => {
								if (typeof value[attribute] === 'string') {
									return (
										<Table.Cell key={key}>
											<div className="defect-name">{value[attribute]}</div>
										</Table.Cell>
									)
								} else {
									const option_defatuls_keys = Object.keys(value[attribute])
									const option_defauls = option_defatuls_keys ? option_defatuls_keys.join(', ') : ''
									return (
										<Table.Cell key={key}>
											<div className="defect-name">{option_defauls}</div>
										</Table.Cell>
									)
								}
							})}
						</Table.Row>
					)
				})
			) : (
				<Table.Row className="no-data">
					<Table.Cell colSpan={6}>No Region Attributes</Table.Cell>
				</Table.Row>
			)

		// Table for region attributes under VIA Image/Canvas
		const region_attributes_table = (
			<Table className="unit-table">
				{region_table_headers.length > 0 && (
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
							{region_table_headers.map((header, key) => {
								return <Table.HeaderCell key={key}>{header}</Table.HeaderCell>
							})}
						</Table.Row>
					</Table.Header>
				)}
				<Table.Body>{region_table_data}</Table.Body>
			</Table>
		)

		return (
			<Modal
				open={this.props.open}
				dimmer="inverted"
				className={`${classnames({ 'modal-viaiframe': this.props.openVIAEditor })}`}
			>
				<Modal.Content
					image
					scrolling
					className={`${classnames({
						'modal-viaimagezoom': !this.props.openVIAEditor,
						'modal-viaiframe-content': this.props.openVIAEditor,
					})}`}
				>
					{this.props.openVIAEditor ? ( // if openVIAEditor is true, display VIA Tool iframe
						// <iframe
						//   id="via-iframe"
						//   src="/via.html"
						//   height="100%"
						//   width="100%"
						//   frameBorder="0"
						//   title="VIA Tool"
						// ></iframe>
						<ReactImageAnnotate
							selectedImage={this.props.data.viaKey} //"https://image.shutterstock.com/image-photo/beautiful-landscape-mountain-layer-morning-600w-753385105.jpg"
							taskDescription="Draw region around the defects"
							images={[{ src: this.props.data.viaKey, name: this.props.name }]}
							regionClsList={['Man Face', 'Woman Face']}
							onExit={this.props.handleVIAEditor}
						/>
					) : (
						// else, display VIA image/canvas with region attributes table
						<React.Fragment>
							<Image
								src={arrow_left}
								className="navigate-left"
								onClick={e => this.props.toggleImage(-1)}
								style={{ opacity: this.props.index ? 1 : 0 }}
							></Image>
							<div className="data" style={{ width: '70%', height: '100%' }}>
								<div className="tool-header">
									<Button primary className="via-btn" onClick={this.props.handleVIAEditor}>
										<Icon name="sign-in alternate"></Icon>SixSense VIA
									</Button>
									<Image src={arrow_close} onClick={this.props.hideImages}></Image>
								</div>
								<div className="defect-name">{'File Name : ' + this.props.name}</div>

								<VIAImageCanvas
									data={this.props.data}
									idPrefix="image-zoom-view-"
									index={this.props.index}
									showImages={() => {}} // Nothing when click canvas image on zoom view
								></VIAImageCanvas>

								<div className="via-desc">{region_attributes_table}</div>
							</div>
							<Image
								src={arrow_right}
								className="navigate-right"
								onClick={e => this.props.toggleImage(1)}
								style={{
									opacity: this.props.total > 1 && this.props.index < this.props.total - 1 ? 1 : 0,
								}}
							></Image>
						</React.Fragment>
					)}
				</Modal.Content>
			</Modal>
		)
	}
}
