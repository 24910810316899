export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const File = 1
export const Folder = 2
export const Db = 3

export const BAR_CHART = 1
export const DOUBLE_BAR_CHART = 2
export const PIE_CHART = 3

export const DEMO1 = '1'
export const DEMO2 = '2'
export const DEMO3 = '3'
export const DEMO4 = '4'
export const DEMO5 = '5'

export const ASC = 1
export const DESC = -1

export const UNIT = 1
export const DEFECT = 2
export const PAGESIZE = 25

export const VIA_REGION_SHAPE = {
	RECT: 'rect',
	CIRCLE: 'circle',
	ELLIPSE: 'ellipse',
	POLYGON: 'polygon',
	POINT: 'point',
	POLYLINE: 'polyline',
}
export const VIA_REGION_POINT_RADIUS = 3
export const VIA_THEME_REGION_BOUNDARY_WIDTH = 3
export const VIA_THEME_BOUNDARY_LINE_COLOR = 'black'
export const VIA_THEME_BOUNDARY_FILL_COLOR = '#1ca7e5'
export const VIA_REGION_LABEL_FONT = '10px Futura'

export const VIA_ATTRIBUTE_TYPE = {
	TEXT: 'text',
	CHECKBOX: 'checkbox',
	RADIO: 'radio',
	DROPDOWN: 'dropdown',
}

export const VIA_ATTRIBUTE_DROPDOWN_TYPES = [
	{ key: 'text', text: 'text', value: 'text' },
	{ key: 'checkbox', text: 'checkbox', value: 'checkbox' },
	{ key: 'radio', text: 'radio', value: 'radio' },
	{ key: 'dropdown', text: 'dropdown', value: 'dropdown' },
]

export const VIADefaultAttributes = {
	region: {
		name: {
			type: 'dropdown',
			description: 'Name of the object',
			default_value: 'not_defined',
		},
		image_quality: {
			type: 'radio',
			description: 'Quality of image region',
			options: {
				'216-CU_PUDDLE': '216-CU_PUDDLE',
				'205-CU_VOIDS': '205-CU_VOIDS',
			},
			default_options: {
				'216-CU_PUDDLE': true,
			},
		},
	},
	file: {
		caption: { type: 'text', description: '', default_value: '' },
		public_domain: {
			type: 'radio',
			description: '',
			options: { yes: 'Yes', no: 'No' },
			default_options: { no: true },
		},
		image_url: { type: 'text', description: '', default_value: '' },
	},
}

export const defect_map = {
	'3': {
		SMALL_DEF: '8',
		UNKNOWN_BIN: 'notvalid',
		'SURF_BIG_DEF / BLK_DEP_DEF': '12',
		PIN_HOLE_CU_VOIDS: '207',
		FLAKE: '87',
		RESIDUE: '15',
		WATERMARK: '18',
		ARC_SCRATCHES: '237',
		CU_VOIDS: '205',
		KILLER_SCRATCHES: '239',
		PEELING: '40',
		BOMB_DEF: '29',
	},
	'2': {
		PATT_SMALL_DEF: '8',
		SMALL_DEF: '9',
		PATT_SURF_BIG_DEF: '12',
		SURF_BIG_DEF: '13',
		BLK_DEP_DEF: '68',
		PIN_HOLE_CU_VOIDS: '207',
		PATT_FLAKE: '87',
		FLAKE: '88',
		PATT_RESIDUE: '15',
		RESIDUE: '16',
		WATERMARK: '18',
		ARC_SCRATCHES: '237',
		CU_VOIDS: '205',
		KILLER_SCRATCHES: '239',
		PEELING: '40',
		BOMB_DEF: '29',
	},
	'4': {
		BLOCK_ETCH: '213',
		EMB_PARTICLE: '128',
		CU_VOIDS: '205',
		SUBSTRATE_DEF: '198',
		PATT_RESIDUE: '15',
		PATT_FLAKE: '87',
		SMALL_DEF: '9',
		FLAKE: '88',
		CU_PUDDLE: '216',
		CROWN: '209',
		MICRO_SCRATCH: '37',
		PATT_SURF_BIG_DEF: '12',
		KILLER_SCRATCHES: '239',
		PATT_SMALL_DEF: '8',
		SURF_BIG_DEF: '13',
	},
	'5': {
		SMALL_DEF: '9',
		SURF_BIG_DEF: '13',
		RESIDUE: '16',
		WATERMARK: '18',
		BOMB_DEF: '29',
		FILAMENT: '33',
		ARCING: '38',
		BLK_DEP_DEF: '68',
		MTAL_CRACK: '83',
		WHISKER: '85',
		HDMD_PART: '86',
		FLAKE: '88',
		CRATER: '121',
		ARC_SCRATCHES: '237',
		KILLER_SCRATCHES: '239',
		UNKNOWNBIN: '999',
	},
}

export const layers = {
	'1': 'Cu_void/Crown/Puddle',
	'3': 'CuAnneal_Merged',
	'5': ' MetalDepandDarc',
	'6': 'ContactViaBarrier',
}
