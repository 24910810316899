import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Button, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import { GridView, Filters, SelectedFilters } from './Components'
import Api from 'Api'
import { downloadPredictionReport, modelName } from 'helpers'
import PerformanceImageZoom from 'components/ImageZoom/PerformanceImageZoom'
import { setTitle, setError, predictDefect, resetPredictions, saveParams } from 'actions'
import { useZoom } from 'hooks/useZoom'
import { useFetch } from 'hooks/useFetch'

const Unitwise = () => {
	const {
		model,
		session,
		actualDefectId,
		predictedDefectId,
		bookmarked,
		defects,
		defectDict,
		models,
		stateLoaded,
		loading,
		total,
		inferences,
		inferencesLoaded,
	} = useSelector(
		state => ({
			model: state.common.model,
			session: state.common.session,
			actualDefectId: state.common.actualDefectId,
			predictedDefectId: state.common.predictedDefectId,
			bookmarked: state.common.bookmarked,
			defects: state.common.modelDefects,
			defectDict: state.common.defectDict,
			models: state.common.models,
			stateLoaded: state.common.stateLoaded,
			total: state.predictions.total,
			inferences: state.predictions.inferences,
			loading: state.predictions.loading,
			inferencesLoaded: state.predictions.inferencesLoaded,
		}),
		shallowEqual
	)

	let history = useHistory()
	const dispatch = useDispatch()

	const [filteredData, setFilteredData] = useState([])
	const [display, setDisplay] = useState(1)
	const [bookmarkDisplay, setBookmarkDisplay] = useState(1)

	const { offset, loadMore, setOffset } = useFetch(
		stateLoaded && !inferencesLoaded,
		session,
		actualDefectId,
		predictedDefectId,
		bookmarked
	)
	const { zoomData, toggleZoomImage } = useZoom(inferences, loadMore, offset)

	useEffect(() => {
		dispatch(setTitle('Performance Monitoring of the Platform'))
	}, [dispatch])

	useEffect(() => {
		setFilteredData(inferences)
	}, [inferences])

	const predictRight = async (fileId, defect, isCorrect) => {
		const formData = new FormData()
		formData.append('defectId', defect.id)
		dispatch(predictDefect(fileId, defect, isCorrect))
		await Api.predictDefect(fileId, formData).catch(e => {
			dispatch(setError())
		})
	}

	const updateDisplay = display => {
		setDisplay(display)
	}

	const recordWrongFeedback = fileId => {
		const formData = new FormData()
		formData.append('orgFileId', fileId)
		formData.append('sessionId', session)
		formData.append('isCorrect', false)
		Api.updateInference(formData).catch(e => dispatch(setError()))
	}

	const updateBookmarkDisplay = option => {
		dispatch(resetPredictions())
		setBookmarkDisplay(option)
		setOffset(0)
		let q = `?model=${model}&session=${session}`
		if (actualDefectId) q += `&actualDefectId=${actualDefectId}`
		if (predictedDefectId) q += `&predictedDefectId=${predictedDefectId}`
		if (option === 2) q += `&bookmarked=${true}`
		if (option === 3) q += `&bookmarked=${false}`
		dispatch(saveParams({ bookmarked: option }))
		history.replace(`${history.location.pathname}${q}`)
	}

	const clearIdFilter = () => {
		dispatch(resetPredictions())
		dispatch(saveParams({ actualDefectId: '', predictedDefectId: '' }))
		let q = `?&model=${model}&session=${session}`
		if (bookmarkDisplay === 2) q += `&bookmarked=${true}`
		if (bookmarkDisplay === 3) q += `&bookmarked=${false}`
		history.replace(`${history.location.pathname}${q}`)
	}

	return (
		<React.Fragment>
			{zoomData.open ? (
				<PerformanceImageZoom
					index={zoomData.index}
					data={zoomData.data}
					toggleZoomImage={toggleZoomImage}
					total={total}
					open={zoomData.open}
					display={display}
					predictRight={predictRight}
					canGiveFeedback={display === 1 ? false : true}
					loading={loading}
				></PerformanceImageZoom>
			) : (
				''
			)}
			<div className="misclass">
				<div className="title">
					<div className="sub-title">Unitwise Analysis of {modelName(models, model)}</div>
					<div>
						<Button
							className="box-button ss-big"
							onClick={e => downloadPredictionReport(actualDefectId, predictedDefectId, session)}
						>
							Download CSV
						</Button>
					</div>
				</div>
				<Grid columns={4} className="misclass-analysis">
					<Filters
						display={display}
						setDisplay={updateDisplay}
						// filters={filters}
						// defectFilter={filters.defects}
						// applyDefectFilter={applyDefectFilter}
						// resetPredicted={resetPredictedFilter}
						// resetFeedback={resetFeedbackFilter}
						// setNameFilter={setNameFilter}
						bookmarkDisplay={bookmarkDisplay}
						setBookmarkDisplay={updateBookmarkDisplay}
					></Filters>
					<SelectedFilters
						bookmarkDisplay={bookmarkDisplay}
						// showTable={showTable}
						// setShowTable={setShowTable}
						display={display}
						// defectFilter={filters.defects}
						// removePredictedFilter={removeSingleFilter}
						// resetNameFilter={resetNameFilter}
						// name={filters.name}
						clearIdFilter={clearIdFilter}
						defectDict={defectDict}
						actual={actualDefectId}
						predicted={predictedDefectId}
					></SelectedFilters>

					<React.Fragment>
						<Grid.Row className="show-result">
							Showing {total} of {total} results
						</Grid.Row>
						{filteredData.length > 0 && (
							<InfiniteScroll
								dataLength={inferences.length}
								next={() => loadMore(offset + 20)}
								hasMore={true}
								scrollThreshold={0.9}
								style={{ overflow: 'visible', width: '100%' }}
							>
								<div className="card-grid">
									{filteredData.map((item, i) => {
										return (
											<GridView
												data={item}
												display={display}
												predictRight={predictRight}
												key={i}
												index={i}
												openZoom={toggleZoomImage}
												defects={defects}
												recordWrongFeedback={recordWrongFeedback}
												canGiveFeedback={display === 1 ? false : true}
											></GridView>
										)
									})}
								</div>
							</InfiniteScroll>
						)}
						{loading && inferences.length <= total && (
							<Grid.Row>
								<Loader>Loading</Loader>
							</Grid.Row>
						)}
					</React.Fragment>
				</Grid>
			</div>
			{/* <Grid className="trend">
        <Grid.Row className="trend-row">
          <Grid.Column className="trend-row-column">Trend Charts</Grid.Column>
          <Grid.Column className="trend-row-column2"></Grid.Column>
        </Grid.Row>
        <Grid.Row className="trend-row2">
          <Grid.Column className="trend-row2-column">
            <Button
              onClick={e =>
                props.history.push({
                  pathname: '/performance/trend-charts',
                  state: {
                    menuOpen: 3,
                  },
                })
              }
            >
              Learn More{' '}
            </Button>
          </Grid.Column>
          <Grid.Column className="trend-row2-column2"></Grid.Column>
        </Grid.Row>
      </Grid> */}
		</React.Fragment>
	)
}

export default Unitwise

// const memoizeFilter = memoizeOne(unitwiseDefectFilter)
// const [filters, setFilters] = useState({
//   name: '',
//   defects: {
//     predicted: [],
//     feedback: [],
//   },
//   display: 1,
//   canGiveFeedback: false,
// })
// const memoizePredictFilter = memoizeOne(getUnitwisePredictedDefects)
// const memoizeFeedbackFilter = memoizeOne(getUnitwiseFeedbackDefects)
// setFilters({
//   ...filters,
//   display: display,
//   canGiveFeedback: canGiveFeedback,
// })
// let { temp } = memoizeFilter(
//   { predicted: filters.defects.predicted, feedback: filters.defects.feedback },
//   inferences,
//   display,
//   filters.name
// )
// const applyDefectFilter = f => {
//   let { temp, hasFilter } = memoizeFilter(f, inferences, filters.display, filters.name)
//   if (hasFilter) {
//     setFilteredData(temp)
//   } else {
//     setFilteredData(inferences)
//   }
// }
// const setNameFilter = text => {
//   setFilters({
//     ...filters,
//     name: text,
//   })
//   let { temp } = memoizeFilter(
//     { predicted: filters.defects.predicted, feedback: filters.defects.feedback },
//     inferences,
//     filters.display,
//     text
//   )
//   setFilteredData(temp)
// }
// const resetPredictedFilter = () => {
//   let p = memoizePredictFilter(inferences)
//   setFilters({
//     ...filters,
//     defects: {
//       ...filters.defects,
//       predicted: p,
//     },
//   })
//   let { temp, hasFilter } = memoizeFilter(
//     { predicted: p, feedback: filters.defects.feedback },
//     filteredData,
//     filters.display,
//     filters.name
//   )
//   if (hasFilter) {
//     setFilteredData(temp)
//   } else {
//     setFilteredData(inferences)
//   }
// }

// const resetFeedbackFilter = () => {
//   let f = memoizeFeedbackFilter(inferences)
//   setFilters({
//     ...filters,
//     defects: {
//       ...filters.defects,
//       feedback: f,
//     },
//   })
//   let { temp, hasFilter } = memoizeFilter(
//     { predicted: filters.defects.predicted, feedback: f },
//     inferences,
//     filters.display,
//     filters.name
//   )
//   if (hasFilter) {
//     setFilteredData(temp)
//   } else {
//     setFilteredData(inferences)
//   }
// }
// const resetNameFilter = () => {
//   setFilters({
//     ...filters,
//     name: '',
//   })
//   let { temp } = memoizeFilter(
//     { predicted: filters.defects.predicted, feedback: filters.defects.feedback },
//     inferences,
//     filters.display,
//     ''
//   )
//   setFilteredData(temp)
// }
// const removeSingleFilter = (id, key) => {
//   const d = filters.defects
//   d[key][id].checked = !d[key][id].checked
//   setFilters({
//     ...filters,
//     defects: d,
//   })
//   let { temp } = memoizeFilter(d, inferences, filters.display, filters.name)
//   setFilteredData(temp)
// }
