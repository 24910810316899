import {
	SAVE_DEFECT_INFERENCES,
	CHECK_INFERENCE,
	UNCHECK_INFERENCE,
	SELECT_ALL_INFERENCES,
	UNSELECT_ALL_INFERENCES,
	ADD_INFERENCE_BOOKMARK,
	REMOVE_INFERENCE_BOOKMARK,
	PREDICT_DEFECT,
	RESET_PREDICTIONS,
	RESET_CHECKED_PREDICTIONS,
	BOOKMARK_CHECKED_INFERENCES,
	SET_SHOW_CHECKED,
	RESET,
	SET_INFERENCE_LOADING,
	GIVE_SINGLE_FEEDBACK,
	REMOVE_INFERENCE,
} from 'constants/action-types'

const initialState = {
	loading: true,
	total: 0,
	inferences: [],
	unitChecked: 0,
	viewChecked: 0,
	giveChecked: 0,
	showChecked: false,
	showCheckedKey: '',
	inferencesLoaded: false,
	singleSelected: -1,
}

export function predictions(state = initialState, action) {
	switch (action.type) {
		case SAVE_DEFECT_INFERENCES:
			return {
				...state,
				loading: false,
				total: action.data.total_count,
				inferencesLoaded: true,
				inferences: [
					...state.inferences,
					...action.data.defect_inferences.map((item, i) => {
						return {
							...item,
							unitChecked: false,
							viewChecked: false,
							giveChecked: false,
						}
					}),
				],
			}
		case CHECK_INFERENCE:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					if (item.id === action.id) {
						return {
							...item,
							[action.key]: true,
						}
					}
					return item
				}),
				[action.key]: state[action.key] + 1,
			}
		case UNCHECK_INFERENCE:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					if (item.id === action.id) {
						return {
							...item,
							[action.key]: false,
						}
					}
					return item
				}),
				[action.key]: state[action.key] - 1,
			}
		case SELECT_ALL_INFERENCES:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					return {
						...item,
						[action.key]: true,
					}
				}),
				[action.key]: state.inferences.length,
			}
		case UNSELECT_ALL_INFERENCES:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					return {
						...item,
						[action.key]: false,
					}
				}),
				[action.key]: 0,
			}
		case ADD_INFERENCE_BOOKMARK:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					if (item.org_file.id === action.id) {
						return {
							...item,
							is_bookmark: true,
						}
					}
					return item
				}),
			}
		case REMOVE_INFERENCE_BOOKMARK:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					if (item.org_file.id === action.id) {
						return {
							...item,
							is_bookmark: false,
						}
					}
					return item
				}),
			}
		case PREDICT_DEFECT:
			return {
				...state,
				inferences: state.inferences.map((item, index) => {
					if (item.org_file.id === action.fileId) {
						return {
							...item,
							actual_defect: { ...action.defect },
							is_correct: action.isCorrect,
						}
					}
					return item
				}),
			}
		case RESET_CHECKED_PREDICTIONS:
			return {
				...state,
				inferences: state.inferences.map((item, i) => {
					return {
						...item,
						[action.key]: false,
					}
				}),
				[action.key]: 0,
				showChecked: false,
			}
		case BOOKMARK_CHECKED_INFERENCES:
			if (action.all) {
				return {
					...state,
					inferences: state.inferences.map(item => {
						return {
							...item,
							is_bookmark: action.val,
						}
					}),
				}
			} else {
				return {
					...state,
					inferences: state.inferences.map(item => {
						if (item[action.key]) {
							return {
								...item,
								is_bookmark: !item.is_bookmark,
							}
						} else {
							return item
						}
					}),
				}
			}
		case REMOVE_INFERENCE:
			return {
				...state,
				inferences: state.inferences.filter(item => {
					return item.org_file.id !== action.id
				}),
				[action.key]: state[action.key] - 1,
			}
		case SET_SHOW_CHECKED:
			return {
				...state,
				showChecked: true,
				showCheckedKey: action.key,
				singleSelected: -1,
			}
		case GIVE_SINGLE_FEEDBACK:
			return {
				...state,
				singleSelected: action.id,
			}
		case SET_INFERENCE_LOADING:
			return {
				...state,
				loading: true,
			}
		case RESET_PREDICTIONS:
			return { ...initialState }
		case RESET:
			return {
				...initialState,
			}
		default:
			return state
	}
}
