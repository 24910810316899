import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { getDefectInferences, setInferenceLoading } from 'actions'

export function useFetch(stateLoaded, session, actualDefectId, predictedDefectId, bookmarked) {
	const disptach = useDispatch()

	const [offset, setOffset] = useState(0)
	const [load, setLoad] = useState(false)

	useEffect(() => {
		setLoad(stateLoaded)
	}, [stateLoaded])

	useEffect(() => {
		setOffset(0)
	}, [actualDefectId, predictedDefectId])

	useEffect(() => {
		if (load) {
			disptach(setInferenceLoading())
			disptach(getDefectInferences(session, offset, actualDefectId, predictedDefectId, bookmarked))
		}
	}, [actualDefectId, bookmarked, disptach, load, offset, predictedDefectId, session])

	const loadMore = val => {
		disptach(setInferenceLoading())
		setOffset(val)
		setLoad(true)
	}

	return { offset, setOffset, loadMore }
}
