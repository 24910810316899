import { useState, useEffect } from 'react'
import filter from 'lodash/filter'
import escapeRegExp from 'lodash/escapeRegExp'

export const useDropDownFilter = defects => {
	const [data, setData] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [checkedDefect, setCheckedDefect] = useState({})

	useEffect(() => {
		setData(defects)
	}, [defects])

	useEffect(() => {
		let filteredData = defects
		if (searchTerm) {
			const re = new RegExp(escapeRegExp(searchTerm), 'i')
			const isMatch = result => re.test(result.name)
			filteredData = filter(filteredData, isMatch)
		}
		setData(filteredData)
	}, [defects, searchTerm])

	const change = e => {
		setSearchTerm(e.target.value || e.target.innerText)
	}
	const toggleDefect = defect => {
		setCheckedDefect(defect)
	}
	return { data, searchTerm, change, checkedDefect, toggleDefect }
}
