import React, { useState, useEffect } from 'react'
import { NavLink, withRouter, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useWindowSize } from 'hooks/useResize'

import expand from '../../assests/images/expand.svg'
import collpase from '../../assests/images/collapse.svg'

const Menu = props => {
	const { model, session } = useSelector(state => ({
		model: state.common.model,
		session: state.common.session,
	}))

	const history = useHistory()

	const [open, setOpen] = useState(0)
	const [expanded, setExpanded] = useState(true)
	const [mouseOver, setMouseOver] = useState(false)
	const [onPrediction, setOnPrediction] = useState(false)
	const [onPerformance, setOnPerformance] = useState(false)

	const [x, height] = useWindowSize()

	const width = expanded || mouseOver ? 250 : 70

	let left = (x - 1440) / 2
	if (left < 0) {
		left = 0
	}

	const style = {
		minHeight: `${height - 94}px`,
		width: `${width}px`,
	}

	useEffect(() => {
		if (history.location.pathname.includes('prediction')) {
			setOpen(2)
		} else if (history.location.pathname.includes('performance')) {
			setOpen(3)
		} else if (history.location.pathname.includes('feedback')) {
			setOpen(4)
		}
	}, [history.location.pathname])

	return (
		<React.Fragment>
			<div
				className="menu"
				style={model === 0 && props.many ? { pointerEvents: 'none', ...style } : { ...style }}
			>
				<ul onMouseEnter={e => setMouseOver(true)} onMouseLeave={e => setMouseOver(false)}>
					{/* <li className="item">
          <NavLink activeClassName="active" to="/">
            <div className="bar"></div>
            <div className="image">
              <img src={book} alt="" />
            </div>
            <div className="title">Glossary</div>
          </NavLink>
        </li> */}
					<li className="item model-item" onClick={e => setOpen(0)}>
						<NavLink activeClassName="active" to="/" exact>
							<div className="bar"></div>
							<div className="model"></div>
							<div className="title">Select Model</div>
						</NavLink>
					</li>
					<li className="item upload-item" onClick={e => setOpen(0)}>
						<NavLink activeClassName="active" to={`/data-acquisition?model=${model}`}>
							<div className="bar"></div>
							<div className="upload">{/* <img src={upload} alt="" /> */}</div>
							<div className="title">Data Acquisition</div>
						</NavLink>
					</li>
					<li
						className="item notepad-item"
						style={session ? {} : { pointerEvents: 'none' }}
						onMouseOver={() => setOnPrediction(true)}
						onMouseLeave={() => setOnPrediction(false)}
					>
						<div
							className={open === 2 ? 'active' : 'inactive'}
							onClick={e => {
								setOpen(2)
								history.push(`/prediction/overview?model=${model}&session=${session}`)
							}}
						>
							<div className="bar"></div>
							<div className="notepad"></div>
							<div className="title">Prediction Results</div>
						</div>
					</li>
					<div
						className="prediction"
						onMouseOver={() => setOnPrediction(true)}
						onMouseLeave={() => setOnPrediction(false)}
						style={{ display: onPrediction || (open === 2 && (expanded || mouseOver)) ? 'block' : 'none' }}
					>
						<li className="item-sub">
							<NavLink
								to={`/prediction/overview?model=${model}&session=${session}`}
								activeClassName="active"
							>
								<div className="title-sub">Overview</div>
							</NavLink>
						</li>
						<li className="item-sub">
							<NavLink
								to={`/prediction/unitwise?model=${model}&session=${session}`}
								activeClassName="active"
							>
								<div className="title-sub">Unitwise</div>
							</NavLink>
						</li>
					</div>
					<li
						className="item feedback-item"
						onClick={e => setOpen(4)}
						style={session ? {} : { pointerEvents: 'none' }}
					>
						<div
							className={open === 4 ? 'active' : 'inactive'}
							onClick={e => {
								setOpen(4)
								history.push(`/feedback/view?model=${model}&session=${session}`)
							}}
						>
							<div className="bar"></div>
							<div className="feedback"></div>
							<div className="title">Feedback</div>
						</div>
					</li>
					<li
						className="item speed-item"
						style={session ? {} : { pointerEvents: 'none' }}
						onMouseOver={() => setOnPerformance(true)}
						onMouseLeave={() => setOnPerformance(false)}
					>
						<div
							className={open === 3 ? 'active' : 'inactive'}
							onClick={e => {
								setOpen(3)
								history.push(`/performance/overall?model=${model}&session=${session}`)
							}}
						>
							<div className="bar"></div>
							<div className="speed"></div>
							<div className="title">Performance Monitoring</div>
						</div>
					</li>
					<div
						onMouseOver={() => setOnPerformance(true)}
						onMouseLeave={() => setOnPerformance(false)}
						style={{ display: onPerformance || (open === 3 && (expanded || mouseOver)) ? 'block' : 'none' }}
					>
						<li className="item-sub">
							<NavLink
								to={`/performance/overall?model=${model}&session=${session}`}
								activeClassName="active"
							>
								<div className="title-sub">Overall Performance</div>
							</NavLink>
						</li>
						<li className="item-sub">
							<NavLink
								to={`/performance/confusion-matrix?model=${model}&session=${session}`}
								activeClassName="active"
							>
								<div className="title-sub">Confusion Matrix</div>
							</NavLink>
						</li>
						<li className="item-sub">
							<NavLink
								to={`/performance/unitwise-analysis?model=${model}&session=${session}`}
								activeClassName="active"
							>
								<div className="title-sub">Unitwise Analysis</div>
							</NavLink>
						</li>
					</div>
				</ul>
				<img
					src={mouseOver ? collpase : expanded ? collpase : expand}
					alt=""
					style={{ left: `${width + left - 10}px` }}
					onClick={e => setExpanded(x => !x)}
				/>
			</div>
			<div className="expand" style={{ left: width }}></div>
		</React.Fragment>
	)
}

export default withRouter(Menu)
