import {
	SET_TITLE,
	START_GPU,
	STOP_GPU,
	SERVER_STATUS_REQ,
	START_AWS_POLLING,
	START_DJANGO_POLLING,
	STOP_DJANGO_POLLING,
	STOP_AWS_POLLING,
	UPLOAD_HISTORY_REQ,
	UPLOAD_IMAGE_REQ,
	SET_MODEL,
	AWS_STATUS_UPDATE,
	ERROR,
	DJANGO_STATUS_UPDATE,
	SAVE_MODELS,
	SET_STATE_STATE,
	SAVE_DEFECTS,
	DEFECTS_REQ,
	SAVE_UPLOAD_HISTORY,
	SAVE_SESSION_ID,
	INFERENCE_DATA_REQ,
	SAVE_INFERENCE_DATA,
	DEFECT_INFERENCES_REQ,
	SAVE_DEFECT_INFERENCES,
	CONFUSION_MATRIX_REQ,
	SAVE_CONFUSION_MATRIX,
	RESET,
	CHECK_INFERENCE,
	UNCHECK_INFERENCE,
	SELECT_ALL_INFERENCES,
	UNSELECT_ALL_INFERENCES,
	ADD_INFERENCE_BOOKMARK,
	REMOVE_INFERENCE_BOOKMARK,
	PREDICT_DEFECT,
	RESET_PREDICTIONS,
	RESET_CHECKED_PREDICTIONS,
	BOOKMARK_CHECKED_INFERENCES,
	SET_SHOW_CHECKED,
	SET_INFERENCE_LOADING,
	GIVE_SINGLE_FEEDBACK,
	REMOVE_INFERENCE,
	SET_UPLOADING_STATUS,
	POLL_SESSION_REQ,
	UPDATE_UPLOAD_COUNT,
	STOP_INFERENCE_POLLING,
	SAVE_PARAMS,
	MODEL_DEFECTS_REQ,
	SAVE_MODEL_DEFECTS,
} from 'constants/action-types'

export function uploadImageReq(payload) {
	return { type: UPLOAD_IMAGE_REQ, payload }
}

export function startAwsPolling() {
	return { type: START_AWS_POLLING }
}

export function stopAwsPolling(payload) {
	return { type: STOP_AWS_POLLING }
}

export function stopDjangoPolling() {
	return { type: STOP_DJANGO_POLLING }
}

export function startDjangoPolling() {
	return { type: START_DJANGO_POLLING }
}

export function setModel(model) {
	return { type: SET_MODEL, model }
}

export function setGpuStatus(state) {
	return { type: AWS_STATUS_UPDATE, state }
}

export function setError() {
	return { type: ERROR }
}

export function setDjangoStatus(state) {
	return { type: DJANGO_STATUS_UPDATE, state }
}

export function saveModels(models) {
	return { type: SAVE_MODELS, models }
}

export function setStateState(state) {
	return { type: SET_STATE_STATE, state }
}

export function getDefects() {
	return { type: DEFECTS_REQ }
}

export function saveDefects(defects) {
	return { type: SAVE_DEFECTS, defects }
}

export function getUploadHistory(model) {
	return { type: UPLOAD_HISTORY_REQ, model }
}

export function getServerState(payload) {
	return { type: SERVER_STATUS_REQ, payload }
}

export function setTitle(title) {
	return { type: SET_TITLE, title }
}

export function saveUploadHistory(history) {
	return { type: SAVE_UPLOAD_HISTORY, history }
}

export function saveSessionId(sessionId) {
	return { type: SAVE_SESSION_ID, sessionId }
}

export function getInferenceData(sessionId) {
	return { type: INFERENCE_DATA_REQ, sessionId }
}

export function saveInferenceData(data) {
	return { type: SAVE_INFERENCE_DATA, data }
}

export function getDefectInferences(sessionId, offset, actual = '', predicted = '', bookmarked) {
	return { type: DEFECT_INFERENCES_REQ, sessionId, offset, actual, predicted, bookmarked }
}

export function saveDefectInferences(data) {
	return { type: SAVE_DEFECT_INFERENCES, data }
}

export function getConfusionMatrix(sessionId) {
	return { type: CONFUSION_MATRIX_REQ, sessionId }
}

export function saveConfusionMatrix(data) {
	return { type: SAVE_CONFUSION_MATRIX, data }
}

export function startGpu() {
	return { type: START_GPU }
}

export function stopGpu() {
	return { type: STOP_GPU }
}

export function reset() {
	return { type: RESET }
}

export function checkInference(id, key) {
	return { type: CHECK_INFERENCE, id, key }
}

export function uncheckInference(id, key) {
	return { type: UNCHECK_INFERENCE, id, key }
}

export function selectAllInferences(key) {
	return { type: SELECT_ALL_INFERENCES, key }
}

export function unselectAllInfereces(key) {
	return { type: UNSELECT_ALL_INFERENCES, key }
}

export function addInferenceBookmark(id) {
	return { type: ADD_INFERENCE_BOOKMARK, id }
}

export function removeInferenceBookmark(id) {
	return { type: REMOVE_INFERENCE_BOOKMARK, id }
}

export function predictDefect(fileId, defect, isCorrect) {
	return { type: PREDICT_DEFECT, fileId, defect, isCorrect }
}

export function resetPredictions() {
	return { type: RESET_PREDICTIONS }
}

export function resetCheckedPredictions(key) {
	return { type: RESET_CHECKED_PREDICTIONS, key }
}

export function bookmarkCheckedInferences(key, val) {
	return { type: BOOKMARK_CHECKED_INFERENCES, key, val }
}

export function setShowChecked(key) {
	return { type: SET_SHOW_CHECKED, key }
}

export function setInferenceLoading() {
	return { type: SET_INFERENCE_LOADING }
}

export function giveSingleFeedback(id) {
	return { type: GIVE_SINGLE_FEEDBACK, id }
}

export function removeInference(id, key) {
	return { type: REMOVE_INFERENCE, id, key }
}

export function setUploadingStatus(status, totalFiles, showInferenceStatus) {
	return { type: SET_UPLOADING_STATUS, status, totalFiles, showInferenceStatus }
}

export function pollInferenceSession(sessionId) {
	return { type: POLL_SESSION_REQ, sessionId }
}

export function updateUploadCount(count) {
	return { type: UPDATE_UPLOAD_COUNT, count }
}

export function stopInferencePolling() {
	return { type: STOP_INFERENCE_POLLING }
}

export function saveParams(params) {
	return { type: SAVE_PARAMS, params }
}

export function getModelDefects(model) {
	return { type: MODEL_DEFECTS_REQ, model }
}

export function saveModelDefects(defects) {
	return { type: SAVE_MODEL_DEFECTS, defects }
}
